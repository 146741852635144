import _ from 'lodash';
import { mapGetters, mapActions } from "vuex";
import selectFilialProduct from "@/components/inventory/select-filial-product";
import selectCurrency from "@/components/inventory/select-currency";
import selectPackage from "@/components/inventory/select-package";
import selectColor from "@/components/inventory/select-color";
import selectKpTemplate from "@/components/inventory/select-kp-template";
import selectServices from "@/components/multiSelects/multi-select-product-services";
import childProducts from "@/views/deals/components/dealProduct/deal-product-childs";
import CrmOnlyNumberInput from '@/components/crm/crm-only-number-input';
import { formatNumber, formatMoney } from "@/filters/index";

export default {
	props: {
		filial_id: {
			default: null,
		},
		deal_id: {
			default: null,
		}, 
		product_calculation_type_id: {
			default: null,
		}, 
		deal_calculate_money: {
			default: 'deal',
		},
		change_in_deal_modal: {
			default: false
		},
		min_profit:{
			default: null
		},
		client_status: {
			default: false
		}
	},
	components: {
        selectFilialProduct,
        selectCurrency,
        selectPackage,
        selectColor,
        selectKpTemplate,
        selectServices,
        childProducts,
        CrmOnlyNumberInput
    },

	data() {
		return {
			dealProductsList: [],
			product_id: null,
			updateProducts: false,
			drawerServices: false,
			selectedProductServices: [],
			selectedProductIndex: null,
			selectedRow: {},
			drawerChildProduct: false,
			srcList: []
		};
	},

	watch: {
		deal_id: {
		  handler: function (deal_id) {
			this.updateList();
			this.updateFeeList();
		  },
		  immediate: true,
		  deep: true,
		},
		dealProductsList: {
		  handler: function (newVal) {
			if(this.change_in_deal_modal){
			  this.$emit('input', newVal);
			}
		  },
		  immediate: true,
		  deep: true,
		},
	},

	computed: {
		...mapGetters({
		}),
	
		totalAmountWithoutNDSAndDiscount: function () {
			let total = 0;
			this.dealProductsList.forEach((product) => {
				total += parseFloat(product.quantity) * parseFloat(product.price) * parseFloat(product.currency_rate);
			});
			return total;
		},
		profitAmount: function () {
			let total = 0;
			this.dealProductsList.forEach((product) => {
				total += (parseFloat(product.currency_rate) * parseFloat(product.quantity) * parseFloat(product.price) * parseFloat(product.profit)) / 100;
			});
			return total;
		},
		discountAmount: function () {
			let total = 0;
			this.dealProductsList.forEach((product) => {
				total += (parseFloat(product.currency_rate) * parseFloat(product.quantity) * parseFloat(product.price) * parseFloat(product.discount)) / 100;
			});
			return total;
		},
		NDSAmount: function () {
			let total = 0;
			this.dealProductsList.forEach((product) => {
				total += (parseFloat(product.currency_rate) * parseFloat(product.quantity) * parseFloat(product.price) * parseFloat(product.nds)) / 100;
			});
			return total;
		},
		totalAmount: function () {
			let total = 0;
			this.dealProductsList.forEach((product) => {
				let prodcutServiceCost = 0;
				if(product.serviceList && product.serviceList.length > 0){
				let moneyList = product.serviceList.map(el => (parseFloat(el.money_amount)*parseFloat(el.currency_rate)));
				prodcutServiceCost = moneyList.reduce((acc, price) => parseFloat(acc) + parseFloat(price));
				}
				total += parseFloat(product.quantity) * (parseFloat(prodcutServiceCost) + (parseFloat(product.currency_rate) * parseFloat(product.price) * (100 - parseFloat(product.discount) + parseFloat(product.nds))/100));
			});
			return total;
		},
		calculateProfitManagerForProduct() {
			let currency_symbol = '';
			let self = this;
			let total = 0;
			
			if(!_.isEmpty(this.parent().type_rules) && !_.isEmpty(this.dealProductsList)){
				if (this.$refs['currencySelect']) {
					currency_symbol = this.$refs['currencySelect'].currency_symbol;
				}
				_.forEach(this.dealProductsList, product => {
					if (product.profit) {
						let profit = _.find(this.parent().type_rules, o => o.from <= product.profit && o.until >= product.profit && o.first_deal == self.client_status);
						let bonus = profit ? profit.bonus : 0;
						total += (parseFloat(product.price) * product.currency_rate  * parseFloat(product.quantity) * parseFloat(product.profit) * parseFloat(bonus || 0) / 10000);
					}
				});
			}
			return formatNumber(total, 1) + ' $';
		},
	},

	methods: {
		parent(){
			return this.$parent.$parent;
		},
		updateFeeList(){
			this.updateFeeInventory();
		},
		async checkMinProfit(profit, row){
			let min_profit = this.min_profit ? this.min_profit : row.min_profit;
			if ((parseFloat(profit) < min_profit) || !profit){
				this.$set(row, 'profit', min_profit);
				this.$notify({
					title: this.$t('message.warning'),
					type: "warning",
					offset: 130,
					message: this.$t('message.min_profit_value_warning', {value: min_profit})
				});
			}
			return min_profit;
		},
		updateParentPriceAndRemoveProduct(data){
			this.$set(this.dealProductsList[this.selectedProductIndex], 'price', parseFloat(data.price));
			this.dealProductsList[this.selectedProductIndex].child_products.splice(data.index, 1);
		},
		setChildProducts(item){
			let index = JSON.parse(JSON.stringify(this.selectedProductIndex));
			if(index >= 0 && this.dealProductsList && this.dealProductsList[index]){
				this.$set(this.dealProductsList[index], 'calculation_type_id', (item && item.calculation_type_id ? item.calculation_type_id : null));
				this.$set(this.dealProductsList[index], 'quantity', parseFloat(item.last_quantity || 0));
				this.$set(this.dealProductsList[index], 'price', parseFloat(item.last_price || 0));
				this.$set(this.dealProductsList[index], 'profit', parseFloat(item.profit || 0));
				this.$set(this.dealProductsList[index], 'child_products', item.products);
				this.$set(this.dealProductsList[index], 'feeList', item.feeList);
			}else{
				this.$notify({
                    title: this.$t('message.error_m'),
                    type: "error",
                    offset: 130,
                    message: this.$t('message.parent_product_not_found'),
                });
			}
			setTimeout(() => {
				this.selectedProductIndex = null;
				this.selectedRow = {};
			}, 100);
			
		},
		childProductListOpen(row, index){
			this.selectedProductIndex = index;
			this.selectedRow = JSON.parse(JSON.stringify(row));
			this.drawerChildProduct = true;	
		},
		noMoreThan(index) {
			if(this.dealProductsList[index].profit >= 100){
				this.dealProductsList[index].profit = 99;
			}
			if(this.dealProductsList[index].profit < 0){
				this.dealProductsList[index].profit = 0;
			}
			if(this.dealProductsList[index].nds >= 100){
				this.dealProductsList[index].nds = 99;
			}
			if(this.dealProductsList[index].nds < 0){
				this.dealProductsList[index].nds = 0;
			}
			if(this.dealProductsList[index].discount >= 100){
				this.dealProductsList[index].discount = 99;
			}
			if(this.dealProductsList[index].discount < 0){
				this.dealProductsList[index].discount = 0;
			}
		},
		checkCurrency(row){
			let currency = this.currencies.find(curr => curr.id === row.currency_id);  
			if(currency && currency.active){
				return true;
			}
			return false;
		},
	
		check: function (column, event) {
			this.updateColumn({key: column, value: event,})
		},
	
		changeCurrency(row){
			if(row.currency_id){
				let currency = this.currencies.find(el => el.id === row.currency_id);
				if(currency){
					let price = _.round((parseFloat(row.currency_rate) * parseFloat(row.price) / parseFloat(currency.rate)), 2);
					this.$set(row, 'currency_rate', currency.rate);
					this.$set(row, 'price', price);
				}
			}
		},
		getSummaries(param) {
			const { columns } = param;
			const sums = [];
			columns.forEach((column, index) => {
			if (index === 0) {
				sums[index] = this.$t('message.total');
				return;
			}
			if (column.label === this.columns.price.title) {
				sums[index] = formatMoney(this.totalAmountWithoutNDSAndDiscount, 2);
				return;
			}
			if (column.label === (this.columns.profit.title + ' %')) {
				sums[index] = formatMoney(this.profitAmount, 2);
				return;
			}
			if (column.label === (this.columns.discount.title + ' %')) {
				sums[index] = formatMoney(this.discountAmount, 2);
				return;
			}
			if (column.label === (this.columns.nds.title + ' %')) {
				sums[index] = formatMoney(this.NDSAmount, 2);
				return;
			}
			if (column.label === this.columns.total.title) {
				sums[index] = formatMoney(this.totalAmount, 2);
				return;
			}
			});
	
			return sums;
		},
		getSummariesForServices(param){
			const { columns, data } = param;
			const sums = [];
			columns.forEach((column, index) => {
				if (index === 0) {
					sums[index] = this.$t('message.total');
					return;
				}
				if(column.label === this.$t('message.money_amount')){
					let total = data.map(serv => parseFloat(serv.money_amount*serv.currency_rate));
					if(total.length > 0){
						sums[index] = formatMoney(total.reduce((all_money, money) => all_money+money), 3);
					}else{
						sums[index] = 0;
					}
					return;
				}
			});
		
			return sums;
		},
	
		checkRowServices(row){
			if(row.services && row.services.length > 0){
				return false;
			}
			return true;
		},
		showServices(row, index){
			this.drawerServices = true;
			this.selectedProductServices = JSON.parse(JSON.stringify(row.serviceList));
			this.selectedProductIndex = index;
		},
	
		getCurrencyCodeById(row){
			let currency = this.currencies.find(el => el.id === row.currency_id);
			if(currency){
				return currency.symbol
			}
			return '';
		},
		productServiceChange(data, index){
			if(this.dealProductsList[index].serviceList){
				this.$set(this.dealProductsList[index], 'serviceList', this.dealProductsList[index].serviceList.filter(el => data.includes(el.id)));
			}else{
				this.$set(this.dealProductsList[index], 'serviceList', []);
			}
		
			let list_ids = this.dealProductsList[index].serviceList.map(el => el.id);
			let newServices = data.filter(item => !list_ids.includes(item) )
			if(newServices.length > 0){
				newServices.forEach(el => {
					let service = JSON.parse(JSON.stringify(this.services.find(e => e.id === el)));
					if(service){
						let currency = this.currencies.find(el => el.id === service.currency_id);
						currency = currency ? currency.rate : 0; 
						this.$set(service, 'currency_rate', currency);
						this.dealProductsList[index].serviceList.push(service);
					}
				});
			}
			this.dealProductsList[index].serviceList.sort((a, b) => a.id - b.id);
		},
	
		submitService(close){
			if(this.selectedProductIndex >= 0){
				this.$set(this.dealProductsList[this.selectedProductIndex], 'serviceList', this.selectedProductServices);
			}
			this.closeService();
		},
		closeService(){
			this.selectedProductServices = [];
			this.selectedProductIndex = null;
			this.drawerServices = false;
		},
	
		get_selected_product(selected_product_id){
			if(selected_product_id){
				this.addToDealProductsList(selected_product_id);
			}
		},
		dealOffersCreate() {
			if(this.dealProductsList.length > 0){
				this.$emit('dealOffersCreate', {drawer: true, productList: this.dealProductsList})
			}else{
				this.$message({
					type: "warning",
					message: this.$t('message.product_list_is_empty'),
				});
			}
		},
		productRowTotal(product) {
			let rowTotal = 0;
			let prodcutServiceCost = 0;
			if(product.serviceList && product.serviceList.length > 0){
				let moneyList = product.serviceList.map(el => (parseFloat(el.money_amount) * parseFloat(el.currency_rate)));
				prodcutServiceCost = moneyList.reduce((acc, price) => parseFloat(acc) + parseFloat(price));
			}
			rowTotal = (parseFloat(product.currency_rate) * parseFloat(product.price) + parseFloat(prodcutServiceCost)) * parseFloat(product.quantity) * parseFloat(100 - parseFloat(product.discount) + parseFloat(product.nds)) / 100;
			return parseFloat(rowTotal || 0);
		},
	
		addToDealProductsList(product_id) {
			this.showProduct(product_id).then(() => {
				let item = {};
		
				let product_children = JSON.parse(JSON.stringify(this.getProduct.child_products));
				product_children.forEach(element => {
					element.id = null;
					let rate = 1;
					if(element.selling_price_currency_id){
						let currency = this.currencies.find(el => el.id === element.selling_price_currency_id)
						rate = currency ? currency.rate : 1;
					}
					element.currency_rate = rate;
				});
				if(product_children && product_children.length > 0){
					item.child_products = product_children;
				}else{
					item.child_products = [];
				}
		
				let product_feeList = JSON.parse(JSON.stringify(this.getProduct.feeList));
				product_feeList.forEach(element => {
					element.id = null;
				});
				if(product_feeList && product_feeList.length > 0){
					item.feeList = product_feeList;
				}else{
					item.feeList = [];
				}
		
				let currency_id = this.getProduct.selling_price_currency.id;
				let rate = null
				if(currency_id){
					let currency = this.currencies.find(el => el.id === currency_id)
					if(currency){
						rate = currency.rate;
					}
				}
				let profit = 0;
				if(this.min_profit && parseFloat(this.min_profit || 0) > parseFloat(this.getProduct.profit || 0)){
					profit = this.min_profit;
				}else if (this.getProduct.profit){
					profit = this.getProduct.profit;
				}
				item.profit = profit;
		
				item.id = null;
				item.deal_id = this.deal_id;
				item.product_id = product_id;
				item.currency_id = currency_id;
				item.production = this.getProduct.production;
				item.currency_rate = rate;
				item.product = JSON.parse(JSON.stringify(this.getProduct));
				item.quantity = 1;
				item.discount = 0;
				item.nds = 0;
				item.client_price = 0;
				item.color_id = null;
				item.services = [];
				item.serviceList = [];
				item.colors = this.getProduct.allowed_colors;
				item.package_id = null;
				item.packages = this.getProduct.allowed_packages;
				item.service_for_unit = true;
				item.calculation_type_id = this.getProduct.calculation_type_id ? this.getProduct.calculation_type_id : this.product_calculation_type_id;
				if(this.getProduct.production > 0 && this.getProduct.child_products && this.getProduct.child_products.length == 0){
					item.price = 0;
				}else if(this.getProduct.child_products && this.getProduct.child_products.length > 0){
					// CALCULATES WITH OLD CURRENCY RATE
					// let price = (this.getProduct.selling_price * 100) / ( 100 + parseFloat(this.getProduct.profit));
					// price = (parseFloat(price || 0) * (1 + parseFloat(profit || 0)/100));
					// item.price = _.round(parseFloat(price || 0), 2);
					
					// CALCULATES WITH NEW SYSTEM CURRENCY RATE
					item.price = this.calculateTotals(item, profit);
				}else {
					item.price = parseFloat(this.getProduct.selling_price || 0);
				}
				let new_item = JSON.parse(JSON.stringify(item));
				this.dealProductsList.push(new_item);
			});
		},
	
		calculateTotals(rowProduct, profit){
			let total = 0;
			let last_price_net = 0;
			let fee_total_percentage = 0;
			let fee_total_money_amount = 0;
			rowProduct.child_products.forEach((product) => {
				let prodcutServiceCost = 0;
				if(product.serviceList && product.serviceList.length > 0){
					let moneyList = product.serviceList.map(el => (parseFloat(el.money_amount || 0) * parseFloat(el.currency_rate || 0)));
					prodcutServiceCost = moneyList.reduce((acc, price) => parseFloat(acc) + parseFloat(price || 0));
				}
				total += parseFloat(product.quantity || 0) * (parseFloat(prodcutServiceCost || 0) + parseFloat(product.currency_rate || 0) * parseFloat(product.price || 0));
				if(rowProduct.calculation_type_id != 1){ // rowProduct.calculation_type_id == 2
					last_price_net += (parseFloat(product.price || 0) * parseFloat(product.currency_rate || 0) + parseFloat(prodcutServiceCost || 0));
					last_price_net = last_price_net * (1 + parseFloat(product.waste || 0) / 100);
				}
			});
			if(rowProduct.feeList.length > 0){
				rowProduct.feeList.forEach(element => {
					fee_total_percentage += parseFloat(element.percentage || 0)
					fee_total_money_amount += parseFloat(element.money_amount || 0)
				});
			}
			if(rowProduct.calculation_type_id == 1){
				last_price_net = parseFloat(last_price_net) + parseFloat(fee_total_money_amount || 0);
				last_price_net = parseFloat(last_price_net) / parseFloat(rowProduct.currency_rate); 
	
			}else {  // rowProduct.calculation_type_id == 2 
				last_price_net = parseFloat(last_price_net) + parseFloat(fee_total_money_amount || 0);
				last_price_net = parseFloat(last_price_net) / parseFloat(rowProduct.currency_rate);
			}
			let last_price = (parseFloat(last_price_net || 0) * (1 + parseFloat(profit || 0)/100));
			return _.round(last_price, 2);
		},
	
		removeFromDealProductsList(id, index) {
			if (id) {
				this.$confirm(
					this.$t('message.Are you sure you want to uninstall the product?'),
					this.$t('message.warning'),
					{
					confirmButtonText: "Да",
					cancelButtonText: "Нет",
					type: "warning",
					}
					
				)
				.then(() => {
					this.destroy(id)
					.then((res) =>{
						this.dealProductsList.splice(index, 1);
						this.$emit('updateProdsOnClose');
						this.$alert(res);
					})
					.catch((err) => {
						this.$alert(err);
					})
				})
				.catch(() => {
					this.$message({
						type: "warning",
						message: this.$t('message.deleting_product_canceled'),
					});
				});
			}else{
				this.dealProductsList.splice(index, 1);
			}
		},
	
		tableRowClassName({row, rowIndex}){
			if(row.price <= 0){
				return 'danger-row';
			}else{
				return '';
			}
		},
	
		submit(close = true) {
			if(this.deal_calculate_money === 'product'){
				this.$emit('listChanged');
				this.$emit('updateProductTotal', this.totalAmount);
			}
			if(this.dealProductsList.filter(el => el.price <= 0).length > 0){
				this.$notify({
					title: this.$t('message.warning'),
					type: "warning",
					offset: 130,
					message: this.$t('message.set_product_price'),
				});
			} else if (this.updateProducts) {
				this.loadingButton = true;
				this.update(this.dealProductsList)
				.then((res) => {
					this.loadingButton = false;
					this.updateList();
					this.$alert(res);
				})
				.catch((err) => {
					this.loadingButton = false;
					this.$alert(err);
				});
			} else {
				this.loadingButton = true;
				this.save(this.dealProductsList)
				.then((res) => {
					this.loadingButton = false;
					this.updateList();
					this.$alert(res);
				})
				.catch((err) => {
					this.loadingButton = false;
					this.$alert(err);
				});
			}
		},
		updateList(){
			if(this.deal_id){
				this.loadingData = true;
				this.updateProdList(this);
			}
		},
		updateProdList: _.debounce((self) => {
			if(self.currencies.length === 0){
				self.updateCurrencyInventory();
			}
			self.show(self.deal_id)
				.then((res) => {
					self.dealProductsList = JSON.parse(JSON.stringify(self.dealProducts));
					self.updateProducts = true;
					self.loadingData = false;
				})
				.catch((err) => {
					self.loadingData = false;
				});
		}, 300),


    }
}