import { index, inventory, show, store, update, destroy, getNotificationUsers, updateSysteamNotificationUser, 
	getAllNotifications, getSamplesForMessages } from "@/api/settings/systeamNotification";

export const actions = {
    index({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            index(params)
                .then(res => {
                    commit("SET_LIST", res.data.result.data.systeamNotifications);
                    commit("UPDATE_PAGINATION", { key: 'total', value: res.data.result.data.pagination.total });
                    resolve(res.data.result.data.systeamNotifications);
                })
                .catch(err => {
                    reject(err);
                });
        }); 
    },

    inventory({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            inventory(params).then(res => {
                commit("SET_INVENTORY", res.data.result.data.systeamNotifications);
                resolve(res.data)
            }).catch(err => {
                reject(err)
            })
        })
    },

	 getSamplesForMessages({ commit }, params = {}) {
		return new Promise((resolve, reject) => {
			getSamplesForMessages(params).then(res => {
				  resolve(res.data.result.data);
			 }).catch(err => {
				  reject(err)
			 })
		})
  	},

    show({ commit }, id) {
        return new Promise((resolve, reject) => {
            show(id)
                .then(res => {
                    commit('SET_MODEL', res.data.result.data.systeamNotification);
                    resolve(res.data.result.data);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

	 getNotificationUsers({ commit }, id){
		return new Promise((resolve, reject) => {
			getNotificationUsers(id)
				 .then(res => {
					  resolve(res.data.result.data);
				 })
				 .catch(err => {
					  reject(err);
				 });
	  });
	 },

    store({ commit }, data) {
        return new Promise((resolve, reject) => {
            store(data)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    update({ commit }, data) {
        return new Promise((resolve, reject) => {
            update(data)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

	 updateSysteamNotificationUser({ commit }, id) {
		return new Promise((resolve, reject) => {
			updateSysteamNotificationUser(id)
				  .then(res => {
						resolve(res);
				  })
				  .catch(err => {
						reject(err);
				  });
		});
  },

  getAllNotifications({ commit }, params = {}) {
	return new Promise((resolve, reject) => {
		getAllNotifications(params)
			  .then(res => {
					commit("SET_INVENTORY", res.data.result.data.systeamNotifications);
					commit("UPDATE_PAGINATION_NOTES", { key: 'total', value: res.data.result.data.pagination.total });
					resolve(res.data.result.data);
			  })
			  .catch(err => {
					reject(err);
			  });
	}); 
},

    empty({ commit }) {
        return new Promise((resolve, reject) => {
            commit("EMPTY_MODEL");
            resolve()
        })
    },

    destroy({ commit }, id) {
        return new Promise((resolve, reject) => {
            destroy(id)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    updateSort({ commit }, sort) {
        commit("SET_SORT", sort)
    },

    updateFilter({ commit }, filter) {
        commit('SET_FILTER', JSON.parse(JSON.stringify(filter)))
    },

    updateColumn({ commit }, obj) {
        commit('UPDATE_COLUMN', obj)
    },
    updatePagination({ commit }, pagination) {
        commit('UPDATE_PAGINATION', pagination)
    },
	 updatePaginationNotes({ commit }, pagination) {
		commit('UPDATE_PAGINATION_NOTES', pagination)
  },
    refreshData({ commit }) {
        return new Promise((resolve, reject) => {
            commit("REFRESH");
            resolve()
        })
    },
};
