<template>
    <div class="timeline-wrap" v-loading="loadingData">
        <div class="timeline-card p-relative mll5" >
            <div class="timeline-items d-flex f-wrap mb-5">
                <div class="timeline-items__right rounded-sm alimer deal-tab-scroll">
                    <el-tabs
                        v-model="activeName"
                        @tab-click="handleClick"
                        class="tab-right"
                    >
                        <el-tab-pane
                            v-for="message_type in types"
                            :key="message_type.id"
                            :label="message_type.name"
                            :name="message_type.id + '-tab'"
                            v-loading="loadingTab"
                        >
                            <div v-if="message_type.id == 2 && authUser.sip_account && authUser.sip_account.sip_number && deal.client" class="timeline-card-body p-4">
                                <el-row :gutter="20" class="mb-5">
                                    <el-col v-if="deal.company" :span="12" class="mb-2">
                                        <span class="font-bold">{{ $t('message.company') }}:</span> {{ deal.company.name }}
                                    </el-col>
                                    <el-col :span="12" class="mb-2">
                                        <span class="font-bold">{{ $t('message.client') }}:</span> {{ deal.client.full_name }}
                                    </el-col>
                                    <el-col :span="12" class="mb-2">
                                       <span class="font-bold">{{ $t('message.phone_number') }}:</span>{{ deal.client.phone }}
                                    </el-col>
                                </el-row>
                                <el-row :gutter="20">
                                    <el-col :span="12">
                                        <el-select v-model="phone_call.prefix" :placeholder="$t('message.phoneNumberForSipAccountOutsideCalls')">
                                            <el-option
                                                v-for="phone_for_outbound_call in (authUser.sip_account ? authUser.sip_account.phones : [])"
                                                :key="phone_for_outbound_call.id" :label="phone_for_outbound_call.phone_number"
                                                :value="phone_for_outbound_call.prefix">
                                            </el-option>
                                        </el-select>
                                    </el-col>
                                    <el-col :span="12">
                                        <el-button @click="callToClient" type="primary" icon="fa-solid fa-phone-volume" plain> 
                                            <span class="font-bold ml-2"> {{ $t('message.call_client') }} </span>
                                        </el-button>   
                                    </el-col>
                                </el-row>
                            </div>
                            <div v-else class="timeline-card-body p-4">
                                <el-form
                                    :ref="'form-' + message_type.id"
                                    :model="form"
                                    :rules="rules"
                                >
                                    <div class="tab-btunn">
                                        <div class="d-flex">
                                            <el-form-item
                                                :label="$t('message.file')"
                                                class="label_mini d-flex"
                                            >
                                                <el-upload
                                                    v-model="form.files"
                                                    class="upload-demo"
                                                    action="/"
                                                    :before-remove="beforeRemove"
                                                    :limit="file_limit"
                                                    :accept="fileAccepts"
                                                    :on-exceed="handleExceed"
                                                    :on-remove="handleRemove"
                                                    :on-change="createImageList"
                                                    :file-list="fileList"
                                                    :auto-upload="false"
                                                >
                                                    <el-button
                                                            size="small"
                                                            type="primary"
                                                    >
                                                            {{ $t('message.loading') }}
                                                    </el-button>
                                                </el-upload>
                                            </el-form-item>
                                            <el-form-item v-if="message_type.id == 5" :label="$t('message.meeting_time')" class="d-flex label_mini ml-5">
                                                <el-date-picker
                                                    type="datetime"
                                                    :placeholder="$t('message.meeting_time')"
                                                    v-model="form.excutation_time"
                                                    style="width: 100%;"
                                                    format="yyyy-MM-dd HH:mm"
                                                    value-format="yyyy-MM-dd HH:mm"
                                                >
                                                </el-date-picker>
                                            </el-form-item>
                                        </div>
                                        <div class="btn" v-if="message_type.id === 7">
                                            <el-button
                                                size="small"
                                                type="info"
                                                @click="emailOpen(message_type.id)"
                                            >
                                                {{ $t('message.message') }}
                                                <span style="color: red;">
                                                        ( <i class="el-icon-message" > </i> / {{ total_email_message_count }} )
                                                </span>
                                            </el-button>
                                        </div>
                                    </div>

                                    <el-row v-if="message_type.id == 5" :gutter="24">
                                        <el-col :span="4">
                                            <el-form-item :label="$t('message.notifications_nn')">
                                                <el-checkbox v-model="form.notify_check"></el-checkbox>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="6" v-show="form.notify_check">
                                            <el-form-item>
                                                <el-select class="w-100" v-model="activity_time" @change="changeActivityTime(activity_time, form.excutation_time)" :placeholder="$t('message.activity_time')" clearable size='small'>
                                                    <el-option :label="$t('message.in_an_hour')" value="in_an_hour"></el-option>
                                                    <el-option :label="$t('message.in_two_hours')" value="in_two_hours" ></el-option>
                                                    <el-option :label="$t('message.in_three_hours')" value="in_three_hours"></el-option>
                                                    <el-option :label="$t('message.in_four_hours')" value="in_four_hours" ></el-option>
                                                    <el-option :label="$t('message.per_day')" value="per_day"></el-option>
                                                </el-select>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="6" v-show="form.notify_check">
                                            <el-form-item>
                                                <select-notification-types
                                                    v-model="form.notification_type_id"
                                                    :id="form.notification_type_id"
                                                ></select-notification-types>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="6" v-show="form.notify_check">
                                            <el-form-item>
                                                <multi-select-users
                                                    v-model="form.user_ids"
                                                    :user_ids="form.user_ids"
                                                    :collapse_tags="true"
                                                    :className="'w-50'"
                                                    :customStyle="'width: 100%;'"
                                                >
                                                </multi-select-users>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>

                                    <el-form-item
                                        :label="$t('message.text')"
                                        class="label_mini"
                                    >
                                        <textarea
                                            v-model="form.text"
                                            :placeholder="$t('message.text')"
                                            value="asdasdasd"
                                            class="
                                                timeline-textarea
                                                w-100
                                                rounded-sm
                                                p-3
                                            "
                                        ></textarea>
                                    </el-form-item>
                                    <div class="text-descr d-flex" v-if="message_type.id === 7">
                                        <div class="mmq">
                                            <el-form-item class="label_mini" >
                                                <el-checkbox v-model="form.send_message" :label="$t('message.Do you want to send a message to the client')" border></el-checkbox>
                                            </el-form-item>
                                        </div>
                                        <div class="mmq" v-if="form.send_message">
                                            <el-form-item
                                                class="label_mini"
                                            >
                                                <select-user-email
                                                    :size="'large'"
                                                    placeholder="User Email"
                                                    :id="form.userEmailId"
                                                    :user_id="authUser.id"
                                                    v-model="form.userEmailId"
                                                >
                                                </select-user-email>
                                            </el-form-item>
                                        </div>
                                    </div>

                                    <el-button
                                        v-if="permissions.some(per => per.slug == 'dealCorrespondences.create')"
                                        @click="submit(message_type.id)"
                                        v-loading="loadingButton"
                                        type="primary"
                                        size="medium"
                                        class="font-medium mt-3"
                                        >{{$t('message.send')}}
                                    </el-button>
                                </el-form>
                            </div>
                        </el-tab-pane>
                    </el-tabs>
                </div>
            </div>
        </div>

        <div
            v-for="message in list"
            :key="message.id"
            class="timeline-card p-relative ml-5"
        >
            <div class="timeline-items d-flex f-wrap mb-5">
                <div class="timeline-items__right rounded-sm">
                    <div class="timeline-card-header p-4">
                        <p class="m-0 m-d-i">
                            <strong class="mr-2" v-if="message.type.id != 5">
                                {{ message.type.name }} <span>{{ message.created_at }}</span>
                            </strong>
							<strong class="mr-2" v-else>
                                {{ message.type.name }} <span>{{ message.excutation_time }}</span>
                            </strong>

                            <span>
                                <i :class="'el-icon-'+(message.message_exchange_status === 1 ? 'bottom' : (message.message_exchange_status === 2 ? 'top' : ''))" style="color: blue;"></i>
                                {{ message.message_exchange_status === 1 ? message.message_to : message.message_from }}
                                <span class="ml-2">{{ message.message_exchange_status === 1 ? message.message_source_date : (message.message_exchange_status === 2 ? message.created_at : '') }}</span>
                            </span>
                        </p>
                    </div>

                    <div v-if="message.text || message.call_history" class="p-4 timeline-card-header">
                        <p class="lh-base font-medium m-0" v-html="urlify(message.text)"></p>
                        <div v-if="message.type.id == 2 && message.call_history" class="p-4 timeline-card-header">
                            <p class="lh-base font-medium m-0" >
                                <el-row :gutter="20" class="mb-5">
                                    <el-col :span="12" class="mb-2">
                                        <span class="font-bold">{{ $t('message.status') }}: </span> {{ message.call_history.status }}
                                    </el-col>
                                    <el-col :span="12" class="mb-2">
                                        <span class="font-bold">{{ $t('message.conversation_status') }}: </span> {{ message.call_history.callStatus }}
                                    </el-col>
                                    <el-col :span="12" class="mb-2">
                                        <span class="font-bold">{{ $t('message.caller_name') }}: </span> 
                                        <span v-html="message.call_history.caller_name"></span>
                                    </el-col>
                                    <el-col :span="12" class="mb-2">
                                        <span class="font-bold">{{ $t('message.caller') }}: </span> 
                                        <span v-html="message.call_history.caller"></span>
                                    </el-col>
                                    <el-col :span="12" class="mb-2">
                                        <span class="font-bold">{{ $t('message.target_name') }}: </span> 
                                        <span v-html="message.call_history.target_name"></span>
                                    </el-col>
                                    <el-col :span="12" class="mb-2">
                                        <span class="font-bold">{{ $t('message.called_to') }}: </span> 
                                        <span v-html="message.call_history.called_to"></span>
                                    </el-col>
                                    <el-col :span="12" class="mb-2">
                                        <span class="font-bold">{{ $t('message.billsec') }}: </span> 
                                        <span v-html="message.call_history.billsec"></span>
                                    </el-col>
                                    <el-col :span="12" class="mb-2">
                                        <span class="font-bold">{{ $t('message.calldate') }}: </span> 
                                        <span v-html="message.call_history.calldate"></span>
                                    </el-col>
                                    <el-col :span="24" class="mb-2">
                                        <div v-if="message.call_history.recordingfile">
                                            <audio id="audioFromExternal" controls :src="listenToTheAudio(message.call_history)"></audio>
                                        </div>
                                    </el-col>
                                </el-row>
                            </p>
                        </div>
                    </div>

                    

					<div class="timeline-card-header p-4" v-if="message.notification">
                        <p class="m-0 m-d-i">
                            <strong class="mr-2">
                                {{ $t('message.notifications_nn') }} <span>{{ message.notification.activity_time }}</span>
								<span class="ml-5">{{ $t('message.creator') }}: {{ message.notification.user.name }}</span>
                            </strong>
                        </p>
                    </div>

					<div v-if="message.notification && (message.notification.items.length > 0)" class="p-4 timeline-card-header">
                        <p class="lh-base font-medium m-0"> {{ $t('message.participants') }}:
                            <strong v-for="(item, index) in message.notification.items" :key="index">
                                <span v-if="index != message.notification.items.length - 1">{{ item.user.name }}, </span>
                                <span v-else>{{ item.user.name }}</span>
                            </strong>
                        </p>
                    </div>

                    <div class="p-4">
                        <div
                            @click="fileDownload(message.id, file.name)"
                            class="d-flex align-center mir font-medium pb-4"
                            v-for="file in message.files"
                            :key="file.id"
                        >
                            <span class="text-descr__text  text-reset">
                                <i class="el-icon-document-checked"></i> 
                                {{ file ? file.name : "" }}
                            </span>
                            <span class="text-descr__text  text-reset">
                                <i class="el-icon-download"></i>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="text-center">
            <el-button
                v-if="count !== list.length"
                v-loading="loadDealCorrespondenceList"
                @click="getMore()"
                type="success"
                size="small"
                round
            >
                {{$t('message.more')}}
            </el-button>
        </div>

        <div class="app-modal app-modal__full">
            <el-drawer
                :visible.sync="email"
                direction="rtl"
                size="70%"
                :append-to-body="true"
                :with-header="false"
                custom-class="ge"
                ref="drawerCreate"
                @opened="drawerOpened('drawerCreateChild')"
                @closed="drawerClosed('drawerCreateChild')"
            >
                <Email
                    :deal_id="deal_id"
                    :deal_email="deal_email"
                    :type_id="selected_type_id"
                    ref="drawerCreateChild"
                    drawer="drawerCreate"
                ></Email>
            </el-drawer>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import child_list from "@/utils/mixins/child_list";

import selectUserEmail from "@/components/inventory/select-user-email";
import Email from "./email";
import MultiSelectUsers from '../../../../components/multiSelects/multi-select-users';
import SelectNotificationTypes from '../../../../components/inventory/select-notification-types';

export default {
    mixins: [form, drawer, child_list],
    props: {
        deal: {
            default: null,
        },
        deal_id: {
            default: null,
        },
        deal_email: {
            default: null,
        },
    },
    components: {
        selectUserEmail,
        Email,
        MultiSelectUsers,
        SelectNotificationTypes,
    },
    data() {
        return {
            email: false,
            loadingData: false,
            selected_deal_id: null,
            activeName: "1-tab",
            fileList: [],
            updateFile: [],
            updateImage: [],
            blobType: "",
            fileName: "",
            file_limit: 10,
            selected_type_id: null,
            loadDealCorrespondenceList: false,
            loadingTab: false,
            fileAccepts: "image/png, image/jpeg, application/pdf, .doc,.docx,application/msword,.xls,.xlsx,application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/plain,",
			activity_time: null,
            phone_call: {
                prefix: '',
                phone: '',
                call_status: 'CALLING',               
            },

        };
    },
    computed: {
        ...mapGetters({
            numberToCall: "call/numberToCall",
            permissions: "auth/permissions",
            authUser: "auth/user",
            list: "dealCorrespondences/list",
            count: "dealCorrespondences/count",
            total_email_message_count: "dealCorrespondences/total_email_message_count",
            model: "dealCorrespondences/model",
            rules: "dealCorrespondences/rules",
            types: "dealCorrespondences/types",
            last_id: "dealCorrespondences/last_id",
        }),
    },
    watch: {
        deal_id: function (val) {
            if (val) {
                this.selected_deal_id = val;
                this.getTypes();
                this.fetchData({ deal_id: val, last_id: this.last_id });
            }
        },
        'form.excutation_time': {
            handler: function(newDate){
                if (newDate){
                    this.changeActivityTime(this.activity_time, newDate);
                }
                else this.form.activity_time = null;
            },
            deep: true
        },
        'form.notify_check':{
            handler: function (newVal){
                if(!newVal){
                    this.activity_time = null;
                    this.form.notification_type_id = null;
                    this.form.user_ids = [];
                }
            }
        },
        numberToCall: {
            handler: async function(newVal, oldVal) {
                if(newVal && newVal.status && newVal.status == 'TERMINATED'){
                    setTimeout(async () => {
                        await this.bindCallHistory({...this.phone_call, deal_id: this.deal_id});
                        await this.emptyNumberToCall();
                        this.updateFile = [];
                        await this.emptyDealCorrespondences()
                            .then(() => {
                                this.fileList = [];
                                this.getMore();
                            });
                        this.empty();
                    }, 500);
                 
                }
            },
            deep: true,
            immediate: true
        },
    },

    methods: {
        ...mapActions({
            emptyNumberToCall: "call/emptyNumberToCall",
            setNumberToCall: "call/setNumberToCall",
            bindCallHistory: "dealCorrespondences/bindCallHistory",
            save: "dealCorrespondences/store",
            updateList: "dealCorrespondences/getAll",
            getTypes: "dealCorrespondences/getTypes",
            getEmailsCount: "dealCorrespondences/getEmailsCount",
            empty: "dealCorrespondences/empty",
            emptyDealCorrespondences: "dealCorrespondences/emptyList",
            downloadDealCorrespondence:"dealCorrespondences/downloadDealCorrespondence",
        }),

        callToClient(){
            let c_number = this.deal.client.phone;
            if(c_number.includes('+998') && c_number.length > 9 && this.phone_call.prefix){
                this.phone_call.phone = c_number.slice(-9);
                this.setNumberToCall(this.phone_call);
            }else if(!this.phone_call.prefix){
                this.$notify({
                    title: this.$t('message.warning'),
                    type: "warning",
                    offset: 130,
                    message: this.$t('message.please_select_input', {input: this.$t('message.phoneNumberForSipAccountOutsideCall')})
                });
            }else{
                this.$notify({
                    title: this.$t('message.error_m'),
                    type: "error",
                    offset: 130,
                    message: this.$t('message.phone_number_is_not_valid')
                });
            }
        },

        listenToTheAudio(call){
            let url = '';
            let month = new Date(call.calldate).getMonth() + 1;
            if(month < 10){
                month = '0' + month;
            }
            
            let day = new Date(call.calldate).getDate();
            if(day < 10){
                day = '0' + day;
            }

            url += new Date(call.calldate).getFullYear() + '/' + month + '/' + day;
            url += '/'+call.recordingfile;
            url = process.env.VUE_APP_CALL_HISTORY_URL + '/' + url;
            return url
        },

        urlify(text) {
            if(text){
                let urlRegex = /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/igm;
                return text.replace(urlRegex, function(url) {
                    return '<a target="_blank" href="' + url + '">' + url + '</a>';
                });
            }else {
                return '';
            }
            
        },
        changeActivityTime(key, excutation_time){
            if (excutation_time) {
                const excutation_time = new Date(this.form.excutation_time);
                let hour = excutation_time.getHours();

                switch (key) {
                    case 'in_an_hour':
                        excutation_time.setHours(hour - 1);
                        this.form.activity_time = this.dateTimeFormat(excutation_time);
                    break;
                    case 'in_two_hours':
                        excutation_time.setHours(hour - 2);
                        this.form.activity_time = this.dateTimeFormat(excutation_time);
                    break;
                    case 'in_three_hours':
                        excutation_time.setHours(hour - 3);
                        this.form.activity_time = this.dateTimeFormat(excutation_time);
                    break;
                    case 'in_four_hours':
                        excutation_time.setHours(hour - 4);
                        this.form.activity_time = this.dateTimeFormat(excutation_time);
                    break;
                    case 'per_day':
                        excutation_time.setDate(excutation_time.getDate() - 1);
                        this.form.activity_time = this.dateTimeFormat(excutation_time);
                    break;

                    default:
                        this.form.activity_time = null;
                    break;
                }
            }
        },
        afterLeave(){
            this.form = {};
            if(this.types.length > 0){
               this.activeName = JSON.parse(JSON.stringify(this.types[0].id)) + '-tab';
            }
        },
        beforeRemove(file, fileList) {
            this.form.file_path = file.raw;
        },
        handleClick(tab, event) {
            this.form = JSON.parse(JSON.stringify(this.model));
            this.updateFile = [];
            let type_id = JSON.parse(JSON.stringify(this.activeName.replace('-tab', '')));
            if(type_id == 2){
                this.phone_call.prefix = this.authUser.sip_account.phones[0].prefix;
            }
            if(type_id == 7 && !this.loadingTab && this.deal_id){
                if(this.deal_email){
                    this.loadingTab = true;
                    this.getEmailsCount({type_id: type_id, deal_id: this.deal_id})
                        .then(res => {
                            this.loadingTab = false;
                        }).catch(err => {
                            this.$notify({
                                title: this.$t('message.error_m'),
                                type: "error",
                                offset: 130,
                                message: this.$t('message.something_wrong_with_connection_of_email')
                            });
                            this.loadingTab = false;
                        })
                }else{
                    this.$notify({
                        title: this.$t('message.error_m'),
                        type: "error",
                        offset: 130,
                        message: this.$t('message.There is no email in the transaction to search for messages!')
                    });
                }

            }
        },
        emailOpen(type_id){
            this.email = true;
            this.selected_type_id = type_id;
        },
        handleExceed(files, fileList) {
            this.$message.warning(this.$t('message.file_limit_warning', {number: this.file_limit}));
        },
        handleRemove(file, fileList){
            if(file.id){
                this.deleteFile({deal_id: this.selectedItem.id, file: file.name})
            }
            this.updateFile.forEach((element, index) => {
                if(element.name === file.name ){
                    this.updateFile.splice(index, 1);
                }
            });
        },
        submit(type_id) {
            if((this.form.send_message && this.form.userEmailId) || !this.form.send_message){

                let refName = "form-" + type_id.toString();
                if (!this.form.text && this.updateFile.length === 0) {
                    return this.$message({
                        type: "warning",
                        message: this.$t('message.Enter one from text or file'),
                    });
                }

					 if (this.form.notify_check){
						if (!this.form.excutation_time){
							return this.$message({
                        type: "warning",
                        message: this.$t("message.please_enter_input", {input: this.$t('message.excutation_time')}),
                    });
						}
						if (!this.form.activity_time){
							return this.$message({
                        type: "warning",
                        message: this.$t("message.please_enter_input", {input: this.$t('message.activity_time')}),
                    });
						}
						if (!this.form.notification_type_id){
							return this.$message({
                        type: "warning",
                        message: this.$t("message.please_enter_input", {input: this.$t('message.notificationType')}),
                     });
						}
					 }

                this.form.type_id = type_id;
                this.form.deal_id = this.deal_id;
                let formData = new FormData();
                for (const key in this.form) {
                    if (this.form[key] && key != 'user_ids') {
                        formData.append(key, this.form[key]);
                    }
						  else if(key == 'user_ids'){
								this.buildFormData(formData, this.form[key], 'user_ids');
						  }
						  else {
                        formData.append(key, "");
                    }
                }
                if (_.size(this.updateFile) > 0) {
                    for (const key in this.updateFile) {
                        if (this.updateFile.hasOwnProperty(key)) {
                            const element = this.updateFile[key];
                            formData.append(`files[${key}]`, element);
                        }
                    }
                }
                this.loadingButton = true;
                this.dataSaved = true;
                this.save(formData)
                    .then((res) => {
                        this.loadingButton = false;
                        this.$emit('listChanged');
                        if(res.data.result.success){
                            this.$alert(res);
                            this.updateFile = [];
                            this.emptyDealCorrespondences()
                            .then(() => {
                                this.fileList = [];
                                this.getMore();
                            });
                            this.empty();
                        }else{
                            this.$notify({
                                title: this.$t('message.error_m'),
                                type: "error",
                                offset: 130,
                                message: res.data.result.message
                            });
                        }

                    })
                    .catch((err) => {
                        this.loadingButton = false;
                        this.$alert(err);
                    });
            }else{
                return this.$message({
                        type: "warning",
                        message: this.$t('message.Please select an email address if you would like to send an email'),
                    });
            }
        },
		buildFormData(formData, data, parentKey) {
            if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File) && !(data instanceof Blob)) {
              Object.keys(data).forEach(key => {
                this.buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
              });
            } else {
              const value = data == null ? '' : data;

              formData.append(parentKey, value);
            }
        },
        getMore() {
            if (this.list.length !== this.count && !this.loadDealCorrespondenceList || this.count == 0) {
                this.fetchData({ deal_id: this.selected_deal_id, last_id: this.last_id });
            }
        },
        fetchData(query) {
            this.loadDealCorrespondenceList = true;
            this.loadingData = true;

            this.updateList(query)
                .then(() => {
                    this.loadDealCorrespondenceList = false;
                    this.loadingData = false;
                })
                .catch((err) => {
                    this.loadDealCorrespondenceList = false;
                    this.loadingData = false;
                });
        },
        createImageList(file, fileList) {
            if (fileList.length > this.file_limit) {
                fileList.pop();
                return this.$message({
                type: "warning",
                message: this.$t('message.file_limit_warning', {number: this.file_limit}),
                });
            }else if (file.size > 5000000) {
                fileList.pop();
                return this.$message({
                type: "warning",
                message: this.$t('message.file_size_limit_warning', {number: 5}),
                });
            }else if(this.updateFile.some(item => item.name == file.name)) {
                this.updateFile.forEach((element, index) => {
                    if(element.name === file.name && element.size === file.size){
                        fileList.pop();
                        return this.$message({
                            type: "warning",
                            message: this.$t('message.file_already_exists'),
                        });
                    }
                });
            }else{
                this.updateFile.push(file.raw);
            }
        },
        fileDownload(dealCorrespondence_id, fileName) {
            let data = { dealCorrespondence_id: dealCorrespondence_id, file: fileName };
            this.downloadDealCorrespondence(data)
                .then((res) => {
                    const url = window.URL.createObjectURL(new Blob([res.data]));

                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", fileName);
                    document.body.appendChild(link);
                    link.click();
                })
                .catch((err) => {
                    this.$alert(err);
                });
        },
    },
};
</script>


<style>

audio {
    height: 40px;
    margin: 0.4rem;
}

audio::-webkit-media-controls-panel {
    background-color: #dff0fd;
}

</style>
