<template>
  <div>
    <div class="app-modal__box">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0">{{$t('message.deals')}}</p>
          <div>
            <!-- <el-button  type="info" size="medium" @click="close()" > {{$t('message.close')}} </el-button> -->
            <el-button class="bg-none-style"   size="medium" @click="close()"  >
                <!-- {{ $t("message.close") }} -->
                <i class="fa-solid fa-xmark"></i>
            </el-button>
          </div>
        </div>
      </div>
      <div class="app-modal__body p-5 pb-0" >
            <div class="card-table-header table-crm-smart" v-loading="loadTab" v-if="stageDeals.length > 0">
                <table class="table-my-code table-bordered">
                  <thead>
                    <tr>
                      <th>№</th>
                      <th>{{ $t("message.name")}}</th>
                      <th>{{ $t("message.user")}}</th>
                      <th>{{ $t("message.filial")}}</th>
                      <th>{{ $t("message.Total amount")}}</th>
                      <th>{{ $t("message.profit_m")}}</th>
                      <th>{{ $t("message.currency")}}</th>
                      <th>{{ $t("message.begin_date")}}</th>
                      <th>{{ $t("message.end_date")}}</th>
                      <th>{{ $t("message.link")}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="deal in stageDeals" :key="deal.id">
                      <th> {{ deal.id }} </th>
                      <th> {{ deal.name }} </th>
                      <th> {{ deal.username }} </th>
                      <th> {{ deal.filial_name }} </th>
                      <th> {{ deal.money_amount }} </th>
                      <th> {{ deal.profit }} </th>
                      <th> {{ deal.currency }} </th>
                      <th> {{ deal.begin_date }} </th>
                      <th> {{ deal.end_date }} </th>
                      <th>
                        <el-button
                          @click="showSelectedDeal(deal)"
                          type="success"
                          class="w-100"
                          size="mini"
                          round
                          > {{ $t("message.view")}} </el-button
                        >
                      </th>
                    </tr>

                  </tbody>
                </table>
                <div class="my___pagination">
                    <crm-pagination
                        @c-change="updatePagination"
                        :pagination="pagination"
                    ></crm-pagination>
                    <!-- <Pagination /> -->
                </div>
              </div>
            </div>
            <div class="card-table-header table-crm-smart" v-if="stageDeals.length === 0">
                <h2>{{$t('message.no_deal')}}</h2>
            </div>

      </div>
      <div class="app-modal app-modal__full">
        <el-drawer
            size="100%"
            class="bg-drawer-color"
            :visible.sync="drawerDealCreate"
            :fullscreen="true"
            :append-to-body="true"
            :with-header="false"
            ref="drawerDealCreate"
            @opened="drawerOpened('drawerDealCreateChild')"
            @closed="drawerClosed('drawerDealCreateChild')"
        >
          <div
            @click="closeAddModel()"
            class="
              close-modal-stick
              d-flex
              align-center
              p-fixed
              text-white
              p-2
              font-bold
              pointer
              transition
            "
          >
            <div class="close-modal-icon text-center rounded-circle transition">
              <i class="el-icon-close"></i>
            </div>
            <span class="close-modal-text text-uppercase ml-2"></span>
          </div>
          <AppModal
            ref="drawerDealCreateChild"
            :board_id="board_id"
            :selectedDeal="selectedDeal"
            :dealCreate="dealCreate"
            drawer="drawerDealCreate"
          />
        </el-drawer>
      </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import show from "@/utils/mixins/show";
import AppModal from "@/views/deals/components/add-modal";
import Pagination from "@/components/el-pagination";

export default {
  mixins: [form, drawer, show],
  components: {
    AppModal,
    Pagination,
  },
  props: {
    stage_id: {
        default: null,
    },
    filterForm: {
        default: Object,
    },
  },
  data() {
    return {
        board_id: null,
        loadTab: false,
        dealCreate: false,
        drawerDealCreate: false,
        selectedDeal: null
    };
  },

  watch: {
    stage_id: {
        handler: function(newVal, oldVal) {
          if(newVal){
            this.fetchData();
          }
        },
        deep: true,
        immediate: true
    },
    'pagination.page': {
        handler: async function(newVal, oldVal) {
            if (newVal != oldVal && _.isFunction(this.fetchData)) {
                this.fetchData();
            }
        },
        deep: true,
        immediate: true,
    },
    'pagination.per_page': {
        handler: async function(newVal, oldVal) {
            if (newVal != oldVal && _.isFunction(this.fetchData)) {
                this.fetchData();
            }
        },
        deep: true,
        immediate: true,
    },
  },

  computed: {
    ...mapGetters({
        stageDeals: "home/stageDeals",
        pagination: "home/stageDealPagination",

    }),
  },

  methods: {
    ...mapActions({
        getStageDeals: "home/getStageDeals",
        show: "deals/show",
        updatePagination: "home/updateStageDealPagination",
        setPagination: "home/setStageDealPagination",

    }),
    showSelectedDeal(deal){
        this.selectedDeal = deal;
        this.board_id = deal.board_id;
        this.drawerDealCreate = true;
    },
    fetchData() {
        const query = {filter: this.filterForm, ...this.pagination, ...this.sort, stage_id: this.stage_id};
        if (!this.loadingData) {
            this.loadingData = true;
            this.getStageDeals(query).then(res => {
                this.loadingData = false
            }).catch(err => {
                this.loadingData = false
            });
        }
    },

    drawerOpened(ref) {
      if (this.$refs[ref] && _.isFunction(this.$refs[ref].opened)) {
        this.$refs[ref].opened();
      }
    },

    drawerClosed(ref) {
      if (this.$refs[ref]) {
        this.$refs[ref].closed();
      }
      if (this.reloadList === true) {
        this.debouncedGetList();
      }
      if (_.isFunction(this.empty)) {
        this.empty();
      }
    },
    closeDrawer(drawer) {       
       if (this.$refs[drawer] && _.isFunction(this.$refs[drawer].closeDrawer)) {
           this.$refs[drawer].closeDrawer();
       }
   },

    closeAddModel() {
      this.drawerDealCreate = false;
    //   if(this.reloadList === true){
    //     this.debouncedGetList();
    //   }
    },



  }

};
</script>
