<template>
    <div :class="mode ? 'style-scuerday' : 'style-scuernight'" class=" style-scuer h__full handle-draggable">
        <div v-if="editDashboard" class="vue-draggable-handle"></div>
        <div class="mb-5">
            <div class="filterblock_s">
                <h4 class="dashboard-tile__item-title short">
                    <span class="dashboard-tile__item-title-txt">
                        {{ $t('message.deals_by_country') }}
                    </span>
                </h4>
                <div class="filter_home flex-center">
                    <el-radio-group @change="$emit('vmodel', filterByDate)" v-model="filterByDate" size="medium">
                        <el-radio-button label="weekly">{{ $t("message.weekly") }}</el-radio-button>
                        <el-radio-button label="monthly">{{ $t("message.monthly") }}</el-radio-button>
                        <el-radio-button label="yearly">{{ $t("message.yearly") }}</el-radio-button>
                    </el-radio-group>
                    <i v-if="editDashboard" @click="$emit('remove-chart')" class="fa-solid fa-trash-can"
                        style="font-size: 18px; color: #dc2626; cursor: pointer;">
                    </i>
                </div>
            </div>
            <div v-if="countryDeals.length > 0" :class="mode ? '' : 'text-white'" class="d-flex f-between align-center">
                <div class="sausage-chart__item_body_top mt-2 mb-2">
                    {{ countryDeals.length > 0 ? countryDeals.map(item => item.count).reduce((prev, next) => prev + next) : 0 }}
                </div>
                <div>
                    <div class="dashboard-tile__item-bottom-small-count js-resize-txt mt-0">
                        {{ countryDeals.length > 0 ? totalOfCounryDealsMoneyAmount : 0 | formatMoney }}
                    </div>
                    <div class="dashboard-tile__item-bottom-subcaption mt-0">
                        {{ $t("message.per") }}
                        {{ countryDealsPeriod === "weekly" ? $t("message.weekly") : "" }}
                        {{ countryDealsPeriod === "monthly" ? $t("message.monthly") : "" }}
                        {{ countryDealsPeriod === "yearly" ? $t("message.yearly") : "" }}
                    </div>
                </div>
            </div>
            <div v-if="countryDeals.length === 0" class="eror">
                <img src="/img/dashboard.png" alt="Logo" class="d-block" />
                <h1>{{ $t('message.no_data_yet') }}</h1>
            </div>
        </div>
        <div class="task-scroll-khan" v-loading="loadingUsersDeals">
            <div class="itme-task-menjra mb-4 progress-color-lin" v-for="country in countryDeals" :key="country.id">
                <span class="input--label d-block">{{ country.name }}
                    <span style="margin-left: 10px"></span>
                    - {{ country.money_amount | formatMoney }}
                    <span style="margin-left: 10px"></span>- ({{ country.profit | formatMoney }})
                </span>
                <el-progress :percentage="Math.round(country.money_amount * 100 / totalOfCounryDealsMoneyAmount)"></el-progress>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    props: {
        mode: {
            type: Boolean,
            default: () => false
        },
        editDashboard: {
            type: Boolean,
            default: () => false
        },
        loadingUsersDeals: {
            type: Boolean,
            default: () => false
        },
        countryDealsPeriod: {
            type: String
        },  
        allPeriodChange: {
            type: String,
            default: () => "weekly"
        },

    },
    data() {
        return {
            filterByDate: "weekly"
        }
    },
    watch: {
        allPeriodChange(val) {
            this.filterByDate = val;
        },
    },
    computed: {
        ...mapGetters({
            countryDeals: "home/countryDeals",
        }),
        totalOfCounryDealsMoneyAmount() {
            let total = 1;
            if (this.countryDeals && this.countryDeals.length) {
                total = this.countryDeals.map(item => item.money_amount).reduce((prev, next) => parseFloat(prev) + parseFloat(next))
            }
            return total ? parseFloat(total) : 1;
        },
    }
    

}
</script>

<style lang="scss" scoped></style>