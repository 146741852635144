<template>
    <div class="home home-t-app" style="margin-bottom: 50px">
        <div class="
                dashboard-main-bg
                dashboard-main-bg__full
                dashboard-main-bg_image-filter
                dashboard-main-bg_animated
            "> </div>

        <div v-if="false" v-loading="loadingData">
            <!-- FILTER -->
            <div class="filter-filyal  mb-3">
                <el-form ref="form" :model="filterForm" class="d-flex custom__justify el-form-item-0">
                    <div class="d-flex f-w-mobil">
                        <el-form-item class="w-mobil" :class="mode ? 'select__formday' : 'select__formnight'">
                            <select-filial :size="'medium'" :placeholder="$t('message.filial')" :id="filterForm.filial_id"
                                v-model="filterForm.filial_id">
                            </select-filial>
                        </el-form-item>
                        <el-form-item class="w-mobil" :class="mode ? 'select__formday' : 'select__formnight'"
                            v-if="user.is_super_admin">
                            <select-user :size="'medium'" :placeholder="$t('message.user')" :id="filterForm.user_id"
                                v-model="filterForm.user_id">
                            </select-user>
                        </el-form-item>
                        <el-form-item class="w-mobil" :class="mode ? 'select__formday' : 'select__formnight'">
                            <select-client :size="'medium'" :placeholder="$t('message.client')" :id="filterForm.client_id"
                                :company_id="filterForm.company_id" v-model="filterForm.client_id">
                            </select-client>
                        </el-form-item>
                    </div>
                    <div>
                        <select-day v-model="allPeriodChange" />
                    </div>
                </el-form>
            </div>
        </div>


        <!-- -------------- DASHBOARD START------------------------------------------------ -->
        <!-- DASHBOARD -->
        <div @scroll="handleScroll" class="dashboard" ref="dashboard">
            <!-- dashboard navbar -->
            <nav class="dashboard-navbar d-mobil-s" :class="{ sticky: isScrollTop, fixed: editDashboard }"
                :style="{ 'z-index': editDashboard ? 1502 : 2 }">
                <div class="filter-filyal">
                    <el-form ref="form" :model="filterForm"
                        class="d-flex align-items-center justify-content-between el-form-item-0">
                        <div v-if="!editDashboard" class="dashboard-navbar-list">
                            <el-button @click="dialogVisibleDashboard = true" size="small">
                                DASHBOARD! &nbsp; <i class="fa-solid fa-bars"></i>
                            </el-button>
                            <el-select @change="handleTabClick" size="small" v-model="activeName"
                                placeholder="Select Dashboard">
                                <el-option v-for="(item, index) in filterDynamicTabs" :key="index" :label="item.title"
                                    :value="item.name">
                                </el-option>
                            </el-select>
                            <!-- edit -->
                            <el-button @click="editDashboard = true" size="small">
                                <i class="fa-solid fa-pen"></i>
                            </el-button>
                        </div>
                        <!-- save -->
                        <el-button class="flex-shrink-0" v-else type="primary" size="small" @click="updateDashboardSizes()"
                            icon="el-icon-check">
                        </el-button>

                        <div v-if="!editDashboard" class="d-flex f-w-mobil">
                            <el-form-item class="w-mobil" :class="mode ? 'select__formday' : 'select__formnight'">
                                <select-filial :size="'medium'" :placeholder="$t('message.filial')"
                                    :id="filterForm.filial_id" v-model="filterForm.filial_id">
                                </select-filial>
                            </el-form-item>
                            <el-form-item class="w-mobil" :class="mode ? 'select__formday' : 'select__formnight'"
                                v-if="user.is_super_admin">
                                <select-user :size="'medium'" :placeholder="$t('message.user')" :id="filterForm.user_id"
                                    v-model="filterForm.user_id">
                                </select-user>
                            </el-form-item>
                            <el-form-item class="w-mobil" :class="mode ? 'select__formday' : 'select__formnight'">
                                <select-client :size="'medium'" :placeholder="$t('message.client')"
                                    :id="filterForm.client_id" :company_id="filterForm.company_id"
                                    v-model="filterForm.client_id">
                                </select-client>
                            </el-form-item>
                        </div>
                    </el-form>
                </div>

                <div class="dashboard-navbar-right d-flex align-items-center">
                    <select-day v-if="!editDashboard" v-model="allPeriodChange" />
                    <!-- add charts -->
                    <el-button class="ml-3" type="primary" size="small" @click="dialogVisibleChartsList = true">
                        <i class="fa-solid fa-plus"></i>
                    </el-button>
                </div>
            </nav>

            <!-- <el-divider class="divider"></el-divider> -->

            <!-- dashboard tabs -->
            <!--  @tab-remove="removeTab" -->
            <el-tabs class="dashboard-tabs" v-model="activeName" type="card">
                <el-tab-pane v-for="(item, index) in dynamicTabs" :key="item.name + '-dashboard-list-1'" :label="item.title"
                    :name="item.name" :style="{ 'z-index': editDashboard ? 1501 : 0, position: 'relative' }">
                    <div style="padding-bottom: 70px;">
                        <!-- STATUS -->

                        <div v-if="index === 0" class="grid-item mt-3 bg-pr mr-3 ml-3">
                            <div class="sausage-chart">
                                <div class="sausage-chart__item text-center">
                                    <div class="sausage-chart__item_haeder pl-1 pr-1">
                                        <div class="sausage-chart__item_haeder_top text-white"></div>
                                        <span class="sausage-chart__item_haeder_fot text-white"></span>
                                        <div class="mobile__version sausage-chart__item-header-color"></div>
                                    </div>
                                    <div class="sausage-chart__item_body bg-none">
                                        <div class="sausage-chart__item_body_top text-white">+ {{ dealStages.totalCount }}
                                        </div>
                                        <span class="sausage-chart__item_body_fot text-white">
                                            {{ dealStages.totalProfitSumm | formatMoney }}
                                        </span>
                                    </div>
                                    <div class="sausage-chart__item_footer pl-1 pr-1">
                                        <div class="sausage-chart__item-header-color"></div>
                                        <div class="sausage-chart__item_footer_top text-white">
                                            {{ dealStages.totalCount }} {{ $t('message.deal_c') }}
                                        </div>
                                        <div class="sausage-chart__item_footer_top text-white">
                                            {{ dealStages.totalSumm | formatMoney }}
                                        </div>
                                    </div>
                                </div>
                                <!-- sausage-chart__item end -->

                                <div class="sausage-chart__item text-center" v-for="stage in dealStages.dealStages"
                                    :key="stage.id" @dblclick="updateStageDeals(stage.id)">
                                    <div class="sausage-chart__item_haeder pl-1 pr-1">
                                        <div class="sausage-chart__item_haeder_top text-white">{{ stage.name }}</div>
                                    </div>
                                    <div class="sausage-chart__item_body" :class="stageColor(stage.id)">
                                        <div class="sausage-chart__item_body_top text-white">{{ stage.count }}</div>
                                        <span class="sausage-chart__item_body_fot text-white">
                                            {{ stage.profit_money_amount | formatMoney }}
                                        </span>
                                    </div>
                                    <div class="sausage-chart__item_footer pl-1 pr-1">
                                        <div class="sausage-chart__item-header-color" :style="dealStages.lastStageId === stage.id
                                            ? 'background-color: rgba(0, 197, 97, 0.7)'
                                            : 'background-color: #009bf9ad'
                                            "></div>
                                        <div class="sausage-chart__item_footer_top text-white">{{ stage.count }} {{
                                            $t('message.deal_c') }}</div>
                                        <div class="sausage-chart__item_footer_top text-white">
                                            {{ stage.money_amount | formatMoney }}
                                        </div>
                                    </div>
                                </div>
                                <!-- sausage-chart__item end -->

                                <div class="sausage-chart__item text-center">
                                    <div class="sausage-chart__item_haeder pl-1 pr-1">
                                        <div class="sausage-chart__item_haeder_top text-white">{{ $t("message.deletedDeals")
                                        }}</div>
                                    </div>
                                    <div class="sausage-chart__item_body bg-redcha">
                                        <div class="sausage-chart__item_body_top text-white">
                                            {{ deletedDealsForStage.count }}
                                        </div>
                                        <span class="sausage-chart__item_body_fot text-white">
                                            {{ deletedDealsForStage.profit_money_amount | formatMoney }}
                                        </span>
                                    </div>
                                    <div class="sausage-chart__item_footer pl-1 pr-1">
                                        <div class="sausage-chart__item-header-color"
                                            :style="'background-color: #ff6ba6bf '"></div>
                                        <div class="sausage-chart__item_footer_top text-white">
                                            {{ deletedDealsForStage.count }} {{ $t('message.deal_c') }}
                                        </div>
                                        <div class="sausage-chart__item_footer_top text-white">
                                            {{ deletedDealsForStage.money_amount | formatMoney }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <template v-if="item.dashboard && item.dashboard.length && typeof item.dashboard != 'string'">
                            <grid-layout :layout.sync="item.dashboard" :cols="{ lg: 24, md: 10, sm: 6, xs: 4, xxs: 2 }"
                                :row-height="30" :margin="[12, 12]" :is-draggable="editDashboard"
                                :is-resizable="editDashboard" :responsive="true" :is-bounded="true" :vertical-compact="true"
                                :use-css-transforms="true" :breakpoints="{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }">
                                <grid-item v-for="(element, itemIndex) in item.dashboard" :x="element.x" :y="element.y"
                                    :w="element.w" :h="element.h" :minW="element.minW" :maxW="element.maxW"
                                    :minH="element.minH" :maxH="element.maxH" :i="element.i" :key="itemIndex + '-grid-item'"
                                    drag-allow-from=".vue-draggable-handle">
                                    <component :is="element.componentName" :cloak="calendar" v-bind="{
                                        allPeriodChange,
                                        editDashboard,
                                        mode, filterForm, update, // for all component
                                        dealGoalsPeriod, dealGoalsByAmounts, dealGoalsForm, // for Goals component
                                        activityPeriod, activities, activityMark, // for ActivityPerWeek component
                                        latestFiles, // for LatestFiles component
                                        filterPayment, // for filterPayment component
                                        productSellStatisticsPeriod, // for productSellStatisticsPeriod component
                                        clients_birth_day, // for clients_birth_day
                                    }" v-model="dealGoalsPeriod" @showFullWindow="update++"
                                        @dealGoalsByAmounts="dealGoalsByAmounts = $event"
                                        @downloadLatestFile="downloadLatestFile($event)" @showDeal="showDeal($event)"
                                        @vmodel="vModel(element, $event)"
                                        @remove-chart="removeChart(index, itemIndex, element.componentName)" />
                                </grid-item>
                            </grid-layout>
                        </template>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </div>

        <!-- DASHBOARD LIST DIALOG -->
        <el-dialog class="dashboard-list-dialog" title="Выберите шаблон" :visible.sync="dialogVisibleDashboard" width="45%">
            <ul class="dashboard-list">
                <!-- add(plus) and save buttons -->
                <div style="display: flex; justify-content: flex-end; gap: 1rem; margin-bottom: 1rem;">
                    <el-button @click="dialogVisibleChartsList = true" size="small" type="primary" icon="fa-solid fa-plus"
                        plain>
                        Add dashboard
                    </el-button>
                </div>
                <!-- elements -->
                <li class="dashboard-list__item" v-for="(item, index) in dynamicTabs" :key="index + '-dashboard-list'">
                    <div class="dashboard-list__item-name"
                        :class="{ disabled: !dashboardNameList.some(item => item === dynamicTabs[index].name) }">
                        <input v-if="editDashboardName.is && editDashboardName.name === item.name" ref="editInput"
                            class="edit-input" type="text" v-model="editDashboardName.title"
                            :disabled="!dashboardNameList.some(item => item === dynamicTabs[index].name)" maxlength="50"
                            required>
                        <span v-else class="name">
                            {{ item.title }}
                        </span>
                    </div>
                    <!-- actions -->
                    <div class="dashboard-list__item-actions"
                        :class="{ disabled: !dashboardNameList.some(item => item === dynamicTabs[index].name) }">
                        <!-- count -->
                        <span class="counter"
                            :style="{ background: statusCounter(item.dashboard), 'border-color': lengthOfArray(item.dashboard) > 0 ? statusCounter(item.dashboard) : '#b3d8ff', color: lengthOfArray(item.dashboard) > 0 ? '#fff' : '#409EFF' }"
                            style="display: inline-block; margin-right: 12px;">
                            {{ lengthOfArray(item.dashboard) }}/12
                        </span>
                        <!-- show -->
                        <label :for="index" class="dashboard-list__item-label">
                            <input class="checkbox-input" v-model="dashboardNameList" type="checkbox" name="dashbord"
                                :id="index" :value="item.name">
                            <i @click="changeDashboardShow(item)"
                                :class="[dashboardNameList.some(item => item === dynamicTabs[index].name) ? 'fa-eye' : 'fa-eye-slash', 'fa-solid']"></i>
                        </label>
                        <!-- edit -->
                        <button @click="handelEditDashboardName(item)"
                            :disabled="!dashboardNameList.some(item => item === dynamicTabs[index].name)"><i
                                :class="[editDashboardName.is && editDashboardName.name === item.name ? 'fa-check' : 'fa-pencil', 'fa-solid']"></i>
                        </button>
                        <!-- delete -->
                        <button @click="removeDashboard(item, index)"
                            :disabled="!dashboardNameList.some(item => item === dynamicTabs[index].name)"><i
                                class="fa-solid fa-trash-can"></i>
                        </button>
                    </div>
                </li>
            </ul>
        </el-dialog>

        <!-- CHARTS LIST DRAWER -->
        <el-drawer class="LIST__DRAWER" size="100%" :with-header="false" :visible.sync="dialogVisibleChartsList"
            direction="rtl">

            <!-- header -->
            <div class="drawer-header">
                <div style="flex-grow: 1; text-align: center; line-height: 0;">
                    <h2 class="drawer-header__title mobil0size" v-if="!!activeDynamicTab">{{
                        getDashboardName(activeDynamicTab['title']) }}</h2>
                </div>

                <span class="drawer-header__close" @click="dialogVisibleChartsList = false, selectedCharts = []"
                    size="small">
                    <i class="fa-solid fa-xmark"></i>
                </span>
            </div>

            <!-- heap status -->
            <div v-if="editDashboard" style="display: inline-flex; align-items: center; gap: 1rem; padding: 12px 24px 0;">
                <div class="heap-status">
                    <span></span>
                    Текущие элементы
                </div>
                <div class="heap-status">
                    <span></span>
                    Другие элементы
                </div>
            </div>

            <!-- body -->
            <div class="drawer-body" v-for="(category, index) in categoryChartList" :key="index + '-categories'">
                <el-divider content-position="left">{{ category.name }}</el-divider>
                <div class="chart-list">
                    <el-skeleton v-for="item in category.children" :key="item.componentName + '-categories-item'"
                        :loading="loading" animated>
                        <template slot="template">
                            <div class="chart-list__item">
                                <span class="chart-list__item-status">
                                    <i class="fa-regular fa-square"></i>
                                </span>
                                <el-skeleton-item variant="image"
                                    style="width: 56px; height: 56px; margin: 0 auto; border-radius: 5px;" />
                                <div style="padding: 14px;">
                                    <el-skeleton-item variant="h3" style="width: 80%;" />
                                </div>
                            </div>
                        </template>

                        <template>
                            <label @click="validationLimit(item.componentName)" class="chart-list__item"
                                :class="{ selected: isSelectedCharts(item.componentName), done: isAvilableCharts(item.componentName), self_selected: isSelfSelectedCharts(item.componentName), 'not-current-element': editDashboard && activeDynamicTab && !activeDynamicTab['dashboard'].some(el => el.componentName === item.componentName) && availableCharts.some(el => el.componentName === item.componentName) }"
                                :for="item.componentName">
                                <!-- checkbox -->
                                <span class="chart-list__item-status">
                                    <i v-if="isAvilableCharts(item.componentName)" class="fa-solid fa-check"></i>
                                    <span v-else>
                                        <i v-if="selectedCharts.some(evt => evt.componentName === item.componentName)"
                                            class="fa-solid fa-check"></i>
                                        <i v-else class="fa-regular fa-square"></i>
                                        <!-- fa-flip -->
                                    </span>
                                </span>

                                <i v-if="!!item.icon" :class="item.icon"></i>
                                <i v-else :class="item.icons"></i>
                                {{ item.name | truncate(18, '...') }}

                                <input v-model="selectedCharts" type="checkbox" name="chart" :id="item.componentName"
                                    :value="item"
                                    :disabled="availableCharts.some(evt => evt.componentName === item.componentName) || (lengthOfArray(selectedCharts) >= (editDashboard ? 12 - (activeDynamicTab ? lengthOfArray(activeDynamicTab['dashboard']) : 0) : (12 - (currentDynamicTab && currentDynamicTab['dashboard'] ? (activeDynamicTab ? lengthOfArray(activeDynamicTab['dashboard']) : 0) : 0))) && selectedCharts.every(evt => evt.componentName !== item.componentName))">
                                <!-- 1) availableCharts.some(evt => evt.componentName === item.componentName) --- dashboardga qo'shilgan chart larni disabled qilish un -->
                                <!-- 2) lengthOfArray(selectedCharts) >= (editDashboard ? 12 - lengthOfArray(activeDynamicTab['dashboard']) : 12) && selectedCharts.every(evt => evt.componentName !== item.componentName) --- limit 12 yoki 12-n taga yetkanda qolganlarini disabled qilish un -->
                            </label>
                        </template>
                    </el-skeleton>
                </div>
            </div>

            <!-- footer -->
            <div class="drawer-footer drawer-footer-mobil">
                <el-button @click="saveAndCloseCreateDashboard" type="primary"> Сохранить и закрыть</el-button>
                <div class="drawer-footer__count-box"
                    :style="{ background: statusCounter(selectedCharts), 'border-color': lengthOfArray(selectedCharts) > 0 ? statusCounter(selectedCharts) : '#b3d8ff' }">
                    <span style="color: #409eff;"
                        v-if="activeDynamicTab && lengthOfArray(activeDynamicTab['dashboard']) >= 12 && editDashboard">
                        Полностью завершенный
                    </span>
                    <span v-else :style="{ color: lengthOfArray(selectedCharts) > 0 ? '#fff' : '#409EFF' }">
                        <span class="counter">
                            {{ lengthOfArray(selectedCharts) + (currentDynamicTab && currentDynamicTab['dashboard'] &&
                                typeof currentDynamicTab['dashboard'] != 'string' ?
                                currentDynamicTab['dashboard'].length : 0) }}
                        </span> /
                        <span>
                            {{ editDashboard ? 12 - (activeDynamicTab ? lengthOfArray(activeDynamicTab['dashboard']) : 0) :
                                12 }}
                        </span>
                    </span>
                </div>

                <el-select :disabled="editDashboard" class="create-or-select-input" v-model="createNewTab"
                    placeholder="Select or create dashboard name" allow-create filterable clearable>
                    <el-option v-for="item in dynamicTabs" :key="item.name" :label="item.title" :value="item.name"
                        :disabled="12 - item.dashboard.length < selectedCharts.length || item.dashboard.length === 12">
                        <span style="float: left">{{ item.title }}</span>
                        <span style="float: right; color: #8492a6; font-size: 13px">
                            {{ lengthOfArray(item.dashboard) }} / 12
                        </span>
                    </el-option>
                </el-select>
            </div>
        </el-drawer>

        <!-- show when edit dashboard -->
        <div v-if="editDashboard" class="bg-opacity"></div>
        <!-- ------------- DASHBOARD ENDS ------------------------------------------------- -->



        <el-dialog :title="$t('message.deal')" :visible.sync="visibleShow" width="80%" @closed="empty()">
            <crm-show :deal_id="selectedDeal" :open="visibleShow"></crm-show>
        </el-dialog>

        <div class="my-modal">
            <el-dialog :title="$t('message.total_amount') + ' : ' + paymentPointTotal" :visible.sync="dealPaymentDrawer">
                <DealPaymentsInChart :selectedPoint="paymentSelectedPoint"></DealPaymentsInChart>
            </el-dialog>
        </div>

        <div class="app-modal app-modal__full modal-color-bg">
            <el-drawer :with-header="false" :visible.sync="stageDealDrawer" size="80%" :append-to-body="true"
                class="my-bgd body_scroll_80" ref="drawerStageDeals">
                <stage-deals :stage_id="stage_id" :filterForm="filterForm" ref="drawerStageDealsChild" drawer="drawerStageDeals"></stage-deals>
            </el-drawer>
            <el-drawer :with-header="false" :visible.sync="crmShowClient" size="80%" :append-to-body="true"
                class="my-bgd body_scroll_80" ref="drawerClientShow" @opened="drawerOpened('drawerClientShowChild')"
                @closed="drawerClosed('drawerClientShowChild')">
                <crm-show-client :selectedItem="selectedClient" ref="drawerClientShowChild" drawer="drawerClientShow">
                </crm-show-client>
            </el-drawer>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import { GridLayout, GridItem } from "vue-grid-layout";
import { homeDashboard } from '@/store/modules/dashboard/properties/dashboard'

import TodoStatus from '@/components/dashboard/TodoStatus'
import SourcesOfTransactions from '@/components/dashboard/SourcesOfTransactions'
import DealsByManager from '@/components/dashboard/DealsByManager'
import Goals from '@/components/dashboard/Goals'
import DealsByCountry from '@/components/dashboard/DealsByCountry'
import SaleByPeriod from '@/components/dashboard/SaleByPeriod'
import ActivityPerWeek from '@/components/dashboard/ActivityPerWeek'
import LatestFiles from '@/components/dashboard/LatestFiles'
import ReasonsForRemovingDeal from '@/components/dashboard/ReasonsForRemovingDeal'
import PaymentsByPeriod from '@/components/dashboard/PaymentsByPeriod'
import TermsOfPayment from '@/components/dashboard/TermsOfPayment'
import PaymentsByPeriodArea from '@/components/dashboard/PaymentsByPeriodArea'
import StatisticsOnSalesOfGoods from '@/components/dashboard/StatisticsOnSalesOfGoods'
import DeliveryConditions from '@/components/dashboard/DeliveryConditions'
import TransactionType from '@/components/dashboard/TransactionType'
import Contractor from '@/components/dashboard/Contractor'
import TaskMap from '@/components/dashboard/TaskMap'
import SuccessfullTransactions from '@/components/dashboard/SuccessfullTransactions'
import Categories from '@/components/dashboard/Categories'
import Currency from '@/components/dashboard/Currency'
import ManagersProfit from '@/components/dashboard/ManagersProfit'
import ClientBirthday from '@/components/dashboard/ClientBirthday'
// ------------------------------------------------------------------------------------------

import GrossTopLeft from "../components/chart/grossTopLeft";
import chartMultiple from "../components/chart/chartMult"; // 2
// import ShareGrossProfit from "../components/chart/shareGrossProfit"; // 6
// import DeletTask from "../components/chart/deletTask"; // 9
// import PaymentChart from "../components/chart/paymentChart"; // 10
// import MoneyType from "@/components/chart/moneyType"; // 11
// import DealPaymentTerms from "../components/chart/dealPaymentTerms"; // 12
// import ProductSellStatisticsChart from "../components/chart/productSellStatisticsChart"; // 13
// import shipmentTermsChart from "../components/chart/shipmentTermsChart"; // 14
// import dealTypesChart from "../components/chart/dealTypesChart"; // 15
// import CounterColumn from "../components/chart/CounterColumn.vue"; // 16
// import TimeLine from '../components/chart/timeline.vue' // 17
// import categoryClothes from '../components/chart/category/index.vue'; // 19
// import categoryChildClothes from '../components/chart/category/index_childs.vue'; // 19 sub

import DealPaymentsInChart from "../components/chart/paymentModal/deal-payments";
import CrmShow from "@/views/archivedDeals/components/crm-show";
import selectCompany from "@/components/inventory/select-company";
import selectClient from "@/components/inventory/select-client";
import selectUser from "@/components/inventory/select-users";
import selectFilial from "@/components/inventory/select-filial";
// import selectCountry from "@/components/inventory/select-country";
import categoryStatistic from "../components/chart/categoryStatisticBySellingProduct";
import selectCategory from "@/components/inventory/select-category";
import stageDeals from "../components/homePageComponents/stageDeals";
import crmShowClient from "@/views/clients/components/show";
import Calendar from "@/components/dashboard/Calendar";
import selectDay from '../components/selects/select-day.vue'

import _ from "lodash";
import { formatMoney } from "@/filters/index";
import { mapGetters, mapActions } from "vuex";

export default {
    name: "Home",
    components: {
        Calendar,
        GridLayout, GridItem,
        TodoStatus,
        SourcesOfTransactions,
        DealsByManager,
        Goals,
        DealsByCountry,
        SaleByPeriod,
        ActivityPerWeek,
        LatestFiles,
        ReasonsForRemovingDeal,
        PaymentsByPeriod,
        TermsOfPayment,
        PaymentsByPeriodArea,
        StatisticsOnSalesOfGoods,
        DeliveryConditions,
        TransactionType,
        Contractor,
        TaskMap,
        SuccessfullTransactions,
        Categories,
        Currency,
        ManagersProfit,
        ClientBirthday,
        // ------------------------------
        chartMultiple,
        GrossTopLeft,
        CrmShow,
        selectCompany,
        selectClient,
        selectUser,
        selectFilial,
        DealPaymentsInChart,
        categoryStatistic,
        selectCategory,
        stageDeals,
        crmShowClient,
        selectDay,
    },
    data() {
        return {
            // DASHBOARD STARTS
            editDashboard: true,
            loading: true,
            isScrollTop: false,
            calendar: false,
            createNewTab: '',
            activeName: '',
            dialogVisibleChartsList: false,
            dialogVisibleCreateDashboardName: false,
            dialogVisibleDashboard: false,
            editDashboardName: { is: false, name: '', title: '' },
            editDashboard: false, // for drag and resize
            selectedCharts: [],
            availableCharts: [],
            dashboardNameList: [],
            dynamicTabs: [
                // {
                //     title: 'Home',
                //     show: true,
                //     name: '1',
                //     dashboard: []
                // }
            ],
            homeDashboard,
            // DASHBOARD ENDS
            filterForm: {},
            selectedClient: {},
            crmShowClient: false,
            filterPayment: {},
            activityMark: 0,
            update: 0,
            paymentPointTotal: "",
            selectedDeal: null,
            visibleShow: false,
            selectFilial: "",
            fetchAllData: false,
            dealPaymentDrawer: false,
            loadingData: false,
            activityPeriod: "weekly",
            countryDealsPeriod: "weekly",
            productSellStatisticsPeriod: "weekly",
            dealGoalsPeriod: "monthly",
            dealGoalsByAmounts: "by_count",
            allPeriodChange: "weekly",
            topSuccessfulDealsPeriod: "weekly",
            dealGoalsForm: {
                amount: 0,
                goal_amount: 0,
                percentage: 0,
                symbol: '',
            },
            percentage: 75,
            filial_id: null,
            paymentSelectedPoint: {},
            category_parent_id: null,
            stage_id: null,
            stageDealDrawer: false,
            clients_birth_day: []
        };
    },
    watch: {
        filterForm: {
            handler: async function (val) {
                this.filterPayment.user_id = val.user_id
                if (val && Object.keys(val).length > 0) {
                    this.fetchData(val);
                }
            },
            deep: true,
            immediate: true,
        },
        allPeriodChange: {
            handler: async function (newVal, oldVal) {
                this.activityPeriod = newVal;
                this.countryDealsPeriod = newVal;
                this.productSellStatisticsPeriod = newVal;
                this.topSuccessfulDealsPeriod = newVal;
                if (newVal !== 'weekly') {
                    this.dealGoalsPeriod = newVal;
                }
                // await this.debouncedUpdateDealStages(this, this.filterForm);
            },
            deep: true,
            immediate: true,
        },
        countryDealsPeriod(val) {
            this.updateCountryDeals({ period: val, filter: this.filterForm });
        },
        topSuccessfulDealsPeriod(val) {
            this.topSuccessfulDealsData({ period: val, filter: this.filterForm });
        },
        activityPeriod(val) {
            this.debouncedActivityDiagram(val, this.filterForm, this);
        },
        dealGoalsPeriod(val) {
            this.updateDealGoals({ period: val, filter: this.filterForm });
        },
        watchGoalParams() {

            setTimeout(() => {
                if (this.dealGoalsByAmounts === "by_count") {
                    this.dealGoalsForm.amount = this.dealGoals.count;
                    this.dealGoalsForm.goal_amount = this.dealGoals.goal_count;
                    this.dealGoalsForm.percentage = this.dealGoals.count_percentage;
                    this.dealGoalsForm.symbol = "";
                } else if (this.dealGoalsByAmounts === "by_money_amount") {
                    this.dealGoalsForm.amount = this.dealGoals.money_amount;
                    this.dealGoalsForm.goal_amount = this.dealGoals.goal_money_amount;
                    this.dealGoalsForm.percentage = this.dealGoals.money_amount_percentage;
                    this.dealGoalsForm.symbol = process.env.VUE_APP_CURRENCY;
                } else if (this.dealGoalsByAmounts === "by_meetings") {
                    this.dealGoalsForm.amount = this.dealGoals.meetings;
                    this.dealGoalsForm.goal_amount = this.dealGoals.goal_meetings;
                    this.dealGoalsForm.percentage = this.dealGoals.meetings_percentage;
                    this.dealGoalsForm.symbol = "";
                } else {
                    this.dealGoalsForm.amount = this.dealGoals.profit;
                    this.dealGoalsForm.goal_amount = this.dealGoals.goal_profit;
                    this.dealGoalsForm.percentage = this.dealGoals.profit_percentage;
                    this.dealGoalsForm.symbol = process.env.VUE_APP_CURRENCY;
                }
            }, 1000);
        },
        editDashboard(val) {
            if (val) {
                this.createNewTab = this.activeName;
            }
        }
    },
    computed: {
        ...mapGetters({
            mode: "MODE",
            user: "auth/user",
            filials: "filials/userFilials",
            dealStages: "deals/dealStages",
            deletedDealsForStage: "deals/deletedDealsForStage",
            latestFiles: "dealOffers/latest_files",
            activities: "home/activities",
            countryDeals: "home/countryDeals",
            dealGoals: "home/dealGoals",
            filter: "home/filter",
            dashboardConstructs: "dashboardConstruct/list",
        }),
        activeDynamicTab() {
            return this.dynamicTabs.find(el => el.name == this.activeName);
        },
        currentDynamicTab() {
            return this.dynamicTabs.find(el => el.name == this.createNewTab);
        },

        async watchGoalParams() {
            return (
                this.filial_id +
                this.dealGoalsByAmounts +
                this.dealGoalsPeriod +
                this.loadingData +
                this.dealGoals
            );
        },

        // DASHBOARD
        categoryChartList() {
            return [
                {
                    name: 'Home',
                    children: [...this.homeDashboard]
                }
            ]
        },
        isSelfSelectedCharts() {
            return (evt) => {
                return this.currentDynamicTab && this.currentDynamicTab.dashboard && typeof this.currentDynamicTab.dashboard != 'string' && this.currentDynamicTab.dashboard.find(el => el.componentName == evt);
            }
        },
        isSelectedCharts() {
            return (evt) => {
                return this.selectedCharts.some(item => item.componentName === evt)
            }
        },
        isAvilableCharts() {
            return (evt) => {
                return this.availableCharts.some(item => item.componentName === evt)
            }
        },
        filterDynamicTabs() {
            return this.dynamicTabs.filter((item) => {
                return item.name == this.dashboardNameList.find(evt => evt == item.name)
            })
        },
        lengthOfArray() {
            return (element) => element.length
        },
        getDashboardName() {
            return (msg) => !this.editDashboard ? "Новая панель управления" : !!msg || msg === 0 ? msg : 'Oops!'
        },
        statusCounter() {
            return (el) => {
                if (el.length == 0) {
                    return "#ecf5ff" // default
                } else if (el.length > 0 && el.length <= (this.editDashboard ? 6 - (this.activeDynamicTab ? this.lengthOfArray(this.activeDynamicTab['dashboard']) : 0) : 6)) {
                    return "#67c23a" // success
                } else if ((el.length > (this.editDashboard ? 6 - (this.activeDynamicTab ? this.lengthOfArray(this.activeDynamicTab['dashboard']) : 0) : 6)) && (el.length < (this.editDashboard ? 12 - (this.activeDynamicTab ? this.lengthOfArray(this.activeDynamicTab['dashboard']) : 0) : 12))) {
                    return "#e6a23c" // warning
                } else {
                    return "#f56c6c" // danger
                }
            }
        },
    },
    created: function () {
        this.filterForm = JSON.parse(JSON.stringify(this.filter));
        if (this.filials && this.filials.length === 0)
            this.updateFilialInventory();
    },
    mounted() {
        this.setDashboardNameList();
        this.getDashboardConstructs()
            .then(res => {
                this.availableCharts = [];
                if (this.dashboardConstructs && this.dashboardConstructs.length > 0) {
                    this.dynamicTabs = JSON.parse(JSON.stringify(this.dashboardConstructs));
                    this.activeName = this.dynamicTabs[0].name;
                    this.dynamicTabs.forEach(board => {
                        if (['string', 'number', null, 'boolean'].includes(typeof board.dashboard)) {
                            board.dashboard = [];
                        }
                        if (board.show) {
                            this.dashboardNameList.push(board.name);
                        }
                        if (board.dashboard && typeof board.dashboard !== "string") {
                            this.availableCharts = [...this.availableCharts, ...board.dashboard];
                        }
                    });
                    this.findCalendar();
                }
            }).catch(err => {

            });
         this.getInformationOfClients().then((res) => {
            this.clients_birth_day = res.clients;
        }).catch((err) => {

        });

        // related to the dashboard
        document.body.style.overflow = 'hidden'
        document.querySelector('.app-main').style.margin = "0 0 1rem"

        setTimeout(() => {
            this.loading = false
        }, 5000);
    },
    beforeRouteLeave(to, from, next) {
        document.body.style.overflow = "visible";
        document.querySelector('.app-main').style.margin = "1rem 12px"
        next()
    },
    methods: {
        ...mapActions({
            updateDealStages: "deals/dealStages",
            updateLatestFiles: "dealOffers/getLatestFiles",
            downloadFile: "dealOffers/downloadLatestFile",
            activityDiagram: "home/activityDiagram",
            updateFilialInventory: "filials/userFilials",
            updateCountryDeals: "home/countryDeals",
            updateDealGoals: "home/dealGoals",
            updateTaskCounts: "home/tasksByCompleted",
            productSellStatisticsData: "home/productSellStatistics",
            topSuccessfulDealsData: "home/topSuccessfulDeals",
            updateCategoryInventory: "categories/inventory",
            empty: "deals/empty",
            getDashboardConstructs: "dashboardConstruct/index",
            deleteDashboardConstruct: "dashboardConstruct/destroy",
            storeDashboardConstruct: "dashboardConstruct/store",
            updateDashboardConstruct: "dashboardConstruct/update",
            getInformationOfClients: "home/getInformationOfClients"
        }),

        updateStageDeals(stage_id) {
            this.stage_id = stage_id;
            this.stageDealDrawer = true;
            setTimeout(() => {
                this.stage_id = null;
            }, 300);
        },
        closeDrawer(drawer) {
            if (this.$refs[drawer] && _.isFunction(this.$refs[drawer].closeDrawer)) {
                this.$refs[drawer].closeDrawer();
            }
        },
        
        paymentPointSelection(val) {
            this.paymentSelectedPoint = val;
            this.dealPaymentDrawer = true;
        },
        categoryChart(id) {
            this.products.findIndex(x => x.id === id)
        },
        paymentPointAmount(val) {
            this.paymentPointTotal = formatMoney(val, 2);
        },
        showDeal(deal_id) {
            if (deal_id) {
                this.visibleShow = true;
                this.selectedDeal = deal_id;
            }
        },
        async fetchData(filter) {
            this.loadingData = true;

            await this.debouncedUpdateDealStages(this, filter);

            await this.updateTaskCounts();

            await this.updateDealGoals({ period: this.dealGoalsPeriod, filter: filter });
            await this.debouncedUpdateLatestFiles(this, filter);
            await this.productSellStatisticsData({ period: this.productSellStatisticsPeriod, filter: filter });
            await this.topSuccessfulDealsData({
                period: this.topSuccessfulDealsPeriod,
                filter: filter,
            });
            await this.debouncedUpdateCountryDeals(
                this.countryDealsPeriod,
                filter,
                this
            );
            await this.debouncedActivityDiagram(
                this.activityPeriod,
                filter,
                this
            );
        },

        debouncedUpdateDealStages: _.debounce((self, filter) => {
            if (_.isFunction(self.updateDealStages)) {
                self.updateDealStages({ filter: filter, period: self.allPeriodChange });
            }
        }, 500),

        debouncedUpdateCountryDeals: _.debounce((period, filter, self) => {
            if (_.isFunction(self.updateCountryDeals)) {
                self.updateCountryDeals({ period: period, filter: filter });
            }
        }, 500),

        debouncedUpdateLatestFiles: _.debounce((self, filter) => {
            if (_.isFunction(self.updateLatestFiles)) {
                self.updateLatestFiles(filter);
            }
        }, 500),

        debouncedActivityDiagram: _.debounce((period, filter, self) => {
            if (_.isFunction(self.activityDiagram)) {
                self.activityDiagram({ period: period, filter: filter }).then(
                    (res) => {
                        let activity = Object.values(self.activities);
                        let marks = activity.reduce(function (acc, obj) {
                            return acc + obj.mark;
                        }, 0);
                        if (activity.length > 0) {
                            self.activityMark = Math.round(
                                marks / activity.length
                            );
                        }
                    }
                );
                self.loadingData = false;
            }
        }, 500),

        downloadLatestFile(file) {
            this.downloadFile(file.url)
                .then((res) => {
                    const url = window.URL.createObjectURL(
                        new Blob([res.data])
                    );

                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", file.name);
                    document.body.appendChild(link);
                    link.click();
                })
                .catch((err) => {
                    this.$alert(err);
                });
        },
        stageColor(id) {
            if (id === this.dealStages.lastStageId) {
                return "bg-sausage";
            }
            return "";
        },
        drawerClosed(ref) {
            if (this.$refs[ref]) {
                this.$refs[ref].closed()
            }
            if (this.reloadList === true) {
                this.fetchData();
                this.afterFetchData();
            }
            if (_.isFunction(this.empty)) {
                this.empty()
            }
        },
        drawerOpened(ref) {
            if (this.$refs[ref]) {
                if (_.isFunction(this.$refs[ref].opened)) {
                    this.$refs[ref].opened()
                }
            }
        },

        // DASHBOARD
        vModel(el, evt) {
            if (el.componentName === "DealsByCountry") {
                this.countryDealsPeriod = evt
            } else if (el.componentName === "ActivityPerWeek") {
                this.activityPeriod = evt
            } else if (el.componentName === "StatisticsOnSalesOfGoods") {
                this.productSellStatisticsPeriod = evt
            } else if (el.componentName === "SuccessfullTransactions") {
                this.topSuccessfulDealsPeriod = evt
            }
        },
        addNewTab() {
            try {
                let existind_tab = this.dynamicTabs.find(el => el.name == this.createNewTab);
                let selected_count = existind_tab ? this.selectedCharts.length + existind_tab.dashboard.length : this.selectedCharts.length;
                if (this.createNewTab && selected_count <= 12) {
                    let last_index = 10000000;
                    let newTabName = last_index + '';

                    if (existind_tab) {
                        newTabName = this.createNewTab;

                        this.dynamicTabs.find(el => el.name == newTabName)['dashboard'].push(...this.selectedCharts);
                        let back_dash = this.dynamicTabs.find(el => el.name == newTabName)['dashboard'];
                        this.editCurrentTabBackEnd({
                            id: this.dynamicTabs.find(el => el.name == parseInt(newTabName)).id,
                            diagrams: back_dash,
                        });
                        this.activeName = newTabName;
                        this.dashboardNameList.push(newTabName);
                    } else {
                        let tab_form = {
                            title: this.createNewTab,
                            name: newTabName,
                            show: true,
                            dashboard: [...this.selectedCharts]
                        }
                        this.dynamicTabs.push(tab_form);
                        this.addNewTabBackEnd({
                            name: this.createNewTab,
                            index: newTabName,
                            show: true,
                            diagrams: [...this.selectedCharts],
                        });
                    }

                    this.availableCharts.push(...this.selectedCharts);
                    this.createNewTab = ''
                    this.selectedCharts = [];

                    this.$message({
                        message: 'Muvaffaqiyatli yaratildi',
                        type: 'success'
                    });
                } else if (selected_count > 12) {
                    // this.createNewTab = ''
                    this.$message.error('Please, 12 most check!');
                } else {
                    this.createNewTab = ''
                    this.$message.error('Please, Create new dashboard name!');
                }
            } catch (error) {
                console.log('error addNewTab', error);
            }
        },
        addNewTabBackEnd(form) {
            this.storeDashboardConstruct(form)
                .then(res => {
                    this.dynamicTabs[this.dynamicTabs.length - 1].id = JSON.parse(JSON.stringify(res.data.result.data.dashboard.id));
                    this.dynamicTabs[this.dynamicTabs.length - 1].name = JSON.parse(JSON.stringify(res.data.result.data.dashboard.id)) + '';
                    this.dynamicTabs[this.dynamicTabs.length - 1].dashboard = JSON.parse(JSON.stringify(res.data.result.data.dashboard.dashboard));
                    this.activeName = this.dynamicTabs[this.dynamicTabs.length - 1].name;
                    this.dashboardNameList.push(this.activeName);
                })
                .catch(err => {
                    console.log(err, 'add New Tab error occured');
                })
        },
        editCurrentTab() {
            if (this.selectedCharts.length <= 12 - this.activeDynamicTab['dashboard'].length) {
                this.selectedCharts.forEach((item, index) => {
                    item.i = this.activeDynamicTab['dashboard'].length + index + ''
                })
                this.dialogVisibleChartsList = false
                this.activeDynamicTab['dashboard'].push(...this.selectedCharts);
                let edit_tab = this.dynamicTabs.find(el => el.name == parseInt(this.createNewTab.name));
                if (edit_tab && edit_tab.id) {
                    this.editCurrentTabBackEnd({
                        id: edit_tab.id,
                        index: this.activeName,
                        name: this.activeDynamicTab.name,
                        diagrams: this.selectedCharts,
                    });
                }

                this.availableCharts.push(...this.selectedCharts)
                this.selectedCharts = []

                this.$message({
                    message: "Muvaffaqiyatli qo'shildi",
                    type: 'success'
                });
            } else {
                this.$message.error(`Limit ${12 - (this.activeDynamicTab ? this.lengthOfArray(this.activeDynamicTab['dashboard']) : 0)} tadan oshib ketdi`);
            }
        },
        editCurrentTabBackEnd(form) {
            this.updateDashboardConstruct(form)
                .then(res => {
                })
                .catch(err => {
                    console.log(err, 'edit Tab error occured');
                })
        },
        changeDashboardShow(item) {
            item.show = !item.show;
            this.editCurrentTabBackEnd({ id: item.id, show: item.show });
        },
        updateDashboardSizes() {
            this.editDashboard = false;
            let current_tab = this.dynamicTabs.find(el => el.name == this.activeName);
            this.editCurrentTabBackEnd({
                id: current_tab.id,
                index: current_tab.name,
                name: current_tab.title,
                diagrams: current_tab.dashboard
            });
        },

        saveAndCloseCreateDashboard() {
            if (this.editDashboard) {
                this.editCurrentTab()
            } else {
                this.addNewTab()
            }
            this.dialogVisibleChartsList = false
        },
        removeTab(targetName) {
            let tabs = this.dynamicTabs;
            let activeName = this.activeName;
            if (activeName === targetName) {
                tabs.forEach((tab, index) => {
                    if (tab.name === targetName) {
                        let nextTab = tabs[index + 1] || tabs[index - 1];
                        if (nextTab) {
                            activeName = nextTab.name;
                        }
                    }
                });
            }

            this.activeName = activeName;
            this.dynamicTabs = tabs.filter(tab => tab.name !== targetName);
        },
        // default barcha dashboardlar yoqilgan holatda turishligi un
        setDashboardNameList() {
            this.dashboardNameList = []
            this.dynamicTabs.forEach(item => {
                this.dashboardNameList.push(item.name)
            })
        },
        removeChart(index, itemIndex, elementName) {
            this.$confirm('Вы уверены, что хотите удалить эту диаграмму?', 'Предупреждение', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Отменить',
                type: 'warning'
            }).then(() => {
                this.dynamicTabs[index]['dashboard'].splice(itemIndex, 1)
                this.availableCharts.forEach((item, index) => {
                    if (item.componentName == elementName) {
                        this.availableCharts.splice(index, 1)
                    }
                })
                this.$message({
                    type: 'success',
                    message: 'Delete completed'
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: 'Delete canceled'
                });
            });
        },
        validationLimit(evt) {
            if (this.selectedCharts.length === (this.editDashboard ? 12 - (this.activeDynamicTab ? this.lengthOfArray(this.activeDynamicTab['dashboard']) : 0) : 12 - (this.currentDynamicTab && this.currentDynamicTab['dashboard'] ? this.lengthOfArray(this.currentDynamicTab['dashboard']) : 0)) && this.selectedCharts.every(el => el.componentName !== evt)) {
                this.$message({
                    message: `Limit ${this.editDashboard ? 12 - (this.activeDynamicTab ? this.lengthOfArray(this.activeDynamicTab['dashboard']) : 0) : 12} tadan oshmasligi kerak!`,
                    type: 'warning'
                })
            }
        },
        handleScroll(event) {
            event.target.scrollTop > 24 ? this.isScrollTop = true : this.isScrollTop = false
        },
        handleTabClick() {
            this.$refs.dashboard.scrollTo({ top: 0, behavior: 'smooth' });
            this.findCalendar();
        },
        findCalendar() {
            setTimeout(() => {
                let dashboard = this.filterDynamicTabs.find(e => e.name == this.activeName);
                let calendar = dashboard.dashboard.find(el => el.componentName == 'Calendar');
                this.calendar = !!calendar;
            }, 200);
        },
        async handelEditDashboardName(evt) {
            if (!this.editDashboardName.title && this.editDashboardName.is && this.editDashboardName.name == evt.name) {
                this.$message({
                    message: 'Please Enter Name',
                    type: 'success'
                })
            } else if (this.editDashboardName.name && this.editDashboardName.name != evt.name) {
                this.editDashboardName.name = evt.name
                this.editDashboardName.title = evt.title
            } else {
                this.editDashboardName.is = !this.editDashboardName.is
                if (!this.editDashboardName.is) {
                    evt.title = this.editDashboardName.title;
                    this.$message({
                        message: 'Muvaffaqiyatli tahrir qilindi',
                        type: 'success'
                    })
                    await this.editCurrentTabBackEnd({
                        id: evt.id,
                        index: evt.name,
                        name: evt.title,
                    });

                    this.editDashboardName = { is: false, name: '', title: '' }
                } else {
                    this.editDashboardName.name = evt.name
                    this.editDashboardName.title = evt.title
                }
            }
        },
        removeDashboard(evt, index) {
            this.$confirm('This will permanently delete the dashboard. Continue?', 'Warning', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                type: 'warning'
            }).then(() => {
                evt['dashboard'].forEach((item) => {
                    this.availableCharts.some((el, i) => {
                        if (el.componentName === item.componentName) {
                            this.availableCharts.splice(i, 1)
                        }
                    })
                })
                this.dynamicTabs.splice(index, 1);
                this.deleteDashboardConstruct(evt.id);
                this.$message({
                    type: 'success',
                    message: 'Delete completed'
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: 'Delete canceled'
                });
            });

        }
    },
    filters: {
        truncate: function (text, length, suffix) {
            if (text.length > length) {
                return text.substring(0, length) + suffix;
            } else {
                return text;
            }
        }
    }
};
</script>

<style lang="scss">
// DASHBOARD
.dashboard-tabs .el-tabs__header {
    display: none;
}

.el-divider__text {
    font-size: 18px !important;
}

.drawer-footer .el-button,
.drawer-footer .el-select .el-input .el-input__inner {
    border-radius: 0 !important;

}

// ==================================================================

.bg-redcha {
    background-color: #ff6ba6bf !important;
}

.task-scroll-khan {
    overflow: hidden;
    overflow-y: scroll;
    height: 345px;
}

.task-scroll-khan::-webkit-scrollbar {
    width: 5px;
}

.task-scroll-khan::-webkit-scrollbar-track {
    box-shadow: none;
    background-color: inherit;
}

.task-scroll-khan::-webkit-scrollbar-thumb {
    background-color: #0c253d6b;
    outline: 1px solid #ffffff12;
}

.el__cardnight {
    background: #100e1d !important;
    border: 1px dashed #323248 !important;
}

.el__cardday {
    background: #fff !important;
    border: 1px solid #fff;
}

.text__night {
    color: #000;
}

.mode__2 input {
    background: #323248 !important;
    border: 1px solid #323248 !important;
    color: #fff;
}

.task-scroll-khan-product {
    overflow: hidden;
    overflow-y: scroll;
    height: 400px;
}

.task-scroll-khan-product::-webkit-scrollbar {
    width: 5px;
}

.task-scroll-khan-product::-webkit-scrollbar-track {
    box-shadow: none;
    background-color: rgba(12, 37, 61, 0.95);
}

.task-scroll-khan-product::-webkit-scrollbar-thumb {
    background-color: #0c253d6b;
    outline: 1px solid #ffffff12;
}


.filter-filyal .el-input__inner {
    background-color: #0e2b4500 !important;
    border-color: #bfc4c9 !important;
    color: #606266 !important;
    border-radius: 8px;
}

.w50 {
    width: 55%;
    padding-right: 5px;
}

.w20 {
    width: 20%;
}

.w10 {
    width: 5%;
}

.style-view {
    color: #fff;
    position: absolute;
    right: 0px;
    z-index: 2;
    font-size: 20px;
}

.style-view:hover {
    color: #f8b01a;
}

.doc-style {
    position: relative;
}

.custom__justify {
    flex-direction: row;
    justify-content: space-between;
    padding-right: 1rem;
}

// .custom__button label span {
//     // background: #0e2b4500 !important;
//     border-color:#e5e5e561 !important;
// }
.custom__button label span {
    background: #0e2b4500 !important;
    border-color: #e5e5e561 !important;
    color: #fff !important;
}

.custom__button label input:checked+.el-radio-button__inner {
    background-color: #409EFF !important;
}

.circle__user {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    background: #f8b01a;
    border: 2px solid #8f6408;
    color: #fff;
    border-radius: 50%;
}

.user__box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.user__footer {
    display: flex;
    margin-top: 2px;
    flex-direction: column;
}

.user__footer span {
    font-size: 13px !important;
}

.user__deal {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}

.user__deal span {
    font-size: 14px !important;
}

.user__name span {
    font-size: 14px !important;
}

// asasasass
.agents__table {
    width: 100%;
    color: #fff;
}

.agents__table thead tr th {
    text-align: center;
}

.agents__table tbody tr td {
    padding: 8px 5px;
}

.agents__box {
    display: flex;
    flex-direction: row;
}

.agents__half {
    width: 50%;
}

.agents__circle {
    display: block;
    width: 25px;
    height: 25px;
    border-radius: 50%;
}

.agents__green {
    background: #CCFF99;
}

.agents__red {
    background: #FF9999;
}

.agents__blue {
    background: #66B2FF;
}

.agents__yellow {
    background: #FFFF99;
}

.agents__center {
    text-align: center;
}

.agents__view span {
    font-size: 16px;
    cursor: pointer;
}

.agents__flex {
    display: flex;
    flex-direction: column;
}

@media screen and (max-height: 750px) {
    .agents__flex {
        display: flex;
        flex-direction: row;
    }

    .agents__full {
        width: 50%;
    }


}

.tech__item {
    display: flex;
    flex-direction: column;
    padding-top: 25px;
    // border-right: 1px solid rgb(53, 55, 68);
}

.tech__flex {
    display: flex;
}

.tech__color {
    display: block;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    margin-right: 10px;
}

.tech__name {
    color: #fff;
}

.tech__li {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px 0;
    cursor: pointer;
}

.tech__title {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
}

.tech__title span {
    color: #fff;
    font-size: 20px;
    margin-right: 10px;
    cursor: pointer;
}

.tech__title h4 {
    font-size: 18px;
    color: #fff;
    margin: 0;
}

.tech__box {
    width: 100%;
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
    height: 100%;
}


.tech__center {
    display: flex;
    align-items: center;
}

.tech__box::-webkit-scrollbar {
    height: 8px;
}

.tech__box::-webkit-scrollbar-thumb {
    background-color: #459EF7
}

.tech__box::-webkit-scrollbar-track {
    background-color: rgba(12, 37, 61, 0.95);
}

.without__margin div {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.margin__header {
    margin: 0 12px
}

.select__formday .el-input__inner {
    border-color: #bfc4c9 !important;
    color: #606266 !important;
}

.select__formnight .el-input__inner {
    border-color: #bdc2c7 !important;
    color: #ffffff !important;
}

.home {
    position: relative;
}

.h__responssive {
    height: 100%;
}
</style>

<!-- new dashboard style -->
<style lang="scss" scoped>
.home-status {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 24px;

    @media screen and (max-width: 992px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 767px) {
        grid-template-columns: repeat(1, 1fr);
    }
}

.create-or-select-input {
    width: 100%;
}

.drawer-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 40px;
    background-color: #fdfdfd;
    border-bottom: 1px solid #dddddd8f;

    // drawer-header__title
    &__title {
        color: #181818;
        // font-size: 1.5rem;
        font-weight: 600;
    }

    // drawer-header__close
    &__close {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        font-size: 20px;
        cursor: pointer;
        color: #181818;
        transition: color .1s ease-in-out;

        &:hover {
            color: #00b2ff;
        }
    }

    // drawer-header__count
    &__count,
    &__count span {
        color: #fff;
        font-size: 1rem;
        font-weight: 600;
    }
}

.drawer-body {
    padding: 12px 24px;
}

.drawer-footer {
    position: absolute;
    bottom: 80px;
    left: 50%;
    transform: translateX(-50%);
    display: inline-flex;
    align-items: center;
    box-shadow: 0 0 8px 5px #ccc;
    border-radius: 5px;
    overflow: hidden;

    // drawer-footer__save
    &__save {
        outline: none;
        height: 40px;
        font-size: 12px;
        font-weight: 600;
        padding: 10px 20px;
        color: #67c23a;
        background: #f0f9eb;
        border: 1px solid #c2e7b0;
        // border-radius: 4px 0 0 4px;
        transition: .1s;

        &:hover {
            background: #67c23a;
            border-color: #67c23a;
            color: #fff;
        }
    }

    // drawer-footer__count-box
    &__count-box {
        flex-shrink: 0;
        height: 40px;
        padding: 10px 20px;
        border: 1px solid #b3d8ff;
        // border-radius: 0 4px 4px 0;
        line-height: 1.2;

        span {
            font-size: 12px;
            font-weight: 600;

            .counter {
                font-size: 1rem;
            }
        }
    }

    .el-button--success {
        background: rgb(103, 194, 58) !important;
        border-color: rgb(103, 194, 58) !important;
    }
}

.dashboard {
    // max-height: calc(100vh - 60px);
    max-height: 100vh;
    overflow-y: auto;
    padding-bottom: 70px;

    // scrool bar style
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }

    &::-webkit-scrollbar-track {
        background: #ffffff;
        border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background-color: #409eff;
        border: 2px solid #fff0;
    }

    &:hover::-webkit-scrollbar-thumb {
        background-color: #66b1ff;
        border-color: #66b1ff;
    }

    .divider {
        margin: 0;
    }
}

.dashboard-navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    position: sticky;
    top: 0;
    z-index: 2;
    transition: all .25s ease-in-out;

    &.sticky {
        background-color: rgba($color: #fff, $alpha: .9);
        box-shadow: 7px 4px 33px #ccc;
        backdrop-filter: blur(5px);

    }

    &.fixed {
        position: fixed;
        left: 0;
        width: 100%;
        padding: 13px 30px 13px 94px;
    }

    // dashboard-navbar-list
    &-list {
        display: flex;
        align-items: center;
        gap: 12px;

        .el-button {
            margin: 0 !important;
        }
    }
}

.dashboard-list {
    margin: 0;
    padding: 0;
    list-style: none;

    // .dashboard-list__item
    &__item {
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 14px;

        // .dashboard-list__item-name
        &-name {
            position: relative;
            flex-grow: 1;
            height: 100%;
            border: 1px solid #ddd;
            padding: 0 0 0 12px;
            margin: 0;
            border-radius: 6px 0 0 6px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            user-select: none;
            transition: all .1s ease-in-out;
            color: #00b2ff;
            border-color: #b3d8ff;
            overflow: hidden;

            .name {
                font-size: 1rem;
                font-weight: 500;
            }

            .edit-input {
                outline: none;
                width: 100%;
                height: 100%;
                font-size: 1rem;
                font-weight: 500;
                padding: 0 12px;
                border: none;
                position: absolute;
                left: 0;
                top: 0;
            }

            &.disabled {
                color: #909399;
                background: #f4f4f5;
                border-color: #d3d4d6;
            }
        }

        // dashboard-list__item-label
        &-label {
            position: relative;
            outline: none;
            overflow: hidden;
            font-size: 1rem;
            line-height: 24px;
            height: 40px;
            padding: 8px 20px;
            color: #409eff;
            background-color: #ecf5ff;
            border: 1px solid #b3d8ff;
            margin: 0 !important;
            cursor: pointer;

            &:hover {
                background: #409eff;
                border-color: #409eff;
                color: #fff;
            }

            .fa-solid {
                width: 20px;
            }

            .checkbox-input {
                position: absolute;
                left: 0;
                top: 0;
                display: none;
            }
        }

        // dashboard-list__item-actions
        &-actions {
            display: inline-flex;
            align-items: center;

            .counter {
                font-size: 1rem;
                line-height: 24px;
                height: 40px;
                padding: 8px 20px;
                color: #409eff;
                border: 1px solid #b3d8ff;
                margin: 0 !important;
            }

            button {
                height: 40px;
                padding: 8px 20px;
                outline: none;
                border: none;
                border: 1px solid #b3d8ff;
                font-weight: 500;
                font-size: 14px;
                transition: .1s;
                color: #409eff;
                background: #ecf5ff;

                &:hover {
                    background: #409eff;
                    border-color: #409eff;
                    color: #fff;
                }

                &:last-child {
                    color: #f56c6c;
                    background: #fef0f0;
                    border-color: #fbc4c4;
                    border-radius: 0 6px 6px 0;

                    &:hover {
                        background: #f56c6c;
                        border-color: #f56c6c;
                        color: #fff;
                    }
                }

                &:disabled,
                &:disabled:hover {
                    color: #909399;
                    background: #f4f4f5;
                    border-color: #d3d4d6;
                    cursor: not-allowed;
                }
            }

            &.disabled .counter,
            &.disabled .dashboard-list__item-label {
                color: #909399 !important;
                background: #f4f4f5 !important;
                border-color: #d3d4d6 !important;
            }
        }
    }
}

.chart-list {
    // padding: 12px;
    display: grid;
    // grid-template-columns: repeat(8, 1fr);
    gap: 1rem;

    // .chart-list__item
    &__item {
        display: block;
        width: 100%;
        position: relative;
        margin: 0;
        padding: 12px;
        text-align: center;
        border: 1px solid #727b89;
        border-radius: 5px;
        background-color: #fff;
        color: #727b89;
        font-size: .8rem;
        font-weight: 500;
        cursor: pointer;
        user-select: none;
        transition: all .1s ease-in-out;
        outline: none;

        // .chart-list__item-status
        &-status {
            position: absolute;
            top: 1px;
            right: 0;
            width: 30px;
            height: 30px;
            padding: 4px;
            border-radius: 5px;
            // background-color: #f8fafc;
            display: inline-flex;
            align-items: center;
            justify-content: center;

            i {
                font-size: 1.5rem;
            }
        }

        input {
            display: none;
            position: absolute;
            top: 0;
            left: 0;
        }

        &>i {
            display: block;
            margin-bottom: 4px;
            font-size: 3.5rem;
        }

        &:hover {
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        }

        &:hover,
        &.selected {
            color: #00b2ff !important;
            border-color: #00b2ff !important;
        }

        &.done {
            border-color: #64748b !important;
            color: #64748b !important;
        }

        &.self_selected {
            border-color: #22c55e !important;
            color: #22c55e !important;
        }

        &.not-current-element {
            border-color: #64748b !important;
            color: #64748b !important;
        }
    }
}

.heap-status {
    display: inline-flex;
    align-items: center;
    gap: 4px;
    font-size: 12px;
    font-weight: 500;
    color: #18181b;
    transition: all .1s ease-in-out;

    span {
        display: inline-block;
        width: 16px;
        height: 16px;
        border-radius: 5px;
        background-color: #22c55e;
    }

    &:last-child span {
        background-color: #64748b;
    }
}

.bg-opacity {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1500;
    background: rgba(15, 43, 86, 0.539);
}
</style>
