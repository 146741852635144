<template>
    <div>
        <a href="#" rel="nofollow" class="btn-call mobil-btn-call-n" @click="iconPhone" :class="{ noneIcon: noneIcon }"
            v-if="phoneStatus">
            <div class="btn-call__ico"
                :class="(phoneStatus === 'registered') ? 'btn-call__ico_color_white' : 'btn-call__ico_color_red'">
                <i class="el-icon-phone"></i>
            </div>
        </a>

        <div class="btn-call mobil-btn-call-b" @click="callMobil = true">
            <div class="btn-call__ico">
                <i class="el-icon-phone"></i>
            </div>
        </div>

        <!-- SIP NUMBER LIST ( DEPARTMENTS MODAL ) START -->
        <div class="sip__box" v-show="activeCatPhone">
            <i class="el-icon-circle-close closeIconPhone-close" @click="closeDepartmentList">
            </i>
            <div class="sip__header">
                <span>{{ $t('message.department_list') }}</span>
            </div>
            <el-row :gutter="5">
                <el-col :span="4">
                    <div class="sip__item">
                        <div class="sip__cate">
                            <span>S.Raximov</span>
                        </div>
                        <ul class="sip__ul">
                            <li v-for="sip_account in sipAccountList.filter(item => (parseInt(item.username) < 200))"
                                :key="sip_account.username" class="sip__li" @click="callToSipAccount(sip_account)">
                                <div class="sip__into">
                                    <div class="sip__status">
                                        <span class="sip__indicator"
                                            :style="'background-color:' + sip_account.color"></span>
                                        <span class="sip__infoindicator"></span>
                                    </div>
                                    <div class="sip__img">
                                        <img src="https://firebasestorage.googleapis.com/v0/b/vue-store-1.appspot.com/o/user.png?alt=media&token=04ba7c78-0b75-431d-aa5b-3456a8ada2a2"
                                            alt="">
                                    </div>
                                    <div class="sip__name">
                                        <span>{{ sip_account.displayname }}</span>
                                    </div>
                                </div>
                                <div class="sip__phone">
                                    <a>{{ sip_account.username }}</a>
                                </div>
                            </li>
                        </ul>
                    </div>
                </el-col>
                <el-col :span="4">
                    <div class="sip__item">
                        <div class="sip__cate">
                            <span>Sergili Tikuv</span>
                        </div>
                        <ul class="sip__ul">
                            <li v-for="sip_account in sipAccountList.filter(item => (200 <= parseInt(item.username) && parseInt(item.username) < 300))"
                                :key="sip_account.username" class="sip__li" @click="callToSipAccount(sip_account)">
                                <div class="sip__into">
                                    <div class="sip__status">
                                        <span class="sip__indicator"
                                            :style="'background-color:' + sip_account.color"></span>
                                        <span class="sip__infoindicator"></span>
                                    </div>
                                    <div class="sip__img">
                                        <img src="https://firebasestorage.googleapis.com/v0/b/vue-store-1.appspot.com/o/user.png?alt=media&token=04ba7c78-0b75-431d-aa5b-3456a8ada2a2"
                                            alt="">
                                    </div>
                                    <div class="sip__name">
                                        <span>{{ sip_account.displayname }}</span>
                                    </div>
                                </div>
                                <div class="sip__phone">
                                    <a>{{ sip_account.username }}</a>
                                </div>
                            </li>
                        </ul>
                    </div>
                </el-col>
                <el-col :span="4">
                    <div class="sip__item">
                        <div class="sip__cate">
                            <span>Sergili To'quv</span>
                        </div>
                        <ul class="sip__ul">
                            <li v-for="sip_account in sipAccountList.filter(item => (300 <= parseInt(item.username) && parseInt(item.username) < 400))"
                                :key="sip_account.username" class="sip__li" @click="callToSipAccount(sip_account)">
                                <div class="sip__into">
                                    <div class="sip__status">
                                        <span class="sip__indicator"
                                            :style="'background-color:' + sip_account.color"></span>
                                        <span class="sip__infoindicator"></span>
                                    </div>
                                    <div class="sip__img">
                                        <img src="https://firebasestorage.googleapis.com/v0/b/vue-store-1.appspot.com/o/user.png?alt=media&token=04ba7c78-0b75-431d-aa5b-3456a8ada2a2"
                                            alt="">
                                    </div>
                                    <div class="sip__name">
                                        <span>{{ sip_account.displayname }}</span>
                                    </div>
                                </div>
                                <div class="sip__phone">
                                    <a>{{ sip_account.username }}</a>
                                </div>
                            </li>
                        </ul>
                    </div>
                </el-col>
                <el-col :span="4">
                    <div class="sip__item">
                        <div class="sip__cate">
                            <span>Namangan Iplik</span>
                        </div>
                        <ul class="sip__ul">
                            <li v-for="sip_account in sipAccountList.filter(item => (400 <= parseInt(item.username) && parseInt(item.username) < 500))"
                                :key="sip_account.username" class="sip__li" @click="callToSipAccount(sip_account)">
                                <div class="sip__into">
                                    <div class="sip__status">
                                        <span class="sip__indicator"
                                            :style="'background-color:' + sip_account.color"></span>
                                        <span class="sip__infoindicator"></span>
                                    </div>
                                    <div class="sip__img">
                                        <img src="https://firebasestorage.googleapis.com/v0/b/vue-store-1.appspot.com/o/user.png?alt=media&token=04ba7c78-0b75-431d-aa5b-3456a8ada2a2"
                                            alt="">
                                    </div>
                                    <div class="sip__name">
                                        <span>{{ sip_account.displayname }}</span>
                                    </div>
                                </div>
                                <div class="sip__phone">
                                    <a>{{ sip_account.username }}</a>
                                </div>
                            </li>
                        </ul>
                    </div>
                </el-col>
                <el-col :span="4">
                    <div class="sip__item">
                        <div class="sip__cate">
                            <span>{{ $t('message.department') }} X</span>
                        </div>
                        <ul class="sip__ul">
                            <li v-for="sip_account in sipAccountList.filter(item => (500 <= parseInt(item.username)))"
                                :key="sip_account.username" class="sip__li" @click="callToSipAccount(sip_account)">
                                <div class="sip__into">
                                    <div class="sip__status">
                                        <span class="sip__indicator"
                                            :style="'background-color:' + sip_account.color"></span>
                                        <span class="sip__infoindicator"></span>
                                    </div>
                                    <div class="sip__img">
                                        <img src="https://firebasestorage.googleapis.com/v0/b/vue-store-1.appspot.com/o/user.png?alt=media&token=04ba7c78-0b75-431d-aa5b-3456a8ada2a2"
                                            alt="">
                                    </div>
                                    <div class="sip__name">
                                        <span>{{ sip_account.displayname }}</span>
                                    </div>
                                </div>
                                <div class="sip__phone">
                                    <a>{{ sip_account.username }}</a>
                                </div>
                            </li>
                        </ul>
                    </div>
                </el-col>
            </el-row>
        </div>
         <!-- SIP NUMBER LIST ( DEPARTMENTS MODAL ) END -->

        <div class="call-big no-modal2" :class="{ active2: activeIconPhone }">
            <i v-show="callSessionsList.length < 1" @click="closeIconPhone" class="el-icon-circle-close closeIconPhone-close" ></i>
            <i v-show="callSessionsList.length >= 1" @click="closeIconPhone" class="el-icon-back closeIconPhone-close"></i>

            <!-- DIAL MODAL START-->
            <div class="call-number-kontak">

                <!-- DIAL PAD START -->
                <div class="call-number">
                    <div class="dial-pad">
                        <audio id="remoteAudio" ref="remoteAudioRef"></audio>
                        <audio id="incoming-call-beep" loop>
                            <source src="/audio/telephone-ring-04.mp3" type="audio/mpeg" />
                        </audio>
                        <audio id="sound-beep" loop>
                            <source src="/audio/beep.mp3" type="audio/mpeg" />
                        </audio>
                        <div class="statuss" v-show="(addingCall || isTransferCall) && elapsedTime > 0">
                            {{ elapsedTime > 0 ? formattedElapsedTime : ($t('message.calling') + "...") }}
                        </div>
                        <div class="statuss" v-show="(!addingCall && !isTransferCall)">
                            <div v-click-outside="onClickOutside" @click="miniStatus = !miniStatus" class="status__header">
                                <span :class="radio === 'registered' ? 'active__status' : 'notactive__status'"></span>
                                <span>{{ $t(`message.${radio}`) }}</span>
                            </div>
                            <transition name="status-fade">
                                <div v-show="miniStatus" class="status__box">
                                    <div class="status__title">
                                        <span>{{ $t('message.select_status') }}</span>
                                    </div>
                                    <ul class="status__ul">
                                        <li @click.prevent="setActive('registered')" class="call__avialable status__item">
                                            <span class="available__icon">
                                                <span class="el-icon-check"></span>
                                            </span>
                                            <span class="status__text">{{ $t('message.register') }}</span>
                                            <span v-show="isActive('registered')" class="status__absolute el-icon-check"></span>
                                        </li>
                                        <li @click.prevent="setActive('unregistered')" class="not__disturb status__item">
                                            <span class="disturb__icon">
                                                <span class="el-icon-minus"></span>
                                            </span>
                                            <span class="status__text">{{ $t('message.diconnect') }}</span>
                                            <span v-show="isActive('unregistered')" class="status__absolute el-icon-check"></span>
                                        </li>
                                    </ul>
                                </div>
                            </transition>
                        </div>
                        <div class="dial-screen" contenteditable="false">
                            {{ phonet }}
                        </div>
                        <div class="call__numbers">
                            <div class="call__line">
                                <div @click="append('1')" class="call__item">
                                    <span>1</span>
                                </div>
                                <div @click="append('2')" class="call__item">
                                    <span>2</span>
                                </div>
                                <div @click="append('3')" class="call__item">
                                    <span>3</span>
                                </div>
                            </div>
                            <div class="call__line">
                                <div @click="append('4')" class="call__item">
                                    <span>4</span>
                                </div>
                                <div @click="append('5')" class="call__item">
                                    <span>5</span>
                                </div>
                                <div @click="append('6')" class="call__item">
                                    <span>6</span>
                                </div>
                            </div>
                            <div class="call__line">
                                <div @click="append('7')" class="call__item">
                                    <span>7</span>
                                </div>
                                <div @click="append('8')" class="call__item">
                                    <span>8</span>
                                </div>
                                <div @click="append('9')" class="call__item">
                                    <span>9</span>
                                </div>
                            </div>
                            <div class="call__line">
                                <div @click="append('*')" class="call__item">
                                    <span>*</span>
                                </div>
                                <div @click="append('0')" class="call__item">
                                    <span>0</span>
                                </div>
                                <div @click="append('#')" class="call__item">
                                    <span>#</span>
                                </div>
                            </div>
                            <div class="call__line">
                                <div @click="append('+')" class="call__empty">
                                    <span>+</span>
                                </div>
                                <div @click="callingClick" class="call__call">
                                    <span class="el-icon-phone"></span>
                                </div>
                                <div @click="clearCallNumber" class="call__clear">
                                    <span>
                                        <img src="https://firebasestorage.googleapis.com/v0/b/vue-store-1.appspot.com/o/clear.png?alt=media&token=47fc68c8-a7b3-4318-a1db-231de3f18454"
                                            alt="">
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- DIAL PAD END-->

                <div class="call-kontak">
                    <!-- SELECT TRUNCK TO CALL START -->
                    <div>
                        <button class="sip__otdel" @click="openDepartmentList()">{{ $t('message.department_list')}}</button>
                        <el-select v-show="callSessionsList.length < 1" size="mini" class="ml-3"
                            v-model="selectedPhoneNumberForOutboundCall" placeholder="Select">
                            <el-option
                                v-for="phone_for_outbound_call in (authUser.sip_account ? authUser.sip_account.phones : [])"
                                :key="phone_for_outbound_call.id" :label="phone_for_outbound_call.phone_number"
                                :value="phone_for_outbound_call.prefix">
                            </el-option>
                        </el-select>
                    </div>
                    <!-- SELECT TRUNCK TO CALL END -->

                    <!-- ALL PHONE NUMBER TABS -->
                    <el-tabs v-model="activeTabName" @tab-click="handleTabClick">
                        
                        <!-- CALLHISTORY TAB -->
                        <el-tab-pane :label="$t('message.call_history')" name="callHistory">
                            <el-input :placeholder="$t('message.search')" prefix-icon="el-icon-search"
                                v-model="searchCallHistory" class="input-call" @blur="searchInputBlur"
                                @focus="searchInputFocus">
                            </el-input>
                            <div v-if="authUser.sip_account" style="overflow-y: scroll; height: 320px" v-loading="loadingCallHistoryData">
                                <div class="call-users" v-for="(callH, index) in callHistoryList" :key="'callH_' + index">
                                    <div class="call-users-info" @click="getPhoneNumber(callH)" @dblclick="callingClick">
                                        <img src="https://firebasestorage.googleapis.com/v0/b/vue-store-1.appspot.com/o/user.png?alt=media&token=04ba7c78-0b75-431d-aa5b-3456a8ada2a2"
                                            alt="" />
                                        <div class="call-users-info-text2">
                                            <div class="info-c-u-off">
                                                <span class="name-limit">
                                                    {{ filterPhone(callH, true) }}
                                                    <br>
                                                    <i :class="[showCallDirection(callH), showCallStatus(callH)]"></i> {{ filterPhone(callH, false) }}
                                                </span>
                                            </div>
                                            <div class="info-c-u-off">
                                                <span class="sek-off">
                                                    {{ callH.billsec }}
                                                </span>
                                                <span class="sek-off">
                                                    {{ callH.calldate }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </el-tab-pane>

                        <!-- CLIENTS TAB -->
                        <el-tab-pane :label="$t('message.clients')" name="clients">
                            <el-input :placeholder="$t('message.search')" prefix-icon="el-icon-search"
                                v-model="searchClientInput" class="input-call" @blur="searchInputBlur"
                                @focus="searchInputFocus">
                            </el-input>
                            <div style="overflow-y: scroll; height: 320px">

                                <div class="call-users" v-for="contact in clientInventory" :key="contact.id"
                                    @click="getPhoneNumber(contact)" @dblclick="callingClick">
                                    <div class="call-users-info">
                                        <img src="https://firebasestorage.googleapis.com/v0/b/vue-store-1.appspot.com/o/user.png?alt=media&token=04ba7c78-0b75-431d-aa5b-3456a8ada2a2"
                                            alt="" />
                                        <div class="call-users-info-text w-100">
                                            <div class="mm_select_additional_container">
                                                <span class="name"> {{ contact.full_name }} </span>
                                                <span class="mm_select_additional_info"> {{ contact.company ? contact.company.name : '' }} </span>
                                            </div>
                                            <span class="sek-off font_10 fbig"> </span>
                                            <span class="tel"> {{ contact.phone }} </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </el-tab-pane>

                        <!-- PHONECONTACTS TAB -->
                        <el-tab-pane :label="$t('message.phoneContacts')" name="contacts">
                            <el-input :placeholder="$t('message.search')" prefix-icon="el-icon-search"
                                v-model="contactSearchInput" class="input-call" @input="findContactInputSearch"
                                @blur="searchInputBlur" @focus="searchInputFocus">
                            </el-input>
                            <div style="overflow-y: scroll; height: 320px">
                                <div class="call-users" v-for="contact in phoneContacts" :key="contact.id"
                                    @click="getPhoneNumber(contact)" @dblclick="callingClick">
                                    <div class="call-users-info">
                                        <img src="https://firebasestorage.googleapis.com/v0/b/vue-store-1.appspot.com/o/user.png?alt=media&token=04ba7c78-0b75-431d-aa5b-3456a8ada2a2"
                                            alt="" />
                                        <div class="call-users-info-text">
                                            <span class="name">
                                                {{
                                                    contact.name_title
                                                    ? contact.name_title.name
                                                    : ""
                                                }}
                                                {{ contact.name }}
                                                {{ contact.surname }}
                                            </span>
                                            <span class="sek-off font_10 fbig">
                                                {{
                                                    contact.company
                                                    ? contact.company.name.toUpperCase()
                                                    : ""
                                                }}
                                            </span>
                                            <span class="tel">
                                                {{ contact.phone_number }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </el-tab-pane>
                    </el-tabs>
                    <!-- ALL PHONE NUMBER TABS -->
                </div>
            </div>
            <!-- DIAL MODAL END-->
        </div>

        <div class="call-big no-modal-kontak" :class="{ activeKontak: activeKontakModal }" style="background: #fff; border: 1px solid #d7d5d5">
            <i class="el-icon-circle-close closeIconPhone-close" @click="closeIconKontak"></i>
            <callKantentCom ref="callKantentCom" @closeContactModal="closeIconKontak"></callKantentCom>
        </div>

        <div class="call-big no-modal-task" :class="{ activetask: activeTaskModal }">
            <i class="el-icon-circle-close closeIconPhone-close" @click="closeIconKontakTask"></i>
            <callTaskCom ref="callTaskCom" @addModalEdit="addModalEdit" @createDealFromContact="createDealFromContact"> </callTaskCom>
        </div>

        <div class="calling-style" :class="{ active3: callingModal, active4: miniPage }">
            <!-- CONFERENCE LIST  START-->
            <div class="main__call" v-show="callSessionsList.length > 1">
                <div>
                    <ul class="meeting__ul"
                        :class="[{ 'grid-3': callSessionsList.length > 2 }, { 'grid-2': callSessionsList.length == 2 }, { 'grid-1': callSessionsList.length == 1 }]">
                        <li v-for="(call_session, index) in callSessionsList" :key="'call_' + index" class="meeting__li">
                            <span class="meeting__title">
                                {{ (call_session.session.direction === 'incoming' ? $t('message.incoming') : $t('message.outgoing')) + ' ' + $t('message.call') }}
                            </span>
                            <img class="meeting__image" src="/img/default-user-icon.png" alt="" />
                            <h5>{{ call_session.display_name }}</h5>
                            <h5>{{ call_session.username }}</h5>
                            <div class="meeting__buttons">
                                <button 
                                    class="mute__mic" 
                                    @click="puttCallOnHold(call_session.session)"
                                    v-show="call_session.session.status === call_session.session.C.STATUS_CONFIRMED"
                                >
                                    <i class="fa-solid fa-pause"></i>
                                </button>
                                <button 
                                    class="mute__mic" 
                                    @click="answerCall(call_session.session, index)"
                                    v-show="call_session.session.status === call_session.session.C.STATUS_WAITING_FOR_ANSWER"
                                >
                                    <i class="fa-solid fa-phone" ></i>
                                </button>
                                <button class="decline__icon" @click="hangUpCall(call_session.session, index)">
                                    <i class="fa-duotone fa-phone-hangup"></i> 
                                </button>
                            </div>
                        </li>
                    </ul>
                </div>

                <div class="calling-icons align__center">
                    <i v-if="elapsedTime" class="el-icon-plus unique__button opacityshow" @click="addPhone('adding')"></i>
                    <i v-if="elapsedTime && !addingCall" class="el-icon-sort unique__button opacityshow" @click="addPhone('transfering')"></i>
                    <i @click="microphoneCalling" :class="{ offMicrophone: addMic }" class="el-icon-microphone"> </i>
                    <i class="el-icon-user" v-if="!miniPage" @click="ikontalModal"></i>
                    <i class="el-icon-suitcase" v-if="!miniPage" @click="ikontalModalTask"></i>
                    <i class="el-icon-copy-document" @click="miniWidow"></i>
                </div>
            </div>
            <!-- CONFERENCE LIST END-->

            <!-- SINGLE CALL START-->
            <div v-show="callSessionsList.length === 1" class="phone__center">
                <div v-if="callSessionsList.length === 1">
                    <div class="mm_bordered_container mb-2">
                        <el-row :gutter="20">
                            <el-col :span="12">
                                <div class="clling-img">
                                    <img src="/img/default-user-icon.png" alt="" />
                                </div>
                            </el-col>
                            <el-col :span="12">
                                <div v-if="callSessionsList[0].company && callSessionsList[0].company.name" class="calling-nam-user font18">
                                    <i class="el-icon-office-building"></i>
                                    {{ callSessionsList[0].company.name.toUpperCase() }}
                                </div>
                                <div v-if="callSessionsList[0].display_name" class="calling-nam-user">
                                    {{ callSessionsList[0].display_name }}
                                </div>
                                <div v-if="callSessionsList[0].username" class="calling-nam-user">
                                    {{ callSessionsList[0].username }}
                                </div>
                                <div class="calling-text waviy">
                                    {{ 
                                        (callSessionsList[0].session.direction === 'outgoing' && callSessionsList[0].session.status === callSessionsList[0].session.C.STATUS_1XX_RECEIVED) ? 
                                        ($t('message.calling') + '...') : '' 
                                    }}
                                    {{ 
                                        (callSessionsList[0].session.direction === 'incoming' && callSessionsList[0].session.status === callSessionsList[0].session.C.STATUS_WAITING_FOR_ANSWER) ? 
                                        $t('message.incoming_call') : '' 
                                    }}
                                </div>
                                <div v-if="elapsedTime > 0" class="elapsedTime calling-pharom-user">
                                    {{ elapsedTime > 0 ? formattedElapsedTime : ($t('message.calling') + "...") }}
                                </div>
                            </el-col>
                        </el-row>    
                    </div>
                
                    <!--  -->
                    <div  class="mm_bordered_container p-5 w-100 mm_top_white_border">
                        <h4> {{ $t('message.last_call') }} </h4>
                        <p v-if="searchingCallerHistory" class="lh-base font-medium m-0">
                            {{ $t('message.searching_caller_history') }}
                        </p>

                        <p v-if="callerHistory && callerHistory.status" class="lh-base font-medium m-0" >
                            <el-row :gutter="20" class="mb-5">
                                <el-col :span="12" class="mb-2">
                                    <span class="font-bold">{{ $t('message.status') }}: </span> {{ callerHistory.status }}
                                </el-col>
                                <el-col :span="12" class="mb-2">
                                    <span class="font-bold">{{ $t('message.conversation_status') }}: </span> {{ callerHistory.callStatus }}
                                </el-col>
                                <el-col :span="12" class="mb-2">
                                    <span class="font-bold">{{ $t('message.caller_name') }}: </span> 
                                    <span v-html="callerHistory.caller_name"></span>
                                </el-col>
                                <el-col :span="12" class="mb-2">
                                    <span class="font-bold">{{ $t('message.caller') }}: </span> 
                                    <span v-html="callerHistory.caller"></span>
                                </el-col>
                                <el-col :span="12" class="mb-2">
                                    <span class="font-bold">{{ $t('message.target_name') }}: </span> 
                                    <span v-html="callerHistory.target_name"></span>
                                </el-col>
                                <el-col :span="12" class="mb-2">
                                    <span class="font-bold">{{ $t('message.called_to') }}: </span> 
                                    <span v-html="callerHistory.called_to"></span>
                                </el-col>
                                <el-col :span="12" class="mb-2">
                                    <span class="font-bold">{{ $t('message.billsec') }}: </span> 
                                    <span v-html="callerHistory.billsec"></span>
                                </el-col>
                                <el-col :span="12" class="mb-2">
                                    <span class="font-bold">{{ $t('message.calldate') }}: </span> 
                                    <span v-html="callerHistory.calldate"></span>
                                </el-col>
                                <!-- <el-col :span="24" class="mb-2">
                                    <div v-if="callerHistory.recordingfile">
                                        <audio id="audioFromExternal" controls :src="listenToTheAudio(callerHistory)"></audio>
                                    </div>
                                </el-col> -->
                            </el-row>
                        </p>
                        
                        <p v-else-if="!searchingCallerHistory && !callerHistory" class="lh-base font-medium m-0">
                            {{ $t('message.new_number_detected') }}
                        </p>
                        
                    </div>
                </div>

                <div class="calling-icons">
                    <i v-if="callSessionsList.length > 0 && callSessionsList[0].session.status === callSessionsList[0].session.C.STATUS_CONFIRMED"
                        class="el-icon-plus unique__button opacityshow" @click="addPhone('adding')">
                    </i>
                    <i v-if="callSessionsList.length > 0 && callSessionsList[0].session.status === callSessionsList[0].session.C.STATUS_CONFIRMED"
                        class="el-icon-sort unique__button opacityshow" @click="addPhone('transfering')">
                    </i>
                    <i v-if="callSessionsList.length > 0 && callSessionsList[0].session.status === callSessionsList[0].session.C.STATUS_CONFIRMED"
                        @click="microphoneCalling(callSessionsList[0].session)" :class="{ offMicrophone: addMic }"
                        class="el-icon-microphone">
                    </i>
                    <i class="el-icon-user" v-if="!miniPage" @click="ikontalModal"> </i>
                    <i class="el-icon-suitcase" v-if="!miniPage" @click="ikontalModalTask"> </i>
                    <i class="el-icon-phone-outline" @click="phoneOutline(callSessionsList[0].session)" :class="{ noneBlock: noneBlock }"></i>
                    <i class="el-icon-phone" @click="closeCallingClick(callSessionsList[0].session)"></i>
                    <i class="el-icon-copy-document" @click="miniWidow"></i>
                </div>
            </div>
            <!-- SINGLE CALL END-->
        </div>

        <div class="app-modal app-modal__full">
            <el-dialog :visible.sync="taskModalDrawer" ref="drawerCreateTask"
                @opened="drawerOpened('drawerCreateTaskChild')" @closed="drawerClosed('drawerCreateTaskChild')"
                :fullscreen="true">
                <div @click="taskModalDrawer = false" class="
                        close-modal-stick
                        d-flex
                        align-center
                        p-fixed
                        text-white
                        p-2
                        font-bold
                        pointer
                        transition
                    ">
                    <div class="
                            close-modal-icon
                            text-center
                            rounded-circle
                            transition
                        ">
                        <i class="el-icon-close"></i>
                    </div>
                    <span class="close-modal-text text-uppercase ml-2"></span>
                </div>
                <TaskModal ref="drawerCreateTaskChild" drawer="drawerCreateTask" />
            </el-dialog>

            <el-dialog :visible.sync="drawerDealCreate" :fullscreen="true" ref="drawerDealCreate"
                @opened="drawerOpened('drawerDealCreateChild')" @closed="drawerClosed('drawerDealCreateChild')">
                <div @click="closeAddModel()" class="
                        close-modal-stick
                        d-flex
                        align-center
                        p-fixed
                        text-white
                        p-2
                        font-bold
                        pointer
                        transition
                    ">
                    <div class="
                            close-modal-icon
                            text-center
                            rounded-circle
                            transition
                        ">
                        <i class="el-icon-close"></i>
                    </div>
                    <span class="close-modal-text text-uppercase ml-2"></span>
                </div>
                <DealModal ref="drawerDealCreateChild" :board_id="board_id" :selectedDeal="selectedDeal"
                    :dealCreate="dealCreate" drawer="drawerDealCreate" />
            </el-dialog>

            <el-drawer :with-header="false" :visible.sync="drawerCreateContact" class="body_scroll"
                ref="drawerCreateContact" size="50%" @opened="drawerOpened('drawerCreateContactChild')"
                @closed="drawerClosed('drawerCreateContactChild')">
                <div>
                    <contact-create ref="drawerCreateContactChild" drawer="drawerCreateContact">
                    </contact-create>
                </div>
            </el-drawer>
        </div>
        <div v-show="renderOnce">
            <el-dialog class="el-dialo-gcallMobil" title="Телефон" :visible.sync="callMobil" width="80%" center>
                <callMobil
                    v-show="renderOnce"
                    :registeredPhone="$phoneUA"
                    :registeredPhoneStatus="phoneStatus"
                >
                </callMobil>
            </el-dialog>
        </div>
        

    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import _ from "lodash";
import form from "@/utils/mixins/form";
import drawer from "@/utils/mixins/drawer";
import call from "@/utils/mixins/views/call/call";
import call_register from "@/utils/mixins/views/call/call_register";
import dial from "@/utils/mixins/views/call/dial";
import call_modals from "@/utils/mixins/views/call/call_modals";
import call_conference from "@/utils/mixins/views/call/call_conference";
import callMobil from "@/components/call/callMobil.vue";

export default {
    mixins: [form, drawer, call, call_register, dial, call_modals, call_conference],
    props: {
        callRequest: Object
    },
    components: {
        callMobil
    },
    data() {
        return {
            callMobil: false,
            miniStatus: false,
            radio: "registered",
            calculation: "",
            contactSearchInput: '',
            searchClientInput: '',
            searchCallHistory: '',
            activeIconPhone: false,
            callingModal: false,
            addMic: false,
            noneBlock: false,
            miniPage: false,
            noneIcon: false,
            elapsedTime: 0,
            newcontact: false,
            timer: undefined,
            taskModalDrawer: false,
            reloadList: false,
            drawerDealCreate: false,
            dealCreate: true,
            drawerCreateContact: false,
            pickUpCallIcon: false,
            activeKontakModal: false,
            activeTaskModal: false,
            userAgentOptions: {},
            connection: null,
            phone: "",
            audioStart: true,
            selectedDeal: {},
            board_id: null,
            phoneStatus: '',
            format: '',
            regax: '^',
            template: 'XX XXX XX XX',
            phonet: '',
            sipAccountList: [],
            selectedPhoneNumberForOutboundCall: '',
            isTransferCall: false,
            addingCall: false,
            callDirection: '',
            callSessionsList: [],
            callRequestUpdate: [],
            callHistorySave: false,
            windowWidth: 0,
            renderOnce: false
        };
    },
    mounted() {
        this.addKeyPressListener();
        
        this.updateWindowWidth();
        
        if (this.audioStart) {
            document.addEventListener("mouseup", e => {
                if (this.audioStart) {
                    let playPromise = document.getElementById("incoming-call-beep").play();
                    if (playPromise !== undefined) {
                        playPromise.then(_ => {
                            document.getElementById("incoming-call-beep").pause();
                        })
                            .catch(error => {
                            });
                    }
                    this.audioStart = false;
                }
            })
        }
        let x = 1;
        this.format = this.template.replace(/X+/g, () => '$' + x++);
        this.template.match(/X+/g).forEach((char, key) => {
            this.regax += '(\\d{' + char.length + '})?'
        })
    },
    watch: {
        callRequest: {
            handler(newValue, oldValue) {
                this.callRequestUpdate = JSON.parse(JSON.stringify(newValue));
                if (newValue.phone) {
                    this.calculation = this.phoneFilterForCall(newValue.phone);
                    if (newValue.call && newValue.phone_country_code === "UZ") {
                        this.callHistorySave = true;
                        this.callingClick();
                    }
                }

            },
            immediate: true,
            deep: true
        },
        calculation(next, prev) {
            this.phonet = this.calculation
            if (next.length > prev.length) {
                this.phonet = this.phonet.replace(/[^0-9]/g, '')
                    .replace(new RegExp(this.regax, 'g'), this.format)
                    .substr(0, this.template.length)
            }
        }
    },
    computed: {
        ...mapGetters({
            numberToCall: "call/numberToCall",
            phoneContacts: "phoneContacts/inventory",
            clientInventory: "clients/inventory",
            authUser: "auth/user",
            serverSipAccountList: "sipAccounts/serverSipAccountList",
            callHistoryList: "callHistories/phoneCallList",
            ariPhones: "phoneNumberForSipAccountOutsideCall/ariPhones",
            dealModel: "deals/model",
            callerHistory: "callHistories/callerHistory",
        }),
        formattedElapsedTime() {
            const date = new Date(null);
            date.setSeconds(this.elapsedTime / 1000);
            const utc = date.toUTCString();
            return utc.substr(utc.indexOf(":") - 2, 8);
        },
        exhibition_id: function () {
            return this.$route.params.exhibition_id
        },
    },
    async created() {
        this.debouncedRegisterUserAgentJSSIP(this);
    },

    methods: {
        ...mapActions({
            sipAccountsFromServer: "sipAccounts/sipAccountsFromServer",
            ariConnectionWithServer: "phoneNumberForSipAccountOutsideCall/ariConnectionWithServer",
        }),
        updateWindowWidth() {
            this.windowWidth = window.innerWidth;
            if(window.innerWidth < 1100 && !this.renderOnce){
                this.callMobil = true;
                setTimeout(() => {
                    this.callMobil = false;
                }, 300);
                setTimeout(() => {
                    this.renderOnce = true;
                }, 700);
            }else {
                this.renderOnce = true;
            }
        },

        addPhone(event) {
            if (event == 'adding') {
                this.addingCall = true
                this.activeIconPhone = true
                this.callingModal = false
            }
            if (event == 'transfering') {
                this.isTransferCall = true
                this.activeIconPhone = true
                this.callingModal = false
            }
        },

        onClickOutside() {
            this.miniStatus = false
        },

        start() {
            this.timer = setInterval(() => {
                this.elapsedTime += 1000;
            }, 1000);
        },

        stop() {
            clearInterval(this.timer);
            setTimeout(() => {
                this.elapsedTime = 0;
            }, 300);

        },

        append(value) {
            this.calculation += value.toString();
        },

        iconPhone(e) {
            this.activeIconPhone = true;
            this.noneIcon = true;
        },

        closeIconPhone(e) {
            if (this.callSessionsList.length < 1) {
                this.activeIconPhone = false;
                this.noneIcon = false;
            } else {
                this.isTransferCall = false;
                this.callingModal = true;
                this.activeIconPhone = false;
            }
        },

        miniWidow(e) {
            this.activeKontakModal = false;
            this.activeTaskModal = false;
            this.miniPage = !this.miniPage;
        },
    },
};
</script>

<style lang="scss">
/* ikkinchi modal */
.btn-call.noneIcon {
    display: none;
}

.calling-style {
    width: 60%;
    height: 515px;
    opacity: 0;
    background: #2c3e50;
    margin: auto;
    padding: 20px;
    position: fixed;
    z-index: 0;
    top: 20vh;
    right: 0;
    left: 0;
    display: flex;
    border-radius: 10px;
    transform: translate(0%, 0%) scale(0);
    transition: all 0.5s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

.calling-style.active3 {
    transform: translate(0%, 0%) scale(1);
    transition: all 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.075);
}

.calling-style.active4 {
    transform: translate(0%, 0%) scale(1);
    transition: all 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.calling-style.active3 {
    opacity: 1;
    z-index: 9994;
}

.calling-text {
    margin-bottom: 30px !important;
    /* -webkit-box-reflect: below -5px linear-gradient(transparent, rgba(0, 0, 0, 0.2)); */
    animation: waviy 1s infinite;
    animation-delay: calc(0.1s * var(--i));
}

@keyframes waviy {

    0%,
    40%,
    100% {
        transform: translateY(3px);
    }

    20% {
        transform: translateY(-3px);
    }
}

.calling-nam-user,
.calling-text {
    color: #fff;
    font-size: 20px;
    margin-bottom: 10px;
    font-weight: 600;
}

.calling-pharom-user {
    font-size: 18px;
    color: #fff;
    margin-bottom: 15px;
}

.clling-img img {
    width: 100px;
    // height: 100px;
    overflow: hidden;
    border: 2px solid #fff;
    border-radius: 50%;
    box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.24),
        0 10px 50px 0 rgba(0, 0, 0, 0.19);
}

.calling-icons {
    background: #2c3e50;
    border-radius: 20px;
    border: 1px #efefef2a solid;
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
}

.calling-icons i {
    font-size: 21px;
    padding: 10px;
    border-radius: 50%;
    color: #fff;
    margin: 5px;
    cursor: pointer;
}

.calling-icons .el-icon-phone {
    background: red;
    transform: rotate(135deg);
}

.calling-icons .el-icon-phone-outline {
    background: #00d669;
}

.calling-icons .el-icon-microphone {
    background-color: #e6a23c;
}

.calling-icons .el-icon-copy-document {
    background-color: #8b8f999c;
}

.calling-icons .el-icon-user {
    background: #fff;
    color: #e6a23c !important;
}

.calling-icons .el-icon-suitcase {
    background-color: #8b8f999c;
}

.el-icon-microphone.offMicrophone {
    background: #fff;
    color: #e6a23c !important;
}

.el-icon-microphone.offMicrophone:before {
    content: "\e728" !important;
}

.el-icon-phone-outline.noneBlock {
    display: none;
}

.calling-style.active4 {
    width: 200px;
    height: 200px;
    right: 20px !important;
    left: initial;
    bottom: 20px;
    top: inherit;
    justify-content: center;
}

.calling-style.active4 .clling-img {
    display: none;
}

.calling-style.active4 .calling-text {
    margin-bottom: 10px !important;
}

.calling-style.active4 .calling-nam-user,
.calling-style.active4 .calling-text {
    font-size: 12px;
    font-weight: 300;
}

.calling-style.active4 .calling-icons i {
    font-size: 18px;
    padding: 7px;
    border-radius: 50%;
    color: #fff;
    margin: 4px;
    cursor: pointer;
}

/* ikkinchi modal */

.closeIconPhone-close {
    position: absolute;
    top: -17px;
    right: -17px;
    font-size: 38px;
    background: #b55e7d;
    color: #fff;
    border-radius: 50%;
    cursor: pointer;
}

.no-modal-task,
.no-modal-kontak,
.no-modal2 {
    width: 850px;
    position: fixed;
    top: 20vh;
    /* bottom: 0; */
    right: 0;
    left: 0;
    background: whitesmoke;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
    margin: auto;
    padding: 20px;
    opacity: 0;
    transform: translate(0%, 0%) scale(0);
    transition: all 0.8s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

.no-modal-task {
    width: 600px;
}

.no-modal-kontak {
    width: 400px;
}

.no-modal-task.activetask,
.no-modal-kontak.activeKontak,
.no-modal2.active2 {
    opacity: 1;
    z-index: 9996;
    transform: translate(0%, 0%) scale(1);
    transition: all 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.call-number-kontak {
    display: flex;
}

.call-kontak {
    width: 100%;
}

.dial-pad {
    width: 210px;
}

.call-number {
    border-right: 1px solid #dddddd8c;
    padding-right: 20px;
    margin-right: 20px;
}

.dial-screen {
    height: 50px;
    padding: 0px;
    font-size: 26px;
    font-weight: 400;
    line-height: 50px;
    text-align: right;
    margin-bottom: 20px;
}

.dial-table {
    width: 100%;
    display: table;
}

.dial-table .dial-table-row {
    display: table-row;
}

.dial-table .dial-table-col {
    cursor: default;
    width: 33.333333%;
    text-align: center;
    display: table-cell;
    vertical-align: top;
}

.dial-table .dial-key-wrap {
    margin-bottom: 10px;
    transition: background 0.3s ease-in-out 0s;
}

.dial-table .dial-table-col .dial-key {
    font-size: 28px;
    font-weight: 400;
    min-height: 48px;
    line-height: 48px;
    cursor: pointer;
}

.dial-table .no-sub-key .dial-key {
    min-height: 48px;
    line-height: 48px;
}

.dial-table .dial-sub-key {
    color: #aaa;
    font-size: 14px;
    text-transform: uppercase;
}

.no-sub-key .dial-sub-key {
    display: none;
}

.dial-table .dial-key-wrap.active,
.dial-table .dial-key-wrap:hover {
    color: #eee;
    background: #16a085;
}

.dial-table .dial-key-wrap.active .dial-sub-key,
.dial-table .dial-key-wrap:hover .dial-sub-key {
    color: #eee;
}

.btn-call {
    background: #38a3fd;
    border: 2px solid #38a3fd;
    border-radius: 50%;
    box-shadow: 0 8px 10px rgba(56, 163, 253, 0.3);
    cursor: pointer;
    height: 55px;
    width: 55px;
    text-align: center;
    position: fixed;
    right: 40px;
    bottom: 30px;
    z-index: 999;
    transition: 0.3s;
    -webkit-animation: hoverWave linear 1s infinite;
    animation: hoverWave linear 1s infinite;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}

.btn-call__ico {
    display: flex;
    justify-content: center;
    align-items: center;
    animation: 1200ms ease 0s normal none 1 running shake;
    animation-iteration-count: infinite;
    -webkit-animation: 1200ms ease 0s normal none 1 running shake;
    -webkit-animation-iteration-count: infinite;
    /* color: white; */
    font-size: 30px;
    padding-top: 5px;
    transition: 0.3s all;
}

.btn-call__ico_color_white {
    color: white;
}

.btn-call__ico_color_red {
    color: red;
}

.btn-call:hover {
    background-color: #fff;
}

.btn-call:hover .btn-call__ico {
    color: #38a3fd;
}

@-webkit-keyframes hoverWave {
    0% {
        box-shadow: 0 8px 10px rgba(56, 163, 253, 0.3),
            0 0 0 0 rgba(56, 163, 253, 0.2), 0 0 0 0 rgba(56, 163, 253, 0.2);
    }

    40% {
        box-shadow: 0 8px 10px rgba(56, 163, 253, 0.3),
            0 0 0 15px rgba(56, 163, 253, 0.2), 0 0 0 0 rgba(56, 163, 253, 0.2);
    }

    80% {
        box-shadow: 0 8px 10px rgba(56, 163, 253, 0.3),
            0 0 0 30px rgba(56, 163, 253, 0),
            0 0 0 26.7px rgba(56, 163, 253, 0.067);
    }

    100% {
        box-shadow: 0 8px 10px rgba(56, 163, 253, 0.3),
            0 0 0 30px rgba(56, 163, 253, 0), 0 0 0 40px rgba(56, 163, 253, 0);
    }
}

@keyframes hoverWave {
    0% {
        box-shadow: 0 8px 10px rgba(56, 163, 253, 0.3),
            0 0 0 0 rgba(56, 163, 253, 0.2), 0 0 0 0 rgba(56, 163, 253, 0.2);
    }

    40% {
        box-shadow: 0 8px 10px rgba(56, 163, 253, 0.3),
            0 0 0 15px rgba(56, 163, 253, 0.2), 0 0 0 0 rgba(56, 163, 253, 0.2);
    }

    80% {
        box-shadow: 0 8px 10px rgba(56, 163, 253, 0.3),
            0 0 0 30px rgba(56, 163, 253, 0),
            0 0 0 26.7px rgba(56, 163, 253, 0.067);
    }

    100% {
        box-shadow: 0 8px 10px rgba(56, 163, 253, 0.3),
            0 0 0 30px rgba(56, 163, 253, 0), 0 0 0 40px rgba(56, 163, 253, 0);
    }
}

/* animations icon */

@keyframes shake {
    0% {
        transform: rotateZ(0deg);
        -ms-transform: rotateZ(0deg);
        -webkit-transform: rotateZ(0deg);
    }

    10% {
        transform: rotateZ(-30deg);
        -ms-transform: rotateZ(-30deg);
        -webkit-transform: rotateZ(-30deg);
    }

    20% {
        transform: rotateZ(15deg);
        -ms-transform: rotateZ(15deg);
        -webkit-transform: rotateZ(15deg);
    }

    30% {
        transform: rotateZ(-10deg);
        -ms-transform: rotateZ(-10deg);
        -webkit-transform: rotateZ(-10deg);
    }

    40% {
        transform: rotateZ(7.5deg);
        -ms-transform: rotateZ(7.5deg);
        -webkit-transform: rotateZ(7.5deg);
    }

    50% {
        transform: rotateZ(-6deg);
        -ms-transform: rotateZ(-6deg);
        -webkit-transform: rotateZ(-6deg);
    }

    60% {
        transform: rotateZ(5deg);
        -ms-transform: rotateZ(5deg);
        -webkit-transform: rotateZ(5deg);
    }

    70% {
        transform: rotateZ(-4.28571deg);
        -ms-transform: rotateZ(-4.28571deg);
        -webkit-transform: rotateZ(-4.28571deg);
    }

    80% {
        transform: rotateZ(3.75deg);
        -ms-transform: rotateZ(3.75deg);
        -webkit-transform: rotateZ(3.75deg);
    }

    90% {
        transform: rotateZ(-3.33333deg);
        -ms-transform: rotateZ(-3.33333deg);
        -webkit-transform: rotateZ(-3.33333deg);
    }

    100% {
        transform: rotateZ(0deg);
        -ms-transform: rotateZ(0deg);
        -webkit-transform: rotateZ(0deg);
    }
}

@-webkit-keyframes shake {
    0% {
        transform: rotateZ(0deg);
        -ms-transform: rotateZ(0deg);
        -webkit-transform: rotateZ(0deg);
    }

    10% {
        transform: rotateZ(-30deg);
        -ms-transform: rotateZ(-30deg);
        -webkit-transform: rotateZ(-30deg);
    }

    20% {
        transform: rotateZ(15deg);
        -ms-transform: rotateZ(15deg);
        -webkit-transform: rotateZ(15deg);
    }

    30% {
        transform: rotateZ(-10deg);
        -ms-transform: rotateZ(-10deg);
        -webkit-transform: rotateZ(-10deg);
    }

    40% {
        transform: rotateZ(7.5deg);
        -ms-transform: rotateZ(7.5deg);
        -webkit-transform: rotateZ(7.5deg);
    }

    50% {
        transform: rotateZ(-6deg);
        -ms-transform: rotateZ(-6deg);
        -webkit-transform: rotateZ(-6deg);
    }

    60% {
        transform: rotateZ(5deg);
        -ms-transform: rotateZ(5deg);
        -webkit-transform: rotateZ(5deg);
    }

    70% {
        transform: rotateZ(-4.28571deg);
        -ms-transform: rotateZ(-4.28571deg);
        -webkit-transform: rotateZ(-4.28571deg);
    }

    80% {
        transform: rotateZ(3.75deg);
        -ms-transform: rotateZ(3.75deg);
        -webkit-transform: rotateZ(3.75deg);
    }

    90% {
        transform: rotateZ(-3.33333deg);
        -ms-transform: rotateZ(-3.33333deg);
        -webkit-transform: rotateZ(-3.33333deg);
    }

    100% {
        transform: rotateZ(0deg);
        -ms-transform: rotateZ(0deg);
        -webkit-transform: rotateZ(0deg);
    }
}

/* .user-call--info */
.call-users-info {
    display: flex;
}

.call-users-info img {
    width: 40px;
    margin-right: 5px;
}

.call-users-info-text {
    display: flex;
    flex-direction: column;
}

.call-users-info-text .tel {
    font-size: 14px;
    color: #409eff;
}

.call-users-info-text .name {
    font-size: 12px;
    margin-bottom: 5px;
    color: #000;
}

.call-users {
    padding: 5px 4px;
    border-radius: 5px;
    border-top: 1px;
    margin-bottom: 5px;
    cursor: pointer;
}

.call-users:hover {
    background-color: rgb(219, 219, 219);
}

.input-call .el-input__inner {
    background: #fff0;
    margin-bottom: 5px;
    border-radius: 50px;
    height: 30px;
    line-height: 29px;
}

.input-call .el-input__icon {
    line-height: 25px;
}

.calling-style.active4 .font18 {
    font-size: 12px;
}

.font18 {
    font-size: 18px;
}

.calling-style.active4 .calling-pharom-user {
    margin-bottom: 9px;
}

/*  */

.fixed__column {
    position: fixed;
    z-index: 999;
    height: 55px;
    width: auto;
    right: 43px;
    bottom: 95px;
}

.carte {
    position: relative;
}

.carte__icon {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    background: #459ef7;
    border-radius: 50%;
    border: 1px solid #459ef7;
    margin-left: auto;
}

.carte__icon i {
    font-size: 30px;
    color: #fff;
}

.carte__li {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 5px 8px;
    cursor: pointer;
}

.carte__li span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    margin-left: 100px;
    background: #459ef7;
    border-radius: 50%;
    border: 1px solid #459ef7;
    color: #fff;
    z-index: 100;
}

.carte__li a {
    background: #459ef7;
    border: 1px solid #459ef7;
    width: 130px;
    height: 30px;
    text-align: center;
    color: #fff;
    border-radius: 0.475rem;
    line-height: 26px;
    margin-right: -95px;
    transition: all 0.2s ease-in-out;
    z-index: 90;
}

.carte__li:active span {
    border: 2px solid #459ef7;
    background: #5eacf9;
}

.carte__li:active a {
    border: 2px solid #459ef7;
    background: #5eacf9;
}

.carte__ul {
    margin-top: -180px;
    padding-bottom: 30px;
    margin-right: 0px;
}

.no-modal-task {
    background: rgb(255, 255, 255);
    top: 10vh;
    max-height: 89vh;
    height: 89vh;
    border: 1px solid #d7d5d5;
}

.no-modal-task .modal-tasks-itle {
    overflow: hidden;
    overflow-y: scroll;
    max-height: 78vh;
}


.call__numbers {
    display: flex;
    flex-direction: column;
}

.call__line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 5px 0;
}

.call__empty {
    display: flex;
    width: 60px;
    height: 60px;
    background: #e5e5e5;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.call__empty span {
    font-size: 22px;
    font-weight: 600;
}

.call__item {
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    background: #e5e5e5;
    cursor: pointer;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.call__item:active {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.call__item span {
    font-size: 22px;
    font-weight: 600;
}

.call__clear {
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.status__header:active {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.call__empty:active {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.call__call:active {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.call__clear span img {
    width: 30px;
    height: auto;
    cursor: pointer;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.call__call {
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    cursor: pointer;
    background: #65c466;
}

.call__call span {
    font-size: 28px;
    color: #fff;
}

.call-big {
    border-radius: 10px;
}

.status__box {
    position: absolute;
    margin-top: 30px;
    background: #fff;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    border-radius: 10px;
}

.statuss {
    display: flex;
    justify-content: center;
}

.status__item {
    padding: 5px 10px;
    border-top: 1px solid #e5e5e5;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.status__title {
    text-align: center;
    padding: 5px 0;
}

.status__title span {
    font-size: 12px;
}

.available__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #65c466;
    font-size: 9px;
    text-align: center;
    display: flex;
    color: #fff;
    margin-right: 5px;
}

.disturb__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #fd3b32;
    font-size: 9px;
    text-align: center;
    display: flex;
    color: #fff;
    margin-right: 5px;
}

.hidden__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #8b8f99;
    font-size: 9px;
    text-align: center;
    display: flex;
    color: #fff;
    margin-right: 5px;
}

.status__text {
    font-size: 13px;
    padding-right: 15px;
}

.status__absolute {
    position: absolute;
    right: 0;
    margin-right: 5px;
}

.status__header {
    background: #dcdcdc;
    padding: 5px 10px;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.d-modal-style .status__header {
    background-color: #0e2c46;
}

.status__header span {
    color: #fff;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
}

.active__status {
    width: 6px;
    height: 6px;
    background: #65c466;
    border-radius: 50%;
    margin-right: 5px;
}

.notactive__status {
    width: 6px;
    height: 6px;
    background: #fd3b32;
    border-radius: 50%;
    margin-right: 5px;
}

.status-fade-enter-active {
    transition: all 0.1s;
}

.status-fade-leave-active {
    transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}

.status-fade-enter-from,
.status-fade-leave-to {
    transform: translateY(-20px);
    opacity: 0;
}

.just-enter-active {
    transition: all .2s ease;
}

.just-leave-active {
    transition: all .2s ease;
}

.just-enter,
.just-leave-to

/* .slide-fade-leave-active below version 2.1.8 */
    {
    transform: translateY(30px);
    opacity: 0;
}

.unique__button {
    cursor: not-allowed !important;
    opacity: 0.5;
    background: #8b8f999c;
}

.opacityshow {
    opacity: 1;
    cursor: pointer !important;
}

.phone__center {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    justify-content: center;
    border: 1px solid #efefef54;
    border-radius: 10px;
}

.mm_bordered_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: #fff;
}

.mm_top_white_border {
    border-top: 1px solid #efefef54;
}

.meeting__li {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    height: 230px;
}

.mute__mic {
    transition: 0.4s;
    border: none;

    &:hover,
    &.active {
        background: #38a3fd;
    }
}

.decline__icon {
    transition: 0.4s;

    &:hover {
        background: lighten(#fd3b32, 8%);
    }
}

.decline__icon {
    border: none;
}

.meeting__image {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border: 2px solid #8b8f999c;
    padding: 1px;
    border-radius: 50%;
}

.meeting__buttons button {
    width: 30px;
    height: 30px;
    cursor: pointer;
    margin: 0 5px;
    margin-top: 10px;
    border-radius: 50%;
}

.decline__icon {
    background: #fd3b32;
    border-color: #fd3b32;
    color: #fff;
}

.mute__mic {
    background: #8b8f999c;
    border-color: #8b8f999c;
    color: #fff;
}

.meeting__li h5 {
    color: #fff;
    margin: 0;
    margin-top: 10px;
}

.meeting__li span {
    color: #fff;
    margin-bottom: 10px;
    font-size: 12px;
}

.meeting__ul {
    display: grid;
    justify-items: center;
    gap: 1rem;
    padding: 1rem;

    li {
        border: 1px solid #efefef54;
        border-radius: 10px;
    }

    .meeting__buttons button {
        width: 40px;
        height: 40px;
    }

    &.grid-3 {
        overflow: auto;
        height: 480px !important;
        grid-template-columns: repeat(3, 1fr);
        padding-bottom: 6rem;
    }

    &.grid-2 {
        grid-template-columns: repeat(2, 1fr);
        align-items: center;
    }

    &.grid-1 {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.align__center {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.main__call {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.d-modal-style .no-modal-task,
.d-modal-style .no-modal-kontak,
.d-modal-style .no-modal2 {
    background: #080c24;
}

.d-modal-style .dial-screen {
    color: #fff;
}
</style>
