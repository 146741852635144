import {i18n} from '@/utils/i18n';

export function notify(res) { 
  try { 
    if ([200,201].includes(res.status)) {
      return this.$notify({
        title: i18n.t('message.succesfully'),
        type: "success",
        offset: 130,
        message: res.data.result.message
      });
    }
    if (res.status == 422) {
      let message = '';
      let errors = res.data.validation_errors;
      for (const key in errors) {
        if (errors.hasOwnProperty(key)) {
          const element = errors[key];
          if (element[0]) {
            message += '<span style="color:red">*</span> ' + element[0] + '<br>'
          }
        }
      }
      return this.$notify({
        title: i18n.t('message.error_m'),
        type: "error",
        offset: 130,
        dangerouslyUseHTMLString: true,
        message: message
      });
    }    
    if(res.status == 400)
    {          
      return this.$notify({
        title: i18n.t('message.error_m'),
        type: "error",
        offset: 130,
        message: res.data.error.message 
      });
    }
    if([403,500].includes(res.status)){
      return this.$notify({
        title: i18n.t('message.error_m'),
        type: "error",
        offset: 130,
        message: res.data.message ? res.data.message : ((res.data.error && res.data.error.message) ? res.data.error.message : '')
      });
    }
    if(res.status == 0){
      return this.$notify({
        title: i18n.t('message.network_error'),
        type: "error",
        offset: 130,
        message: i18n.t('message.network_connection_error')
      });
    }
    return this.$notify({
      title: i18n.t('message.error_m'),
      type: "error",
      offset: 130,
      message: i18n.t('message.There is a problem on the server side')
    });
  } catch (error) {    
    alert(error)
  }
}

export function error__message(res) {  
  try { 
    if (!res.data.result.success) {
      return this.$notify({
        title: i18n.t('message.error_m'),
        type: "error",
        offset: 130,
        message: res.data.result.message
      });
    }
  } catch (error) {    
    alert(error)
  }
}

export function warning__message(res) {  
  try { 
    if (!res.data.result.success) {
      return this.$notify({
        title: i18n.t('message.warning'),
        type: "warning",
        offset: 130,
        message: res.data.result.message
      });
    }
  } catch (error) {    
    alert(error)
  }
}

export function info__message(res) {  
  try { 
    if (!res.data.result.success) {
      return this.$notify({
        title: i18n.t('message.information'),
        type: "info",
        offset: 130,
        message: res.data.result.message
      });
    }
  } catch (error) {    
    alert(error)
  }
}

export function loadingCursor(event) {
  $('body').css({
    cursor: event
  })

}

export function parseValidationErrorToStr(messages) {
  return messages.join(', ')
}

export function permission(permission) {
  return store.getters.role.permissions.some(perm => perm.slug == permission)
}
