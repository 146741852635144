<template>
    <div class="card-table" :class="mode ? 'calendar-day' : 'calendar-night'" v-loading="loadingData">
        <el-row :gutter="20">
            <el-col :xs="24" :sm="24" :md="24" :lg="6" :xl="5">
                <div class="left-filter-calendar  pt-2 pl-2">
                    <div class="caz-blocks-sarcho-title ">
                        <div :class="mode ? 'content__titleday' : 'content__titlenight'"
                            class="content-title d-flex align-center mb-2">
                            {{ $t('message.calendar') }}
                            <crm-refresh @c-click="refresh()"></crm-refresh>
                        </div>
                    </div>
                    <div class="filter-filyal">
                        <el-form ref="form" :model="filterForm">
                            <el-form-item :class="mode ? 'select__formday' : 'select__formnight'">
                                <select-filial :size="'medium'" :placeholder="$t('message.filial')"
                                    :id="filterForm.filial_id" v-model="filterForm.filial_id">
                                </select-filial>
                            </el-form-item>

                            <ul class="leftCheckbox">
                                <li>
                                    <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll"
                                        @change="handleCheckAllChange">
                                        {{ $t('message.all') }}
                                    </el-checkbox>
                                </li>
                                <li v-for="(modul, index) in items" :key="'modul-' + index">
                                    <el-checkbox-group v-model="checkedModules">
                                        <!--  :style="`background-color:${modul.color} !important;`" -->
                                        <el-checkbox :class="modul.className" :label="modul.value">
                                            {{ $t('message.' + modul.label) }}
                                        </el-checkbox>
                                    </el-checkbox-group>
                                    <el-button class="btn-color-calendar" @click="editModulColor(modul)"
                                        icon="el-icon-edit"></el-button>
                                </li>
                            </ul>
                        </el-form>
                    </div>
                </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="18" :xl="19">
                <!-- <div class="p-relativee">
                    <button class="hafta ">{{ week }}</button>
                </div> -->
                <div class="right-filter-calendar pt-2">
                    <FullCalendar ref="fullCalendar" :options="calendarOptions" />
                </div>
                    <div>
                        <button @click="changeView('dayGridMonth')">Month View</button>
                        <button @click="changeView('timeGridWeek')">Week View</button>
                        <button @click="changeView('timeGridDay')">Day View</button>
                    </div>
            </el-col>
        </el-row>

        <div class="app-modal app-modal__full">
            <!-- TaskModalUpdate -->
            <el-dialog 
                :visible.sync="taskUpdateDrawer" 
                ref="drawerTaskUpdate"
                @opened="drawerOpened('drawerTaskUpdateChild')" 
                @closed="drawerClosed('drawerTaskUpdateChild')"
                :append-to-body="true"
                :fullscreen="true"
            >
                <TaskModalUpdate 
                    :selectedItem="selectedTask" 
                    ref="drawerTaskUpdateChild" 
                    drawer="drawerTaskUpdate" 
                />
            </el-dialog>
            <!--End TaskModalUpdate -->

            <!-- DealModalUpdate -->
            <el-dialog 
                :visible.sync="dealUpdateDrawer" 
                :fullscreen="true"
                ref="drawerDealUpdate"
                @opened="drawerOpened('drawerDealUpdateChild')" 
                @closed="drawerClosed('drawerDealUpdateChild')"
                :append-to-body="true"
                :show-close="false"
            >
                <DealModalUpdate 
                    ref="drawerDealUpdateChild" 
                    :dealCreate="false" 
                    :selectedDeal="selectedDeal"
                    drawer="drawerDealUpdate" 
                    :is_calendar="true" 
                    :model_id="selectedDeal.deal_id" 
                />
            </el-dialog>
            <!--End DealModalUpdate -->
        </div>

        <!-- CreateNotification Dialog -->
        <el-dialog 
            class="el-dialog-w-100 caldialog-closeCalendarNotification" 
            :visible.sync="createNotificationDialog"
            :append-to-body="true" 
            width="50%" center 
            ref="dialogCreateNotification"
            @opened="drawerOpened('dialogCreateNotificationChild')" 
            @closed="drawerClosed('dialogCreateNotificationChild')"
        >
            <CreateNotification 
                ref="dialogCreateNotificationChild" 
                drawer="dialogCreateNotification"
                :clickedDate="clickedDate"
            >
            </CreateNotification>
        </el-dialog>
        <!-- @close-calendar-notification="closeCalendarNotification" @closeCalendarNotification="closeCalendarNotification"-->
        <!-- End CreateNotification Dialog -->

        <!-- UpdateNotification Dialog -->
        <el-dialog 
            class="el-dialog-w-100 caldialog-closeCalendarNotification" 
            title=""
            :visible.sync="updateNotificationDialog" 
            :append-to-body="true" 
            :show-close="false" 
            top="0vh" 
            width="55%" 
            center
            ref="dialogUpdateNotification" 
            @opened="drawerOpened('dialogUpdateNotificationChild')"
            @closed="drawerClosed('dialogUpdateNotificationChild')"
        >
            <UpdateNotification 
                ref="dialogUpdateNotificationChild" 
                drawer="dialogUpdateNotification"
                :selectedItem="selectedNotification" 
                :is_calendar="true"
            >
            </UpdateNotification>
        </el-dialog>
        <!--End UpdateNotification Dialog -->

        <!-- MORE MODAL DRAWER -->
        <el-drawer 
            :title="$t('message.calendarNotification')" 
            :visible.sync="drawerMoreInfo" size="55%"
            :append-to-body="true"
            @closed="drawerMoreInfoModalClosed"
        >
            <div>
                <MoreModal 
                    :selectedMoreModules="selectedMoreModules"
                >
                </MoreModal>
            </div>
        </el-drawer>
        <!-- END MORE MODAL DRAWER -->

        <!-- Deal Correspondence Dialog -->
        <el-dialog 
            class="el-dialog-w-100" 
            title="" 
            :visible.sync="updateDealCorrespondenceDialog" 
            :append-to-body="true"
            width="55%" 
            center 
            @closed="closedDealCorrespondenceDialog"
        >
            <div class="timeline-items__right rounded-sm">
                <div class="timeline-card-header p-4">
                    <p class="m-0 m-d-i">
                        <strong class="mr-2">
                            {{ correspondence.type_name }} <span>{{ correspondence.excutation_time }}</span>
                        </strong>
                        <strong class="mr-2" v-if="correspondence.deal">
                            {{ $t('message.deal') + ' ' + $t('message.n') }} {{ correspondence.deal.id }} <span>{{
                                correspondence.deal.name }}</span>
                        </strong>
                    </p>
                </div>

                <div v-if="correspondence.text" class="p-4 timeline-card-header">
                    <p class="lh-base font-medium m-0" v-html="urlify(correspondence.text)"></p>
                </div>

                <div class="timeline-card-header p-4" v-if="correspondence.notification">
                    <p class="m-0 m-d-i">
                        <strong class="mr-2">
                            {{ $t('message.notifications_nn') }} <span>{{ correspondence.notification.activity_time
                            }}</span>
                            <span class="ml-5">{{ $t('message.creator') }}: {{ correspondence.notification.user.name
                            }}</span>
                        </strong>
                    </p>
                </div>

                <div v-if="correspondence.notification && (correspondence.notification.items.length > 0)"
                    class="p-4 timeline-card-header">
                    <p class="lh-base font-medium m-0"> {{ $t('message.participants') }}:
                        <strong v-for="(item, index) in correspondence.notification.items" :key="index">
                            <span v-if="index != correspondence.notification.items.length - 1">{{ item.user.name }}, </span>
                            <span v-else>{{ item.user.name }}</span>
                        </strong>
                    </p>
                </div>
            </div>
        </el-dialog>
        <!--End Deal Correspondence Dialog -->

        <!-- Colors checkbox left block Modal -->
        <el-dialog 
            :class="mode ? 'l-modal-style' : 'd-modal-style'" 
            class="create-task-modal modal-style-board"
            title="Colors" 
            :append-to-body="true"
            :with-header="false" 
            :visible.sync="drawerBoardCreate" 
            ref="drawerBoardCreate"
            @opened="drawerOpened('drawerBoardCreateChild')" 
            @closed="drawerClosed('drawerBoardCreateChild')"
        >
            <crm-create-board 
                ref="drawerBoardCreateChild" 
                drawer="drawerBoardCreate" 
                :colorValue="colorValue"
            >
            </crm-create-board>
        </el-dialog>
        <!-- End  Colors checkbox left block Modal -->

        <!-- Work Meeting Info Dialog -->
        <el-dialog 
            class="el-dialog-w-100" 
            :title="$t('message.workMeeting')" 
            :visible.sync="dialogWorkMeetingInfoVisible"
            :append-to-body="true" 
            top="5vh" 
            width="95%" 
            center 
            ref="dialogWorkMeetingInfo"
            @opened="drawerOpened('dialogWorkMeetingInfoChild')" 
            @closed="drawerClosed('dialogWorkMeetingInfoChild')"
        >
            <work-meeting-info 
                ref="dialogWorkMeetingInfoChild" 
                drawer="dialogWorkMeetingInfo"
                :selectedItem="selectedWorkMeetingInfo" 
                :is_calendar="true"
            >
            </work-meeting-info>
        </el-dialog>
        <!--End Work Meeting Info Dialog -->

        <!-- Exhibition Info Dialog -->
        <el-dialog 
            class="el-dialog-w-100" 
            :title="$t('message.exhibition')" 
            :visible.sync="dialogExhibitionInfoVisible"
            :append-to-body="true" 
            top="5vh" 
            width="90%" 
            center 
            ref="dialogExhibitionInfo"
            @opened="drawerOpened('dialogExhibitionInfoChild')" 
            @closed="drawerClosed('dialogExhibitionInfoChild')"
        >
            <exhibition-info 
                ref="dialogExhibitionInfoChild" 
                drawer="dialogExhibitionInfo"
                :selectedItem="selectedExhibitionInfo" 
                :is_calendar="true"
            >
            </exhibition-info>
        </el-dialog>
        <!-- End Exhibition Info Dialog -->
    </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import FullCalendar from '@fullcalendar/vue';
import { Calendar, joinSpans } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import TaskModalUpdate from "@/views/tasks/components/add-modal-update";
import DealModalUpdate from "@/views/deals/components/add-modal";
import CreateNotification from "@/views/systeamNotification/components/crm-create";
import UpdateNotification from "@/views/systeamNotification/components/crm-update";
import MoreModal from "./components/moreModal";
import CrmCreateBoard from "./components/color-chek";
import selectFilial from "@/components/inventory/select-filial";
import dealCorrespondences from '@/views/deals/components/dealCorrespondence/deal-correspondences';
import workMeetingInfo from "@/views/workMeeting/components/workMeetingInfo";
import exhibitionInfo from "@/views/exhibitionInfo/index";

export default {
    name: "calendar",
    components: {
        FullCalendar,
        TaskModalUpdate,
        DealModalUpdate,
        CreateNotification,
        UpdateNotification,
        selectFilial,
        MoreModal,
        dealCorrespondences,
        CrmCreateBoard,
        workMeetingInfo,
        exhibitionInfo
    },

    data() {
        return {
            calendarOptions: {
                plugins: [dayGridPlugin],
                initialView: 'dayGridMonth',
            },
            modules: [
                { index: 0, className: 'tag31', label: 'task', value: 'task' },
                { index: 1, className: 'tag29', label: 'deal', value: 'deal' },
                { index: 2, className: 'tag25', label: 'calendarNotification', value: 'notification' },
                { index: 3, className: 'tag27', label: 'meeting', value: 'meeting' },
                { index: 4, className: 'tag32', label: 'exhibition', value: 'exhibition' },
                { index: 5, className: 'tag35', label: 'workMeeting', value: 'workMeeting' },
            ],
            isIndeterminate: false,
            selectedMoreModules: {},
            checkAll: true,
            checkedModules: ['task', 'deal', 'notification', 'meeting', 'exhibition', 'workMeeting'],
            filterForm: {
                filial_id: null,
                startDate: '',
                endDate: '',
            },
            clickedDate: '',
            currentEventType: '',
            week: '',
            drawerMoreInfo: false,
            taskUpdateDrawer: false,
            dealUpdateDrawer: false,
            createNotificationDialog: false,
            updateNotificationDialog: false,
            updateDealCorrespondenceDialog: false,
            dialogWorkMeetingInfoVisible: false,
            dialogExhibitionInfoVisible: false,
            drawerBoardCreate: false,
            selectedDeal: {},
            selectedTask: {},
            selectedNotification: {},
            correspondence: {},
            selectedWorkMeetingInfo: {},
            selectedExhibitionInfo: {},
            loadingData: false,
            reloadList: false,
            colorValue: {},

            calendarOptions: {
                views: {
                    dayGrid: {
                        dayMaxEventRows: 5,
                        moreLinkClick: this.showMoreItems,
                        moreLinkContent: function (args) {
                            return '+' + args.num + ' Более';
                        },
                    },
                    timeGrid: {
                        dayMaxEventRows: 5, // adjust to 6 only for timeGridWeek/timeGridDay
                        moreLinkClick: this.showMoreItems,
                        moreLinkContent: function (args) {
                            return '+' + args.num + ' Более';
                        },
                    }
                },
                locale: 'ru',
                firstDay: 1,
                plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin],
                initialView: 'dayGridMonth',
                customButtons: {
                    // prev: {
                    // 	text: '',
                    // 	click: function(event) {
                    // 		alert('prev !', event);
                    // 	}
                    // 	},
                    // next: {
                    // 	text: '',
                    // 	click: function(event) {
                    // 		alert('next !', event);
                    // 	}
                    // 	}
                    // getNotificationsButton: {
                    // 	text: this.$t("message.calendarNotification"),
                    // 	click: this.getAllNotifications
                    // },
                    // getDealsButton: {
                    //     text: this.$t("message.deals"),
                    //     click: this.getAllDeals
                    // },
                    // getTasksButton: {
                    //     text: this.$t("message.tasks"),
                    //     click: this.getAllTasks
                    // },

                },
                headerToolbar: {
                    left: 'prev,next',
                    center: 'title',
                    right: 'dayGridMonth,timeGridWeek,timeGridDay'
                },
                buttonText: {
                    today: this.$t("message.today"),
                    month: this.$t("message.monthly"),
                    week: this.$t("message.weekly"),
                    day: this.$t("message.day"),
                },
                // editable: true,
                // selectable: true,
                eventDrop: this.handleEditEvent,
                dateClick: this.handleDateClick,
                select: this.handleSelect,
                eventClick: this.handleEventClick,
                datesSet: this.handleDatesSet,
                // eventContent: this.handleEventContent, // for adding html element
                events: [
                    // {id: 1, title: 'Test', color: '#90EE90', start: '2022-04-15', end: '2022-04-25'},
                    // {id: 2, title: 'Test2', color: '#000000', start: '2022-04-15', end: '2022-04-25'}
                ],
                eventTimeFormat: {
                    hour: '2-digit',
                    minute: '2-digit',
                    meridiem: false
                },
                displayEventEnd: true,
                allDayText: 'Весь день',
                eventShortHeight: 10,
            }
        };
    },
    computed: {
        ...mapGetters({
            taskList: "tasks/tasksForCalendar",
            dealList: "deals/dealsForCalendar",
            notificationList: "calendarNotifications/list",
            calendarSettings: 'calendarNotifications/calendarSettings',
            mode: "MODE",
        }),
        mode() {
            return this.$store.getters.MODE;
        },
        items() {
            if (!_.isEmpty(this.calendarSettings)) {
                let values = _.map(this.calendarSettings, 'value');
                let filtered_array = _.filter(this.modules, item => !(values.includes(item.value)));
                filtered_array = filtered_array.concat(this.calendarSettings);
                filtered_array = _.sortBy(filtered_array, 'index');

                return filtered_array;
            }
            return this.modules;
        }
    },
    watch: {
        filterForm: {
            handler: async function (newVal) {
                if (newVal) {
                    this.getAllNotifications(this);
                }
            },
            deep: true,
            immediate: true
        },
        checkedModules: {
            handler: function (newVal, oldVal) {
                if (newVal.length < oldVal.length && newVal.length !== 0) {
                    this.checkAll = false;
                    this.isIndeterminate = true;
                    let diff_value = _.difference(oldVal, newVal)[0];
                    this.calendarOptions.events = this.calendarOptions.events.filter(item => (item.value != diff_value));
                }
                else if (newVal.length > oldVal.length) {
                    if (newVal.length == 6) {
                        this.isIndeterminate = false;
                        this.checkAll = true;
                    } else {
                        this.isIndeterminate = true;
                        this.checkAll = false;
                    }

                    let diff_value = _.difference(newVal, oldVal)[0];
                    let filtered_array = this.notificationList.filter(item => item.value == diff_value);
                    this.calendarOptions.events = this.calendarOptions.events.concat(filtered_array);
                }
                else if (newVal.length === 0) {
                    this.checkAll = false;
                    this.isIndeterminate = false;
                    this.calendarOptions.events = [];
                }
            },
            deep: true
        },
    },
    created() {
        setTimeout(() => {
            let button = document.querySelectorAll('.fc-button');
            for (const item of button) {
                item.title = "";
            }
        }, 1500);
        let currentDate = new Date();
        let year = new Date(currentDate.getFullYear(), 0, 1);
        let days = Math.floor((currentDate - year) / (24 * 60 * 60 * 1000));
        this.week = Math.ceil((currentDate.getDay() + 1 + days) / 7);
    },
    methods: {
        ...mapActions({
            getAllFullNotificationList: "calendarNotifications/getAllFullNotificationList",
            getDetailMeetingForCalendar: "dealCorrespondences/getDetailMeetingForCalendar",
        }),
        getAllNotifications: _.debounce(function (self) {
            self.loadingData = true;
            let query = { ...self.filterForm };
            self.calendarOptions.events = [];
            self.getAllFullNotificationList(query).then(res => {
                self.loadingData = false;
                self.calendarOptions.events = _.filter(self.notificationList, function (item) {
                    return (self.checkedModules.includes(item.value));
                });
            }).catch((err) => {
                self.loadingData = false;
                self.$alert(err);
            });
        }, 500
        ),

        editModulColor(module) {
            this.colorValue = module;
            this.drawerBoardCreate = true;
        },
        async handleDatesSet(arg) {
            const startDate = (this.formatDate(arg.start));
            const endDate = (this.formatDate(arg.end));

            this.filterForm.startDate = JSON.parse(JSON.stringify(startDate));
            this.filterForm.endDate = JSON.parse(JSON.stringify(endDate));
            // Do whatever you want with the dates
        },
        handleCheckAllChange(check_all) {
            if (check_all) {
                this.modules.forEach(elem => { this.checkedModules.push(elem.value); });
                this.calendarOptions.events = JSON.parse(JSON.stringify(this.notificationList));
                this.isIndeterminate = false;
            }
            else {
                this.checkedModules = [];
                this.calendarOptions.events = [];
            }
        },
        handleEventClick(arg, isChildModule = false) {
            let currentEventType, module_id = '';

            if (!isChildModule) {
                currentEventType = arg.event.extendedProps.value;
                module_id = arg.event.id;
            }
            else {
                currentEventType = arg.value;
                module_id = arg.id;
            }

            if (currentEventType === 'task') {
                this.taskUpdateDrawer = true;
                this.selectedTask = JSON.parse(JSON.stringify(this.calendarOptions.events)).find(item => item.id == module_id);
            }
            else if (currentEventType === 'deal') {
                this.dealUpdateDrawer = true;
                this.selectedDeal = JSON.parse(JSON.stringify(this.calendarOptions.events)).find(item => item.id == module_id);
            }
            else if (currentEventType === 'notification') {
                this.updateNotificationDialog = true;
                this.selectedNotification = JSON.parse(JSON.stringify(this.calendarOptions.events)).find(item => item.id == module_id);
            }
            else if (currentEventType === 'meeting') {
                this.getDetailMeetingForCalendar(module_id).then((res) => {
                    if (res.dealCorrespondence) {
                        this.updateDealCorrespondenceDialog = true;
                        this.correspondence = res.dealCorrespondence;
                    }
                });
            }
            else if (currentEventType === 'workMeeting') {
                this.dialogWorkMeetingInfoVisible = true;
                this.selectedWorkMeetingInfo = JSON.parse(JSON.stringify(this.calendarOptions.events)).find(item => item.id == module_id);
            }
            else if (currentEventType === 'exhibition') {
                this.dialogExhibitionInfoVisible = true;
                this.selectedExhibitionInfo = JSON.parse(JSON.stringify(this.calendarOptions.events)).find(item => item.id == module_id);
            }
        },
        handleDateClick(arg) {
            this.createNotificationDialog = true;
            this.clickedDate = arg.dateStr;
        },
        showMoreItems(date) {
            let formattedDate = this.formatDate(date.date);
            let hiddenSegs = [];

            date.hiddenSegs.forEach(elem => {
                let obj = _.find(this.calendarOptions.events, { 'id': parseInt(elem.event.id), 'value': elem.event.extendedProps.value });
                let module = _.find(this.items, { 'value': elem.event.extendedProps.value });
                obj['className'] = module.className;
                obj['label'] = module.label;
                hiddenSegs.push(obj);
            });

            this.$set(this.selectedMoreModules, 'formattedDate', formattedDate);
            this.$set(this.selectedMoreModules, 'hiddenSegs', hiddenSegs);
            this.drawerMoreInfo = true;

            return true;
        },

        listChanged() {
            this.reloadList = true;
        },
        drawerMoreInfoModalClosed() {
            this.hiddenSegs = [];
        },
        closeCalendarNotification(val) {
            this.updateNotificationDialog = false;
            this.createNotificationDialog = false;
        },
        closeDrawer(drawer) {
            this.drawerBoardCreate = false;
            this.taskUpdateDrawer = false;
            this.createNotificationDialog = false;
            if (this.$refs[drawer] && _.isFunction(this.$refs[drawer].closeDrawer)) {
                this.$refs[drawer].closeDrawer();
            }
        },
        closeAddModel() {
            this.dealUpdateDrawer = false;
        },
        drawerClosed(ref) {
            if (this.$refs[ref]) {
                this.$refs[ref].closed();
            }
            if (this.reloadList === true) {
                this.getAllNotifications(this);
                this.reloadList = false;
            }
            if (_.isFunction(this.empty)) {
                this.empty()
            }
        },
        drawerOpened(ref) {
            if (this.$refs[ref]) {
                if (_.isFunction(this.$refs[ref].opened)) {
                    this.$refs[ref].opened();
                }
            }
        },
        urlify(text) {
            let urlRegex = /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/igm;
            return text.replace(urlRegex, function (url) {
                return '<a target="_blank" href="' + url + '">' + url + '</a>';
            })
        },
        closedDealCorrespondenceDialog() {
            this.correspondence = {};
        },
        refresh() {
            this.getAllNotifications(this);
        },
        handleSelect(arg) { },
        handleEventContent(arg) { },
        handleEditEvent(arg) {
            // has old date and has new date
        },
    },
    beforeRouteLeave(to, from, next) {
        this.calendarOptions.events = [];
        this.$store.commit("calendarNotifications/EMPTY_LIST");
        next();
    },
};
</script>
<style>
.calendar-night {
    border: 1px dashed #2a2f34 !important;
    background-color: #080c24 !important;
    color: #ced4da;
}

.calendar-day {
    width: 100%;
    background-color: #fff !important;
    border-radius: 8px;
    overflow: hidden;
}

.calendarday div div table {
    border: 1px solid #000 !important;
}

.calendarday div div table tbody tr td {
    border: 1px solid #000 !important;
    color: #000 !important;
}

.calendarnight div div table {
    border: 1px solid #fff !important;
}

.calendarnight div div thead tr th {
    color: #fff !important;
    border: 1px solid #fff !important;
}

.calendarnight div div table tbody tr td {
    border: 1px solid #fff !important;
    color: #fff !important;
}

.filter-filyal {
    margin-bottom: 20px;
}

.filter-filyal .el-input__inner {
    background-color: #0e2b4500;
    border-color: #e5e5e561;
    color: #fff !important;
    border-radius: 8px;
}

.select__formday .el-input__inner {
    border-color: #bfc4c9 !important;
    color: #606266 !important;
}

.select__formnight .el-input__inner {
    border-color: #bdc2c7 !important;
    color: #ffffff !important;
}

.fc-toolbar-chunk {
    display: flex;
}

.fc .fc-button {
    padding: 0.3em 0.45em;
    font-size: 0.9em;
}

.l-modal-style .fc .fc-button {
    border: 1px solid #dddddd !important;
    background-color: #fff !important;
    color: #717171 !important;
}

/* .fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events{
        height: 80px;
        overflow: hidden;
    } */
.fc .fc-daygrid-day-frame {
    height: 140px;
}

.fc .fc-daygrid-day-frame:hover {
    background-color: #747474b5;
    cursor: pointer;
}

.leftCheckbox li .el-checkbox {
    padding: 5px;
    margin-bottom: 5px;
    border-radius: 3px;
    width: 100%;
}

.leftCheckbox .el-checkbox__input.is-checked+.el-checkbox__label {
    color: #000000;
}

.leftCheckbox li {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.leftCheckbox li .el-checkbox-group {
    flex: 1;
}

.fc-daygrid-day-bottom .fc-daygrid-more-link.fc-more-link {
    display: block;
    background: #a6a6a6;
    margin: 3px 0px;
    width: 100%;
    border-radius: 3px;
    color: #fff;
    padding: 3px;
}

.d-modal-style .fc .fc-timegrid-col.fc-day-today,
.d-modal-style .fc .fc-daygrid-day.fc-day-today {
    background: #080e32a6;
}

.d-modal-style .fc-theme-standard .fc-scrollgrid,
.d-modal-style .fc-theme-standard td,
.d-modal-style .fc-theme-standard th {
    border: 1px dashed #274156 !important;
}

.fc-button-group {
    margin-right: 10px;
}

.btn-color-calendar {
    height: 30px;
    width: 30px;
    line-height: 29px !important;
    padding: 0 !important;
    margin-bottom: 5px !important;
    margin-left: 10px !important;
}
</style>
<style lang="scss">
.caldialog-closeCalendarNotification {
    .el-dialog__header {
        padding: 0;
    }

    .el-dialog__body {
        padding: 0;
        padding-bottom: 20px;
    }

    .app-modal__box {
        min-height: auto;
    }
}

.hafta {
    border: 1px solid #dddddd !important;
    background-color: #fff !important;
    color: #717171 !important;
    position: absolute;
    top: 9px;
    left: 190px;
    height: 31px;
    border-radius: 3px;
}

.p-relativee {
    position: relative;
}

.fc-button::after {
    content: attr(title);
    position: absolute;
    top: -20px;
    left: 0;
    width: 100%;
    height: 100%;
    color: transparent;
}

button[title] {
    // pointer-events: none;
}
</style>
