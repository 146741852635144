import request from '@/utils/request';

export function index(params) {
    return request({
        url: '/products',
        method: 'get',
        params
    })
}
export function store(data) {
    return request({
        url: '/products',
        method: 'post',
        data
    })
}
export function inventory(params) {
    return request({
        url: "/products/inventory",
        method: "get",
        params
    });
}

export function filialProducts(params) {
    return request({
        url: `/products/getFilialProducts/${params.filial_id}`,
        method: "get",
        params
    });
}

export function show(id) {
    return request({
        url: `/products/${id}`,
        method: 'get'
    })
}
export function update(data) {

    let data_id = null;
    if (data instanceof FormData) {
        data_id = data.get('id')
    }
    else {
        data_id = data.id;
    }
    return request({
        url: `/products/${data_id}?_method=PUT`,
        method: 'post',
        data
    })
}
export function destroy(id) {
    return request({
        url: `/products/${id}`,
        method: 'delete'
    })
}

export function getProducts(params) {
    return request({
        url: '/products/getProducts',
        method: 'get',
		params
    })
}

export function deleteImage(data) {
    return request({
        url: '/products/images/delete',
        method: 'post',
        data
    })
}

export function printBarcode(params) {
    return request({
        url: `/products/printBarcode/${params.id}`,
        method: 'get',
        params
    })
}

export function updateComposition(data) {
    return request({
        url: `/products/updateComposition/${data.id}`,
        method: 'put',
        data
    })
}

export function excelFileDownload() {
	return request({
		 url: '/products/excelFileDownload',
		 method: 'get',
		 responseType: 'blob'
	})
}

export function getFilialProductsByCategories(params) {
    return request({
        url: '/products/getFilialProductsByCategories',
        method: 'get',
        params
    })
}

export function showForClient(params) {
    return request({
        url: `/products/${params.id}`,
        method: 'get',
        params
    })
}
