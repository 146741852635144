import _ from 'lodash';
import { mapGetters, mapActions } from "vuex";
import TaskModal from "@/views/tasks/components/add-modal";
import DealModal from "@/views/deals/components/add-modal";
import ContactCreate from "@/views/phoneContacts/components/crm-create";
import callKantentCom from "@/components/call/callTaskCom";
import callTaskCom from "@/components/call/callKantentCom";

export default {
    props:{},
    components: {
        TaskModal,
        DealModal,
        ContactCreate,
        callTaskCom,
        callKantentCom,
    }, 
    data() {
        return {
            dataSearchIsWorking: false,
            activeTabName: "clients",
            loadingCallHistoryData: false,
            activeCatPhone: false,

        }
    },
    
    computed: {
        ...mapGetters({
            // authUser: "auth/user",
        }),
    },

    watch: {
        searchClientInput: {
            handler(newValue, oldValue) {
                this.debouncedUpdateClientsInventory(this, {search: newValue});
            },
            immediate: true,
            deep: true
        },
    },
    
    mounted() {
        
    },

    created() {

    },
    
    methods:{
        ...mapActions({
            updateContactInventory: "phoneContacts/inventory",
            updateClientsInventory: "clients/inventory",
            searchPhoneContact: "phoneContacts/searchPhoneContact",
            updateTaskList: "taskBoards/index",
            updateDealsList: "boards/index",
            updateExhibitionDealsList: "deals/index",
            updateContactList: "phoneContacts/index",
            updateCallHistoryList: "callHistories/getPhoneCalls",
            updatePhoneNumberDeals: "deals/phoneNumberDeals",
        }),

        handleTabClick(tab, event){
            if(tab.name == 'callHistory' && this.callHistoryList.length == 0){
                this.debouncedFetchCallHistoryData(this);
            }else if(tab.name == 'clients' && this.clientInventory.length == 0){
                this.debouncedUpdateClientsInventory(this);
            }else if(tab.name == 'contacts' && this.phoneContacts.length == 0){
                this.updateContactInventory();
            }
        },

        searchInputBlur(){
            this.dataSearchIsWorking = false;
        },

        searchInputFocus(){
            this.dataSearchIsWorking = true;
        },


        openDepartmentList(e) {
            this.activeCatPhone = true;
            this.activeIconPhone = false;
        },

        closeDepartmentList(e) {
            this.activeCatPhone = false;
            this.activeIconPhone = true;
        },

        ikontalModal(e) {
            this.activeKontakModal = true;
            this.miniPage = true;
            this.$refs.callKantentCom.afterOpen();
        },

        closeIconKontak(e) {
            this.activeKontakModal = false;
            this.miniPage = !this.miniPage;
        },

        ikontalModalTask(e) {
            this.activeTaskModal = true;
            this.miniPage = true;
            this.$refs.callTaskCom.afterOpen();
        },

        closeIconKontakTask(e) {
            this.activeTaskModal = false;
            this.miniPage = !this.miniPage;
        },

        debouncedUpdateClientsInventory: _.debounce((self, query={}) => {
            if (_.isFunction(self.updateClientsInventory)) {
                self.updateClientsInventory(query);
            }
        }, 500),

        addModalEdit({deal, dealCreate}) {
            this.activeTaskModal = false;
            this.board_id = deal.board_id;
            this.selectedDeal = deal;
            this.dealCreate = dealCreate;
            this.drawerDealCreate = true;
        },

        createDealFromContact(){
            this.dealCreate = true;
            this.activeTaskModal = false;
            this.drawerDealCreate = true;
        },

        // HISTORY START

        fetchCallHistoryData() {
            this.loadingCallHistoryData = true;
            this.updateCallHistoryList({phone_number: this.authUser.sip_account.sip_number})
                .then(res => {
                    this.loadingCallHistoryData = false;
                })
                .catch(err => {
                    this.loadingCallHistoryData = false;
                });
        },

        debouncedFetchCallHistoryData: _.debounce((self) => {
            if (_.isFunction(self.fetchCallHistoryData)) {
                self.fetchCallHistoryData();
            }
        }, 500),

        // HISTORY END

        // CONTACT START

        findContactInputSearch() {
            if (this.contactSearchInput) {
                this.searchContacts(this.contactSearchInput, this);
            } else {
                setTimeout(() => {
                    this.updateContactInventory();
                }, 1000);
            }
        },
        searchContacts: _.debounce((search, self) => {
            if (_.isFunction(self.searchPhoneContact)) {
                self.searchPhoneContact({ text: search });
            }
        }, 1000),

        // CONTACT END

        listChanged() {
            this.reloadList = true;
        },
        
        closeDrawer(drawer) {
            this.taskModalDrawer = false;
            if (
                this.$refs[drawer] &&
                _.isFunction(this.$refs[drawer].closeDrawer)
            ) {
                this.$refs[drawer].closeDrawer();
            }
        },
        closeAddModel() {
            this.drawerDealCreate = false;
            if (this.reloadList === true) {
                this.updateDealsList().then((res) => this.reloadList === false);
                if(this.exhibition_id && this.exhibition_id !== undefined && this.exhibition_id !== NaN){
                    const query = {...this.filter, ...this.pagination, ...this.sort, exhibition_id: this.exhibition_id };
                    this.updateExhibitionDealsList(query);
                }
                if(this.calculation.length > 0){
                    this.updatePhoneNumberDeals(this.calculation).then((res) => this.reloadList === false);
                }
            }
            if(!this.activeTaskModal && this.miniPage){
                this.activeTaskModal = true;
            }
            setTimeout(() => {
                this.selectedDeal = {};
                this.board_id = null;
                this.dealCreate = true;
            }, 100);
        },
        drawerClosed(ref) {
            if (this.$refs[ref]) {
                this.$refs[ref].closed();
            }
            if (this.reloadList === true) {
                if (ref === "drawerCreateContactChild") {
                    this.updateContactList().then(
                        (res) => this.reloadList === false
                    );
                }
                if (ref === "drawerCreateTaskChild") {
                    this.updateTaskList().then(
                        (res) => this.reloadList === false
                    );
                }
            }
            if (_.isFunction(this.empty)) {
                this.empty();
            }
        },
        drawerOpened(ref) {
            if (this.$refs[ref]) {
                if (_.isFunction(this.$refs[ref].opened)) {
                    this.$refs[ref].opened();
                }
            }
        },
    }

}