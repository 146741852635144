import { i18n } from '@/utils/i18n';
import { pagination } from "@/store/modules/properties/pagination";
import { filter } from "./properties/filter";
import store from "@/store/index";
export const mutations = {
    SET_LIST: (state, callHistory) => {              
        state.list = callHistory;
    },
    SET_PHONE_CALL_HISTORY: (state, callHistory) => {
        let number = store.getters['auth/user'].sip_account.sip_number;
        state.phoneCallList = callHistory.filter(el => el.cnum == number || el.dst == number);        
    },

    SET_CALLER_HISTORY: (state, callerHistory) => {              
        state.callerHistory = callerHistory && callerHistory.length > 0 ? callerHistory[0] : null;
    },

    UPDATE_PAGINATION: (state, pagination) => {
        state.pagination[pagination.key] = pagination.value
    },
    UPDATE_COLUMN: (state, obj) => {
        state.columns[obj.key].show = obj.value
    },
    SET_FILTER: (state, filter) => (state.filter = filter),

    REFRESH: (state) => {
        state.filter = JSON.parse(JSON.stringify(filter));
        state.pagination = JSON.parse(JSON.stringify(pagination));
    },

    SET_TOTAL_STATISTICS: (state, totalStatistics) => {              
        state.totalStatistics = totalStatistics;        
    },

    SET_TRUNCK_DIAGRAMS: (state, trunckDiagrams) => {              
        state.trunckDiagrams = trunckDiagrams;        
    },

    SET_USER_DIAGRAMS: (state, userDiagrams) => {              
        state.userDiagrams = userDiagrams;        
    },
    
};
