import { index, store, inventory, show, changeIndex, update, destroy, getBoardDeals} from '@/api/deals/boards';

export const actions = {
    
    index({commit}, params = {}){
        return new Promise((resolve, reject) => {
            index(params).then(res => {
                commit('SET_LIST', res.data.result.data.boards)
                commit("UPDATE_PAGINATION", { key: 'total', value: res.data.result.data.pagination.total });
                resolve(res.data.result)
            }).catch(err => {
                reject(err)
            })  
        })
    },
    
    inventory({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            inventory(params).then(res => {
                commit("SET_INVENTORY", res.data.result.data.boards);
                resolve(res.data)
            }).catch(err => {
                reject(err)
            })
        })
    },

    show({ commit }, id) {
        return new Promise((resolve, reject) => {
            show(id).then(res => {
                commit("SET_MODEL", res.data.result.data.board);
                resolve(res.data.result)
            }).catch(err => {
                reject(err)
            })
        })
    },

    getBoardDeals({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            getBoardDeals(params).then(res => {
                commit("SET_DEALS_IN_BOARD", res.data.result.data.board);
                resolve(res.data.result)
            }).catch(err => {
                reject(err)
            })
        })
    },

    changeIndex({ commit }, data) {
        return new Promise((resolve, reject) => {
            changeIndex(data).then(res => {
                resolve(res.data.result)
            }).catch(err => {
                reject(err)
            })
        })
    },
    
    store({ commit }, data) {
        return new Promise((resolve, reject) => {
            store(data)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    
    update({ commit }, data) {
        return new Promise((resolve, reject) => {
            update(data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },

    destroy({ commit }, id) {
        return new Promise((resolve, reject) => {
            destroy(id).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    
    updateSort({ commit }, sort) {
        commit("SET_SORT", sort)
    },

    emptyModel ({ commit }, model) {
        commit("EMPTY_MODEL", model)
    },

    updateFilter({ commit }, filter) {
        commit('SET_FILTER', JSON.parse(JSON.stringify(filter)))
    },

    updateColumn({ commit }, obj) {
        commit('UPDATE_COLUMN', obj)
    },
    updatePagination({ commit }, pagination) {
        commit('UPDATE_PAGINATION', pagination)
    },
    refreshData({ commit }) {
        return new Promise((resolve, reject) => {
            commit("REFRESH");
            resolve()
        })
    },

}

