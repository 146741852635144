import _ from 'lodash';
import { mapGetters, mapActions } from "vuex";
import JsSIP from "jssip";
import { formatDate } from '@fullcalendar/core';

export default {
  props:{},   
  data() {
    return {
        searchingCallerHistory: false,
    }
  },
  watch: {
   
  },

  computed: {
    lastOnlineDateDifference(){
        let phoneDisconnectedTime = window.localStorage.getItem('phoneDisconnectedTime');
        let differenceInSeconds = 0;
        if(phoneDisconnectedTime && phoneDisconnectedTime > 0){
            phoneDisconnectedTime = new Date(phoneDisconnectedTime);
            let current_date = new Date(); // Current date and time
            let differenceInMilliseconds = current_date - phoneDisconnectedTime;
            differenceInSeconds = differenceInMilliseconds / 1000;
        }
      
        return differenceInSeconds;
    },

  },
  
  mounted() {
  },

  created() {
    if(this.authUser.sip_account && this.authUser.sip_account.sip_number){
        window.addEventListener('beforeunload', this.handleBeforeUnload);
        this.checkMicrophone();
    }
  },
  methods:{
    ...mapActions({
        updatePhoneNumberDeals: "deals/phoneNumberDeals",
        updateCallerHistory: "callHistories/callerHistory",
        emptyCallerHistory: "callHistories/emptyCallerHistory",

    }),

    async checkMicrophone() {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            setTimeout(() => {
                const audioTrack = stream.getAudioTracks()[0];

                if (audioTrack.muted == true) { // Adjust threshold as needed
                    this.$notify({
                        title: this.$t('message.microphone_disabled'),
                        type: "warning",
                        offset: 130,
                        message: this.$t('message.microphone_volume_is_disabled'),
                    }); 
                } else if (audioTrack.disabled == true) {
                    this.$notify({
                        title: this.$t('message.microphone_disabled'),
                        type: "warning",
                        offset: 130,
                        message: this.$t('message.enable_your_microphone'),
                    }); 
                } else {
                    this.$notify({
                        title: this.$t('message.success'),
                        type: "success",
                        offset: 130,
                        message: this.$t('message.microphone_success'),
                    });                        
                }
                stream.getTracks().forEach(track => track.stop());
            }, 1000);
        } catch (error) {
            this.$notify({
                title: this.$t('message.microphone_disabled'),
                type: "warning",
                offset: 130,
                message: this.$t('message.enable_your_microphone'),
            });
        }
    },

    handleBeforeUnload(){
        window.localStorage.setItem('phoneDisconnectedTime', this.dateTimeFormat(new Date(), true));
        this.unregisterThePhone();
    },

    callerHistorySearch(phone_number){
        this.searchingCallerHistory = true;
        this.updateCallerHistory({phone_number: phone_number, per_page: 1, page: 1})
            .then(res => {
                this.searchingCallerHistory = false;
            })
            .catch(err => {
                this.searchingCallerHistory = false;
            });
    },

    debouncedRegisterUserAgentJSSIP: _.debounce((self) => {
        if (_.isFunction(self.registerUserAgentJSSIP) && self.authUser.sip_account && self.authUser.sip_account.sip_number && self.authUser.sip_account.phones.length > 0) {
            self.checkSipStatusAndRegister();
        }else{
            self.phoneStatus = ''
        }
    }, 1000),

    
    async checkSipStatusAndRegister(){
        this.selectedPhoneNumberForOutboundCall = this.authUser.sip_account.phones[0].prefix;
        let register_phone_status = await this.getSipStatusesAndReturnRegistering();
        if(register_phone_status == 'online' && this.lastOnlineDateDifference >= 60){
            this.$notify({
                title: this.$t('message.warning'),
                type: "warning",
                offset: 130,
                message: this.$t('message.phone_registered_on_another_device', {phone: this.authUser.sip_account.sip_number})
            });
        }else{
            await this.registerUserAgentJSSIP();
        }
    },

    async getSipStatusesAndReturnRegistering(){
        let url = 'https://bmcallserver.gomax.uz:8089/ari/endpoints?api_key=go_crm_ari_user:ari_password'
        let register_phone_status = 'offline';
        await this.ariConnectionWithServer(url);
        await this.sipAccountsFromServer()
            .then(res => {
                this.sipAccountList = [];
                this.serverSipAccountList.forEach(element => {
                    let phone_state = this.ariPhones.find(item => item.resource === element.username);
                    if(phone_state && phone_state.state && this.authUser.sip_account.sip_number == element.username){
                        register_phone_status = phone_state.state;
                    }
                    this.sipAccountList.push({
                        username: element.username,
                        displayname: element.displayname,
                        color: (phone_state && phone_state.state === 'online') ? 'green' : 'red',
                    })
                });
            });
        return register_phone_status;
    },

    async registerUserAgentJSSIP() {
     
        // using jsSIP
        let vm = this;
        let wss_url = "wss://" + vm.authUser.sip_account.server_url + ":" + vm.authUser.sip_account.port + "/ws";
        let socket = new JsSIP.WebSocketInterface(wss_url);
        let sip_uri = this.authUser.sip_account.extension_type + ":" + this.authUser.sip_account.sip_number + "@" + this.authUser.sip_account.server_url;
        let configuration = {
            sockets: [socket],
            uri: sip_uri,
            password: this.authUser.sip_account.password,
        };

        let coolPhone = new JsSIP.UA(configuration);
        coolPhone.start();
        coolPhone.register();

        coolPhone.on("registered", function (e) {
            vm.$notify({
                title: vm.$t('message.registered'),
                type: "success",
                offset: 130,
                message: vm.$t('message.phone_registered_message', {phone: vm.authUser.sip_account.sip_number})
            });
            vm.phoneStatus = 'registered';
        });
        coolPhone.on("unregistered", function (e) {
            vm.$notify({
                title: vm.$t('message.disconnected'),
                type: "warning",
                offset: 130,
                message: vm.$t('message.phone_unregistered', {phone: vm.authUser.sip_account.sip_number})
            });

            vm.phoneStatus = 'unregistered';
        });
        coolPhone.on("registrationFailed", function (e) {
            vm.$notify({
                title: vm.$t('message.register'),
                type: "error",
                offset: 130,
                message: vm.$t('message.phone_not_registered_message', {phone: vm.authUser.sip_account.sip_number}),
            });
            vm.phoneStatus = 'registrationFailed'
        });

        coolPhone.on("newRTCSession", function (data) {
            let session = data.session;
            session.on("peerconnection", function (data) {
                data.peerconnection.addEventListener('addstream', function (e) {
                    // set remote audio stream
                    vm.$refs["remoteAudioRef"].srcObject = e.stream;
                    vm.$refs["remoteAudioRef"].play();
                });
            });

            if (session.direction === "outgoing") {
                session.connection.createOffer().then(function(offer) {
                    try{
                        session.connection.setLocalDescription(offer);
                    }catch(err){
                        console.log(err,'err');
                    }
                }).catch(err => {
                    console.log(err, 'errrrrr');
                })
            }

            vm.waitCallDecision(session);
            vm.callDirection = session.direction;

            if (session.direction === "outgoing") {
                vm.callerHistorySearch(vm.calculation);

                let start_out = true;
                session.on("progress", function (e) {
                    if(start_out){
                        start_out = false;
                        if(session.remote_identity.uri.user.length <= 5){
                            document.getElementById("sound-beep").play();
                        }
                    }
                });
                session.on("accepted", function (e) {
                    if(vm.callSessionsList.length === 1){
                        vm.start();
                    }
                    document.getElementById("sound-beep").load();
                });
                session.on("confirmed", function (e) {
                    if(vm.addingCall){
                        vm.callSessionsList.length > 1 ? vm.conferencing() : '';
                        setTimeout(() => {
                            vm.callSessionsList.forEach(element => {
                                if(element.session.isOnHold().local){
                                    element.session.unhold();
                                }
                            });
                        }, 200);

                        // vm.addingCall = false;
                    }
                    document.getElementById("sound-beep").load();
                });

                session.on("refer", function (req) {
                });

                session.on("failed", function (reason) {
                    vm.callStopped(session);
                });

                session.on("ended", function () {
                    if(vm.isTransferCall === true && session.status && session.C.STATUS_TERMINATED){
                        vm.callSessionsList = [];
                    }
                    vm.callStopped(session);
                });
                session.on("icecandidate", function (event) {
                    if (
                        event.candidate.type === "srflx" &&
                        event.candidate.relatedAddress !== null &&
                        event.candidate.relatedPort !== null
                    ) {
                        event.ready();
                    }
                });
            }
            if (session.direction === "incoming") {
                session.on("progress", function (e) {
                    document.getElementById("incoming-call-beep").play();
                    if(session.remote_identity.uri.user){
                        let number = session.remote_identity.uri.user;
                        if(number.length > 9){
                            number = number.slice(-9);
                        }
                        vm.callerHistorySearch(number);
                        vm.updatePhoneNumberDeals(session.remote_identity.uri.user);
                    }
                });
                session.on("refer", function (req) {
                });
                session.on("accepted", function () {
                    if(vm.callSessionsList.length === 1){
                        vm.start();
                    }
                    document.getElementById("incoming-call-beep").load();
                });
                session.on("confirmed", function () {
                    if(vm.addingCall){
                        vm.callSessionsList.length > 1 ? vm.conferencing() : '';
                    }
                });
                session.on("ended", function () {
                    vm.callStopped(session);
                });

                session.on("failed", function (reason) {
                    vm.callStopped(session);
                });
                session.on("addstream", function (e) {
                    vm.$refs["remoteAudioRef"].srcObject = e.stream;
                    vm.$refs["remoteAudioRef"].play();
                });


            }
        });
        this.phone = coolPhone;
        this.$phoneUA = coolPhone;
    },

    waitCallDecision(session) {
      if(Object.keys(session)){
          this.addCallToCallSessionsList(session);
          this.callingModal = true;
          this.pickUpCallIcon = true;
          this.activeIconPhone = false;
          this.miniPage = false;
      }
    },

    isSecondLine(session){
        return session.request.getHeader('X-Second-Line') === 'true';
    },

    unregisterThePhone(){
      if(this.phone && this.phone.isRegistered()){
          this.phone.unregister()
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
  },

}