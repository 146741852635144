import _ from 'lodash';
import { mapGetters, mapActions } from "vuex";

export default {
  props:{},   
  data() {
    return {

    }
  },
  watch: {
    numberToCall: {
        handler: async function(newVal, oldVal) {
            let old_phone = oldVal && newVal && newVal.phone == oldVal.phone;
            if(newVal && newVal.phone && !old_phone){
                this.selectedPhoneNumberForOutboundCall = newVal.prefix;
                this.calculation = newVal.phone;
                setTimeout(() => {
                    this.callingClick();
                }, 100);
            }
        },
        deep: true,
        immediate: true
    },
  },
  
  mounted() {
  },

  created() {

  },
  methods:{
    ...mapActions({
        setNumberToCall: "call/setNumberToCall",    
        updateDeal: "deals/show",
        updateDealCallList: "callHistories/index",
        updatePhoneNumberDeals: "deals/phoneNumberDeals",
        bindingCallHistory: "callHistories/bindingCallHistory",
    }),

    setGlobalCallStatus(status){
        this.setNumberToCall({
            prefix: this.numberToCall.prefix,
            phone: this.numberToCall.phone,
            status: status,
        })
    },

    addCallToCallSessionsList(session){
        let existing_session = {};
        if(session.direction === 'incoming'){
            if(session.remote_identity.uri.user.length > 5){
                let find_con = this.phoneContacts.find(el => el.phone_number.includes(session.remote_identity.display_name));
                existing_session = {
                    session: session,
                    display_name: find_con ? JSON.parse(JSON.stringify(find_con.name)) : session.remote_identity.display_name,
                    username: find_con ? JSON.parse(JSON.stringify(session.remote_identity.uri.user)) : '',
                    company: find_con ? (find_con.company ? find_con.company : '') : '',
                };
            }else{
                existing_session = {
                    session: session,
                    display_name: JSON.parse(JSON.stringify(session.remote_identity.display_name)),
                    username: JSON.parse(JSON.stringify(session.remote_identity.uri.user)),
                    company: '',
                };
            }
        }
        if(session.direction === 'outgoing'){
            if(this.calculation.length > 5){
                let find_con = this.phoneContacts.find(el => el.phone_number.includes(this.calculation));
                existing_session = {
                    session: session,
                    display_name: find_con ? JSON.parse(JSON.stringify(find_con.name)) : '',
                    username: JSON.parse(JSON.stringify(this.calculation)),
                    company: find_con ? (find_con.company ? find_con.company : '') : '',
                };
            }else{
                let find_con = this.sipAccountList.find(el => el.username === this.calculation);
                existing_session = {
                    session: session,
                    display_name: find_con ? JSON.parse(JSON.stringify(find_con.displayname)) : '',
                    username: JSON.parse(JSON.stringify(this.calculation)),
                    company: '',
                };
            }
        }


        if(this.addingCall && this.callSessionsList.length === 1){
            this.callSessionsList.forEach(tell => { tell.session.hold() });
        }
        this.callSessionsList.unshift(existing_session);

    },

    answerCall(session, index) {
        if(Object.keys(session)){
            let callOptions = {
                mediaConstraints: {
                    audio: true, // only audio calls
                    video: false,
                },
                rtcOfferConstraints: {
                    audio: true, // only audio calls
                    video: false,
                },
                sessionTimersExpires: 3600,
                pcConfig: {
                    iceServers: [
                        {
                            urls: [
                                "stun:stun.l.google.com:19302",
                                // "stun:stun.zoiper.com:3478"
                            ],
                        },
                    ],
                    iceTransportPolicy: "all",
                    rtcpMuxPolicy: "negotiate",
                },
            };

            if(!this.addingCall){
                this.callSessionsList.forEach(element => {
                    if(element.session.status === element.session.C.STATUS_CONFIRMED){
                        element.session.hold();
                    }
                });
            }

            session.answer(callOptions);

            if(session && session.status === session.C.STATUS_CONFIRMED && this.addingCall){
                    this.callSessionsList.forEach(element => {
                        if(element.session.isOnHold().local){
                            element.session.unhold();
                        }
                    });
            }
        }
    },

    callStopped(session){
        if(session.status !== session.C.STATUS_CONFIRMED){
            this.callSessionsList.forEach((item) => {
                if(item.session.isOnHold().local){
                    item.session.unhold();
                }
            })
        }
        this.callSessionsList = this.callSessionsList.filter(item => item.session.status !== item.session.C.STATUS_TERMINATED)
        if(this.numberToCall && this.numberToCall.phone){
            this.setGlobalCallStatus('TERMINATED');
        }
        document.getElementById("sound-beep").load();
        document.getElementById("incoming-call-beep").load();
        this.callingModal = false;
        this.activeIconPhone = true;
        this.noneBlock = false;
        this.addMic = false;
        if(this.callSessionsList.length === 0 || this.isTransferCall){
            if(this.callRequestUpdate && this.callRequestUpdate.callable && this.callHistorySave){
                this.callHistorySave = false;
                let outbound = this.authUser.sip_account.phones.find(tell => tell.prefix === this.selectedPhoneNumberForOutboundCall);
                this.callRequestUpdate.src = this.phoneFilterForCall(outbound.phone_number);
                this.callRequestUpdate.phone = this.phoneFilterForCall(this.callRequestUpdate.phone);
                this.bindAndUpdateCall();
            }
            this.stop();
            this.isTransferCall = false;
            this.addingCall = false;
        }
        this.fetchCallHistoryData();
    },

    callToSipAccount(sip_account){
        this.getPhoneNumber(sip_account);
        setTimeout(() => {
            this.callingClick();
        }, 100);
    },

    getPhoneNumber(contact) {
        if(contact.phone_number && contact.phone_number.includes('+998')){
            this.calculation = this.phoneFilterForCall(JSON.parse(JSON.stringify(contact.phone_number)));
        }else if(contact.phone && contact.phone.includes('+998')){
            this.calculation = this.phoneFilterForCall(JSON.parse(JSON.stringify(contact.phone)));
        }else if(contact.username){
            this.calculation = JSON.parse(JSON.stringify(contact.username));
        }else if(contact.cnum && contact.cnum == this.authUser.sip_account.sip_number){
            let c_number = contact.dst;
            if(c_number.length > 9){
                c_number = c_number.slice(-9);
            }
            this.calculation = c_number;
        }else if(contact.dst && contact.dst == this.authUser.sip_account.sip_number){
            let c_number = contact.cnum;
            if(c_number.length > 9){
                c_number = c_number.slice(-9);
            }
            this.calculation = c_number;
        }else{
            this.calculation = '';
            this.$notify({
                title: this.$t('message.error_m'),
                type: "error",
                offset: 130,
                message: this.$t('message.phone_number_is_not_valid')
            });
        }
    },

    puttCallOnHold(session){
        if(session.isOnHold().local){
            session.unhold()
        }else{
            session.hold();
        }
    },

    hangUpCall(session, index) {
        if((session.status && session.status !== session.C.STATUS_TERMINATED) || session.status === 0){

            if(!document.getElementById("incoming-call-beep").paused){
                document.getElementById("incoming-call-beep").load();
            }
            if(!document.getElementById("sound-beep").paused){
                // document.getElementById("sound-beep").load();
            }

            session.terminate();
            if(session && session.status === session.C.STATUS_TERMINATED){
                this.callSessionsList.forEach(item => {
                    if(item.session.isOnHold().local){
                        item.session.unhold();
                    }
                })
                if(this.callSessionsList.length == 1){
                    
                }
            }
        }
        this.fetchCallHistoryData()
    },
    
    callToOthers(ua) {
        this.noneBlock = true;
        var options = {
            mediaConstraints: { audio: true, video: false },
            pcConfig: {
                iceServers: [
                    {
                        urls: [
                            "stun:stun.l.google.com:19302",
                            // "stun:stun.zoiper.com:3478"
                        ],
                    },
                ],
                iceTransportPolicy: "all",
                rtcpMuxPolicy: "negotiate"
            },
        };
        this.pickUpCallIcon = false;
        let vm = this;

        let number = "";
        if (this.calculation.length > 0 && this.calculation.length <= 5 && this.authUser.sip_account) {
            number = this.authUser.sip_account.extension_type + ":" + this.calculation + "@" + this.authUser.sip_account.server_url;
        } else {
            number = this.calculation;
        }
        this.updatePhoneNumberDeals(number);
        if(ua){
            if(this.calculation.length > 5){
                number = this.selectedPhoneNumberForOutboundCall.toString() + number.toString();
            }
            if(this.isTransferCall && this.callSessionsList.length === 1){
                this.transferCall(number, options)
            }else{
                let session = ua.call(number, options);
                session.connection.addEventListener("addstream", (e) => {
                    if(session.status && session.status === session.C.STATUS_TERMINATED)
                    {

                    }else{
                        vm.$refs["remoteAudioRef"].srcObject = e.stream;
                        vm.$refs["remoteAudioRef"].play();
                    }
                });
            }

        }

    },

    bindAndUpdateCall(){
        this.bindingCallHistory(this.callRequestUpdate).then(res => {
            if(this.callRequestUpdate.callable.callable_type === 'deals'){
                this.updateDeal(this.callRequestUpdate.callable.callable_id).then(res => {
                    if(this.dealModel.happyCalls.length > 0){
                        this.updateDealCallList({uniqueIds: this.dealModel.happyCalls});
                    }
                });
            }

        });
    },

    phoneOutline(session) {
        this.noneBlock = true;
        this.answerCall(session, 0);
    },

    /****************icon Phone Icon Modal***********************/
    callingClick(e) {
        if(this.phone.isRegistered()){
            if (this.calculation.length > 0 && this.calculation !== this.authUser.sip_account.sip_number) {
                this.closeDepartmentList();
                this.callingModal = true;
                this.activeIconPhone = false;
                this.miniPage = false;
                this.callToOthers(this.phone);
                this.elapsedTime = 0;
                this.noneIcon = true;
            } else if (this.calculation.length === 0) {
                this.$notify({
                    title: this.$t('message.no_phone'),
                    type: "warning",
                    offset: 130,
                    message: this.$t('message.enter_phone_number_to_call'),
                });
            }else{
                this.$notify({
                    title: this.$t('message.no_phone'),
                    type: "warning",
                    offset: 130,
                    message: this.$t('message.you_can_not_call_yourself'),
                });
            }
        }else{
            this.phoneNotRegisteredMessage();
            this.phoneStatus = 'unregistered';
        }

    },

    phoneNotRegisteredMessage(){
        this.$notify({
            title: this.$t('message.register'),
            type: "error",
            offset: 130,
            message: this.$t('message.phone_not_registered_please_register_phone_to_make_a_call')
        });
    },
    
    closeCallingClick(session) {
        this.activeKontakModal = false;
        this.activeTaskModal = false;
        this.callingModal = false;
        this.activeIconPhone = true;
        this.noneBlock = false;
        this.hangUpCall(session, 0);
    },
    /****************icon Phone Icon Modal***********************/

  }

}