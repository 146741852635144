import request from '@/utils/request';

export function index(params) {
    return request({
        url: '/dealProducts',
        method: 'get',
        params
    })
}
export function store(data) {
    return request({
        url: '/dealProducts',
        method: 'post',
        data
    })
}
export function show(id) {
    return request({
        url: `/dealProducts/${id}`,
        method: 'get'
    })
}
export function update(data) {
    return request({
        url: `/dealProducts/${data[0].deal_id}`,
        method: 'put',
        data
    })
}
export function destroy(id) {
    return request({
        url: `/dealProducts/${id}`,
        method: 'delete'
    })
}

export function downloadProductRecipe(data) {
    return request({
        url: '/dealProducts/downloadProductRecipe',
        method: 'post',
        data
    })
}

export function getCalculationTypes() {
    return request({
        url: `/dealProducts/getCalculationTypes`,
        method: 'get'
    })
}

export function downloadAllProductsRecipe(data) {
    return request({
        url: `/dealProducts/downloadAllProductsRecipe/${data.deal_id}`,
        method: 'post',
        data
    })
}