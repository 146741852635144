import { keyIndicators, sellingPlanPerformanceByCountry } from "@/api/calculations/kpiPanel";

export const actions = {
    keyIndicators({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            keyIndicators(params)
                .then(res => {
                    commit("SET_KEY_INDICATORS", res.data.result.data);
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        }); 
    },

    sellingPlanPerformanceByCountry({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            sellingPlanPerformanceByCountry(params)
                .then(res => {
                    commit("SET_SELLING_BY_COUNTRY", res.data.result.data);
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        }); 
    },






}
