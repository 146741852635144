import { mapGetters, mapActions } from "vuex";
import selectCompany from "@/components/inventory/select-company";
import selectClientType from "@/components/inventory/select-client-type";
import selectClientGender from "@/components/inventory/select-client-gender";
import selectClientTypes from "@/components/multiSelects/multi-select-client-types";
import selectCountry from "@/components/inventory/select-country";
import selectCity from "@/components/inventory/select-city";
import selectExhibition from "@/components/inventory/select-exhibition";
import selectMeasurement from "@/components/inventory/select-measurement";
import contactPeople from "@/views/clients/components/clientPerson/contact-people";
import clientAccount from "@/views/clients/components/clientPerson/client-accounts";

export default {
	components: {
		selectCompany,
		selectClientType,
		selectClientGender,
		selectClientTypes,
		selectCountry,
		selectCity,
		selectExhibition,
		selectMeasurement,
		contactPeople,
		clientAccount
  	},

	data() {
		return {
			updateImage: [],
			imageUrl: "",
			is_phone_valid: false
		};
	},

	computed: {
		...mapGetters({
			rules: "clients/rules",
			model: "clients/model",
			columns: "clients/columns",
		}),
		exhibition_id: function () {
			return this.$route.params.exhibition_id
		},
	},

	methods: {
		...mapActions({
			empty: "clients/empty",
		}),

		getPhoneStatus({is_valid, countryCode}){
			this.is_phone_valid = is_valid;
			this.form.phone_country_code = countryCode;
		},

		updateImageList(file) {
			if(file.size < 5200000){
				this.imageUrl = URL.createObjectURL(file.raw);
				this.updateImage = [];
				this.updateImage.push(file.raw);
			}
			else {
				this.$message({
						type: "warning",
						message: "Размер изображения должен быть меньше 5 МБ"
					});
			}
		},

		submit(close = true) {
			let formData = new FormData();
			let reject_keys = ['client_contact_people', 'client_checking_accounts', 'clientType_ids', 'gender', 'clientTypes'];
			let accapted_keys = ['client_contact_people', 'client_checking_accounts', 'clientType_ids'];
			for (const key in this.form) {
				if (key != "avatar" && !reject_keys.includes(key)) {
					if (this.form[key]) {
						formData.append(key, this.form[key]);
					} else {
						formData.append(key, "");
					}
				}else if(accapted_keys.includes(key)){
					formData.append(key, JSON.stringify(this.form[key]));
				}
			}

			if (_.size(this.updateImage) > 0) {
				for (const key in this.updateImage) {
					if (this.updateImage.hasOwnProperty(key)) {
						const element = this.updateImage[key];
						formData.append("avatar", element);
					}
				}
			}

			this.buildFormData(formData, this.$refs['contactPeople'].object, 'client_contact_people');
			this.buildFormData(formData, this.$refs['clientAccount'].object, 'client_checking_accounts');
			
			this.$refs["form"].validate((valid) => {
				if (valid) {
					if(this.is_phone_valid){
						this.loadingButton = true;
						this.save(formData)
						.then((res) => {
							this.loadingButton = false;
							this.$alert(res);
							this.parent().listChanged();
							if (!this.form.id) {
								if(this.company_id){
									this.companyClients(this.company_id)
									.then(respon => {
										this.$emit('createdClient', res.data.result.data.client);
									})
								}else{
									this.updatePersonClients(this.company_id)
									.then(respon => {
										this.$emit('createdClient', res.data.result.data.client);
									})
								}
							}

							if (close) this.close();
						})
						.catch((err) => {
							this.loadingButton = false;
							this.$alert(err);
						});
					}
					else{
						this.$notify({
							title: this.$t('message.validation'),
							type: "error",
							offset: 130,
							message: this.$t('message.phone_number_is_not_valid')
						});
					}
				}
			});
		},

		buildFormData(formData, data, parentKey) {
			if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File) && !(data instanceof Blob)) {
				Object.keys(data).forEach(key => {
					this.buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
				});
			} else {
				const value = data == null ? '' : data;
				formData.append(parentKey, value);
			}
		},

		afterLeave(){
			this.empty();
		},
	}
}