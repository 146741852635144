import _ from 'lodash';
import { mapGetters, mapActions } from "vuex";

export default {
    props:{},   
    data() {
        return {

        }
    },
    watch: {

    },
    
    mounted() {
    },

    created() {

    },
    methods:{

        transferCall(num, options){
            this.callSessionsList[0].session.refer(num, options);
            if(this.callSessionsList[0].session.status && this.callSessionsList[0].session.status === this.callSessionsList[0].session.C.STATUS_TERMINATED){
                this.callSessionsList = [];
            }
        },
        
        conferencing() {
            //take all received tracks from the sessions you want to merge
            let sessions = this.callSessionsList;
            let receivedTracks = [];
            sessions.forEach(function(session) {
                if(session !== null && session !== undefined) {
                    session.session.connection.getReceivers().forEach(function(receiver) {
                        receivedTracks.push(receiver.track);
                    });
                }
            });
            //use the Web Audio API to mix the received tracks
            let context = new AudioContext();
            let allReceivedMediaStreams = new MediaStream();

            sessions.forEach(function(session) {
                if(session !== null && session !== undefined) {

                    let mixedOutput = context.createMediaStreamDestination();

                    session.session.connection.getReceivers().forEach(function(receiver) {
                        receivedTracks.forEach(function(track) {
                            allReceivedMediaStreams.addTrack(receiver.track);
                            if(receiver.track.id !== track.id) {
                                let sourceStream = context.createMediaStreamSource(new MediaStream([track]));
                                sourceStream.connect(mixedOutput);
                            }
                        });
                    });
                    //mixing your voice with all the received audio
                    session.session.connection.getSenders().forEach(function(sender) {
                        let sourceStream = context.createMediaStreamSource(new MediaStream([sender.track]));
                        sourceStream.connect(mixedOutput);
                    });
                    session.session.connection.getSenders()[0].replaceTrack(mixedOutput.stream.getTracks()[0]);
                }
            });

            //play all received stream to you
            this.$refs["remoteAudioRef"].srcObject = allReceivedMediaStreams;
            let promiseRemote = this.$refs["remoteAudioRef"].play();

            if(promiseRemote !== undefined) {
                promiseRemote.then(_ => {
                    console.log("playing all received streams to you");
                }).catch(error => {
                    console.log(error);
                });
            }

        },
    }

}