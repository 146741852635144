import {i18n} from '@/utils/i18n';
import { formatMoney } from "@/filters/index";


export const mutations = {

    SET_KEY_INDICATORS: (state, keyIndicators) => {
        let revenue = {
            name: i18n.t('message.revenue'),
            fact: formatMoney(keyIndicators.money_amount, 2),
            plan: formatMoney(keyIndicators.goal_money_amount, 2),
            percentage: parseInt(keyIndicators.money_amount_percentage || 0),
        }
        state.keyIndicators.push(revenue);
        
        let profit = {
            name: i18n.t('message.profit_d'),
            fact: formatMoney(keyIndicators.profit, 2),
            plan: formatMoney(keyIndicators.goal_profit, 2),
            percentage: parseInt(keyIndicators.profit_percentage || 0),
        }
        state.keyIndicators.push(profit);

        let profit_percentage = {
            name: i18n.t('message.profit_percentage'),
            fact: parseInt(keyIndicators.profit * 100 / keyIndicators.money_amount || 0) + ' %',
            plan: parseInt(keyIndicators.goal_profit * 100 / keyIndicators.goal_money_amount || 0) + ' %',
            percentage: (parseInt(keyIndicators.profit_percentage * 100 / keyIndicators.money_amount_percentage) || 0),
        }
        state.keyIndicators.push(profit_percentage);

        let counts = {
            name: i18n.t('message.count'),
            fact: keyIndicators.count,
            plan: keyIndicators.goal_count,
            percentage: parseInt(keyIndicators.count_percentage || 0),
        }
        state.keyIndicators.push(counts);

        let average_deal_money_amount = {
            name: i18n.t('message.average_deal_money_amount'),
            fact: formatMoney(keyIndicators.money_amount / keyIndicators.count, 2),
            plan: formatMoney(keyIndicators.goal_money_amount / keyIndicators.count, 2),
            percentage: (parseInt(keyIndicators.money_amount * 100 / keyIndicators.goal_money_amount) || 0),
        }
        state.keyIndicators.push(average_deal_money_amount);
        
    },
    SET_SELLING_BY_COUNTRY: (state, sellingPlanPerformanceByCountry) => {
        state.sellingPlanPerformanceByCountry = sellingPlanPerformanceByCountry
    },

};
