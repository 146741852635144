import _ from 'lodash';
import { mapGetters, mapActions } from "vuex";

export default {
  props:{},   
  data() {
    return {

    }
  },
  watch: {
    
  },
  
  mounted() {
  },

  created() {

  },
  methods:{
    addKeyPressListener(){
      window.addEventListener("keydown", function(e) {
          if(this.activeIconPhone && !this.dataSearchIsWorking){
              if(e.key >= 0 || e.key === '+' || e.key === '#' || e.key === '*'){
                  this.calculation += e.key;
              }
              if(e.code === 'Backspace' &&  this.calculation.length > 0){
                  this.calculation = this.calculation.slice(0, -1);
              }
              if(e.code === 'Enter' &&  this.calculation.length > 0){
                  this.callingClick();
              }
          }
      }.bind(this));
    },

    microphoneCalling(session) {
      this.addMic = !this.addMic;
      if(this.addMic){
          session.mute({audio: true});
      }else{
          session.unmute({audio: true});
      }
    },
    
    clearCallNumber() {
      if (this.calculation.toString().length > 1) {
          this.calculation = this.calculation
              .toString()
              .substring(0, this.calculation.toString().length - 1);
      } else {
          this.calculation = "";
      }
    },

    phoneFilterForCall(phone){
        if(phone && phone.length === 13){
            return phone.slice(4);
        }
        this.$notify({
            title: this.$t('message.register'),
            type: "error",
            offset: 130,
            message: this.$t('message.phone_number_is_not_valid')
        });
        return '';
    },
        
    isActive (menuItem) {
        return this.radio === menuItem
    },

    setActive (menuItem) {
        if(menuItem === 'registered'){
            this.debouncedRegisterUserAgentJSSIP(this);
        }
        if(menuItem === 'unregistered'){
            this.unregisterThePhone();
        }
        this.radio = menuItem
        this.miniStatus = false
    },

    showCallDirection(callH){
      if(this.authUser.sip_account.sip_number === callH.cnum && callH.disposition === 'FAILED'){
          return 'fa-solid fa-phone-slash';
      }else if(this.authUser.sip_account.sip_number === callH.cnum){
          return 'fa-solid fa-phone-arrow-up-right';
      }else if(this.authUser.sip_account.sip_number !== callH.cnum && callH.disposition === 'BUSY'){
          return 'fa-solid fa-phone-xmark';
      }else if(this.authUser.sip_account.sip_number !== callH.cnum && callH.disposition === 'NO ANSWER'){
          return 'fa-solid fa-phone-missed';
      }
      return "fa-solid fa-phone-arrow-down-left";
    },
  
    showCallStatus(callH){
        if(callH.disposition === 'ANSWERED'){
            return "coo1";
        }else if(callH.disposition === 'NO ANSWER' || callH.disposition === 'FAILED'){
            return "coo2";
        }else{
            return "coo3";
        }
    },

    filterPhone(call, show_name) {
        let number = '';
        let name = '';

        if(this.authUser.sip_account.sip_number === call.dst){
            number = call.caller;
            name = call.caller_name;
        }else if(this.authUser.sip_account.sip_number === call.cnum){
            number = call.called_to;
            name = call.target_name;
        }

        if(show_name){
            return name;
        }

        return number;
    },

    
    handleClose(done) {
      this.$confirm(this.$t('message.Are you sure to close this window'))
          .then((_) => {
              done();
          })
          .catch((_) => {});
    },



  }

}