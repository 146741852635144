import { render, staticRenderFns } from "./DealsByCountry.vue?vue&type=template&id=0c8811be&scoped=true&"
import script from "./DealsByCountry.vue?vue&type=script&lang=js&"
export * from "./DealsByCountry.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c8811be",
  null
  
)

export default component.exports